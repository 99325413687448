import styled from '@emotion/styled'

export const Container = styled.div`
    min-height: 94vh;
    background-color: #F0F2F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    & > .filters-wrapper {
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px 10px;
        text-align: left;

        display: flex;
        justify-content: space-between;
    }

    & > .filters-wrapper, .table-wrapper {
        width: 95%;
        margin-bottom: 20px;
    }

    .table-wrapper {
        .centered-item {
            margin-right: 20px !important;
        }
    }
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & label {
        color: rgba(107, 106, 110, 0.5);
        /* font: normal normal normal 0.95em/35px Roboto; */
        letter-spacing: 0.28px;
        align-items: center;
        display: flex;
        & .help-box {
            margin-left: 5px;
            display: inline-flex;
        }
    }
`

export const ButtonRow = styled.div`
    margin-top: 15px;
    text-align: center;

    & > span > svg {
        color: #EF681E;
    }
`
