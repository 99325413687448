interface ItemOption {
    key: string
    label: string
}

export const businessTypes: ItemOption[] = [
    {key: 'ltd', label: 'Limited Company (Ltd)'},
    {key: 'inc', label: 'Incorporation (Inc)'},
    {key: 'public_corporation', label: 'Public Corporation (Co/Corp)'},
    {key: 'llc', label: 'Limited Liability Company (LLC)'},
    {key: 'partnership', label: 'Partnership'},
    {key: 'sole_proprietorship', label: 'Sole Proprietorship'},
    {key: 'private_corporation', label: 'Private Corporation'},
    {key: 'non_profit', label: 'Non Profit Organization'},
    {key: 'trust_company', label: 'Trust Company'},
    {key: 'individual', label: 'Individual (unregistered business)'}
]