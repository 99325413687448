import API from "../API/config";
import {IPayload} from "../types/storeTypes";

export interface QueryParams {
  [key: string]: string | number | undefined;
}

export const getcallScoreRules = (params: QueryParams) => {
  return API.get("/call_score_rules", {params: params});
};

export const createcallScoreRule = (payload: IPayload) => {
  return API.post("/call_score_rules", payload);
};

export const updatecallScoreRule = (id: number, payload: IPayload) => {
  return API.put(`/call_score_rules/${id}`, payload);
};

export const destroycallScoreRule = (id: number) => {
  return API.delete(`/call_score_rules/${id}`);
};

// export class callScoreRuleService {
//   get = async (params: QueryParams) => {
//     const resp: IPayload = await API.get("/call_score_rules", {
//       params: params,
//     });
//     return resp.data;
//   };
// }
