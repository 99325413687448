import React from 'react'
import { useEffect } from 'react'
import Inputmask from "inputmask";
import styled from '@emotion/styled'


import TextField, { /*OutlinedTextFieldProps, */ StandardTextFieldProps } from '@mui/material/TextField'

const StyledPhoneNumberInput = styled(TextField)`
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
    & div {
        color: #6B6A6E;
        font-size: 14px;
    }
    & input  {
        background: #F0F2F5 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border-color: #F0F2F5;
    }
`

/**
 * Extend properties
 */
 export interface PhoneNumberInputProps extends StandardTextFieldProps {
    name: string
    mask?: string
}

/**
 * Password input
 * @param props Properties
 */
export const PhoneNumberField: React.FunctionComponent<PhoneNumberInputProps> = React.forwardRef((props, ref)  => {
    useEffect(() => {
        const defasultMask = '(299) 999-9999' //'(299) 999-9999[ x 9{1,4}]'
        Inputmask.extendDefinitions({
          '2': {
            'validator': '[2-9]'
          }
        });
        const phoneNumberInput = document.getElementsByName(props.name)[0]
        if (phoneNumberInput) {
            if (phoneNumberInput.inputmask) {
                phoneNumberInput.inputmask.remove();
            }
            var inputmask = new Inputmask(props.mask||defasultMask, {});
            inputmask.mask(phoneNumberInput);
        }
    }, [])

    return (
        <StyledPhoneNumberInput ref={ref} {...props}/>
    )
})


interface IProps {
    id: string
    name: string
    error?: boolean | undefined
    helperText?: React.ReactNode | undefined
    mask?: string
    value?: any
    sx?: any
    setUnmaskedValue: (name: any, value: string) => void
}

const PhoneNumberInput = (
    {
        id,
        name,
        error,
        helperText,
        mask,
        sx,
        setUnmaskedValue,
        value
    }
        : IProps) => {

    useEffect(() => {
        const defasultMask = '(299) 999-9999' //'(299) 999-9999[ x 9{1,4}]'
        Inputmask.extendDefinitions({
          '2': {
            'validator': '[2-9]'
          }
        });
        const phoneNumberInput = document.getElementById(id)
        if (phoneNumberInput) {
            if (phoneNumberInput.inputmask) {
                phoneNumberInput.inputmask.remove();
            }
            var inputmask = new Inputmask(mask||defasultMask, {});
            inputmask.mask(phoneNumberInput);
        }
    }, [])

    const onChange = (val: string) => {
        setUnmaskedValue( name , val.replace(/[()-\s]/g, '').replace(/_/g, '') )
    }

    return (
        <StyledPhoneNumberInput
            id={id}
            sx={sx}
            defaultValue={value}
            variant='outlined'
            size='small'
            onChange={(e) => onChange(e.target.value)}
            error={error}
            helperText={helperText}
        />
    )
}

export default PhoneNumberInput;
