import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import CircularProgress from '@mui/material/CircularProgress'
import type { SnackbarCloseReason } from '@mui/material'

import { useAppContext } from '../../context/AppContext'
import { contactToAdministrator } from '../../services/signup'
import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'
import Snackbar from '../../components/CustomSnackbar/CustomSnackbar'

import { Container, StyledTextField } from '../Home/styledComponents'
import { ButtonRow, StyledTypography, Row } from './styled'

export interface IContactForm {
    email: string
    message: string
}

interface CustomizedState {
    userEmail: string
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

const ContactAdmin = (): JSX.Element => {
    let navigate = useNavigate()
    const { loading, setLoading } = useAppContext()
    const [showModal, setShowModal] = useState(true)
    const [message, setMessage] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })
    const location = useLocation()
    const state = location.state as CustomizedState
    const userEmail = state?.userEmail

    const { register, handleSubmit, formState } = useForm<IContactForm>({
        defaultValues: { message: '' }
    })

    useEffect(() => {
        if (!state?.userEmail) {
            navigate('/')
        }
    }, [])

    const closeModal = () => {
        setShowModal(show => !show)
        navigate('/')
    }

    const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
        setSnackbarProps(state => ({ ...state, open: false }))
    }

    const onSubmitAdminMessage = async (data: IContactForm) => {
        try {
            setLoading(true)
            const response = await contactToAdministrator({ email: userEmail, message: data.message })
            if (response.status !== 200) {
                return setMessage('Error')
            }
            setLoading(false)
            navigate('/')
            closeModal()
        } catch (error: any) {
            setLoading(false)
            setMessage('Something went wrong') //error.response.data.error
            setSnackbarProps({ severity: 'error', open: true })
        }
    }

    return (
        <Container>
            <h4>Autopylot</h4>

            <Modal
                open={showModal}
                title="Account already exists"
                maxWidth='xs'
                fullWidth
                onClose={closeModal}
            >
                <form onSubmit={handleSubmit(onSubmitAdminMessage)}>
                    <StyledTypography variant='subtitle2'>It appears there is already an account for this domain. Send a message to the AutoPylot admin to request access.</StyledTypography>
                    <Row>
                        <label>Your Message</label>
                        <StyledTextField
                            {...register('message', { required: 'Required Field' })}
                            multiline
                            rows={3}
                            maxRows={4}
                            variant='outlined' size='small' type="message"
                            error={!!formState.errors?.message}
                            helperText={formState.errors?.message?.message}
                        />
                    </Row>
                    <ButtonRow>
                        {loading ? <CircularProgress /> : <Button type='submit' >Submit</Button>}
                    </ButtonRow>
                </form>
            </Modal>
            <Snackbar open={snackbarProps.open} message={message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />
        </Container>
    )
}

export default ContactAdmin
