import { observer } from "mobx-react";
import { User } from "../../stores/UserStore";

import Modal from '../Modal/Modal'
import { CustomTable as Table, IHeader } from '../../components/Table/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox'

import { Device, DeviceStore } from "../../stores/DeviceStore";
import { useEffect, useState } from "react";
import { action, makeObservable, observable } from "mobx";
import { BulkRow } from "./StyledComponents";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import { capitalize } from "lodash";

const tableHeaders = [
    {
        title: '',
        alignment: 'center'
    },
    {
        title: 'UUID',
        alignment: 'left'
    },
    {
        title: 'Model',
        alignment: 'left'
    },
    {
        title: 'Platform',
        orderBy: 'platform',
        alignment: 'left'
    },
    {
        title: 'Status',
        orderBy: 'status',
        alignment: 'left'
    },
    {
        title: 'Updated At',
        orderBy: 'updated_at',
        order: 'desc',
        alignment: 'left'
    },
    {
        title: 'Actions'
    }
] as Array<IHeader>

interface IDevicesProps {
    userId: number,
    showModal: boolean
    onClose: () => void
}

interface OrderProps {
    orderBy: string
    order: 'asc' | 'desc'
}


class DevicesStore {
    @observable
    devices
    deviceStore = new DeviceStore()
    userId
    @observable
    orderProps = {orderBy: 'updated_at', order: 'desc'} as OrderProps
    @observable
    selectedDevices = [] as Array<number>;
    constructor(userId: number){
      this.devices = [] as Array<Device>;
      this.userId = userId;
      makeObservable(this);
    }

    @action
    selectDevice = (device: Device) => {
        console.log('selectDevice', device);
        if (!device.id) {
            return
        }

        if (this.isSelected(device)) {
            const index = this.selectedDevices.indexOf(device.id);
            this.selectedDevices.splice(index, 1);
        } else {
            this.selectedDevices.push(device.id);
        }
    }

    isSelected = (device: Device) => {
        if (!device.id) {
            return
        }
        return this.selectedDevices.indexOf(device.id) > -1;
    }

    @action
    sortDevices = (orderBy: string, order: 'asc' | 'desc') => {
        this.orderProps.orderBy = orderBy
        if (order === 'asc') {
            this.orderProps.order = 'desc'
        } else {
            this.orderProps.order = 'asc'
        }
        this.fetchDevices()
    }

    @action
    fetchDevices = async () => {
        const sort = this.orderProps.order === 'asc' ? this.orderProps.orderBy : `-${this.orderProps.orderBy}`
        await this.deviceStore.getDevicesAsync({'filter[owner_id]': this.userId, sort: sort});
        this.devices = this.deviceStore.devices;
    }

    removeDevice = async (device: Device) => {
        if (!device.id) {
            return;
        }

        if (device.status === 'active') {
            await this.deviceStore.updateDeviceAsync(device.id, {status: 'inactive'})
        }

        await this.deviceStore.destroyDeviceAsync(device.id)
        const index = this.selectedDevices.indexOf(device.id);
        if (index > -1) {
            this.selectedDevices.slice(index, 1);
        }
    }

    @action
    deleteSelectedDevices = async () => {
        for (const deviceId of this.selectedDevices) {
            let device = this.devices.find((d: Device) => d.id === deviceId);
            if (!device) {
                continue;
            }
            await this.removeDevice(device);
        }
        this.selectedDevices = [];
        await this.fetchDevices();
    }
}

export const DevicesModal = observer(({ userId, showModal, onClose }: IDevicesProps) => {
    const [store] = useState<DevicesStore>(new DevicesStore(userId))

    const deleteDevice = async (device: Device) => {
        await store.removeDevice(device)
        await store.fetchDevices();
    }

    useEffect(() => {
        store.fetchDevices()
    }, [])

    const tableContent = store.devices.map((device: Device, i) => {
        return (
            <TableRow key={i}>
                <TableCell>
                    <Checkbox
                        onChange={(e) => store.selectDevice(device)}
                        checked={store.isSelected(device)}
                    />
                </TableCell>
                <TableCell>
                    <Typography>{device.uuid}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{device.model}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{device.platform}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{capitalize(device.status?.toString())}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{device.updatedAt.format('MM/DD/YYYY hh:mm a')}</Typography>
                </TableCell>
                
                <TableCell>
                    <IconButton
                        onClick={() => deleteDevice(device)}
                        aria-label="delete"
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    })

    return (
        <Modal
            open={showModal}
            title="Devices"
            maxWidth='lg'
            fullWidth
            onClose={onClose}
        >
            <div className='table-wrapper'>
                {store.selectedDevices.length > 1 && <Alert icon={false} severity="info">
                    <BulkRow>
                        <Typography>Selected {store.selectedDevices.length} devices</Typography>
                        <Tooltip title="Delete Selected">
                            <IconButton
                                onClick={() => store.deleteSelectedDevices()}
                                aria-label="delete"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </BulkRow>
                </Alert>}
                <Table
                    orderBy={store.orderProps.orderBy} 
                    order={store.orderProps.order} 
                    sortHandler={store.sortDevices} 
                    headers={tableHeaders}
                    tableContent={tableContent}
                />
                </div>
        </Modal>
    )
})