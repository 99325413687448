import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { observer } from "mobx-react";
import { makeObservable, observable, computed, action } from 'mobx';

import debounce from 'lodash/debounce'
import capitalize from 'lodash/capitalize'
import * as ActiveStorage from '@rails/activestorage'

import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import HelpIcon from '@mui/icons-material/Help';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox'

import PopUp from '../../components/PopUp/PopUp'
import Button from '../../components/Button/Button'
import AutoPylotAutocomplete from '../../components/AutopylotAutoComplete/AutopylotAutoComplete'
import { PhoneNumberField } from '../../components/PhoneNumberInput/PhoneNumberInput'


import { UserStore, User, Role as IRole, DidAllocation } from "../../stores/UserStore";
import { AccountStore } from "../../stores/AccountStore";
import { GroupStore } from "../../stores/GroupStore";


import { getTwilioNumbers, DidResponse } from '../../services/common'


import { useAppContext } from '../../context/AppContext'

import { 
  ResponsiveForm,
  Row,
  ButtonRow,
  StyledTypography,
  StyledTextField,
  StyledMenuItem,
  PopupTypography,
  CheckboxLabel,
  StyledLink,
  StyledInputAdornment
} from './StyledComponents'

import { capitalizeInput } from '../../utils/InputUtils'
import { UploadDelegate } from '../../utils/UploadDelegate'


import { DisableMobileAppModal } from './DisableMobileAppModal'
import { TransferAPNumberModal } from './TransferAPNumberModal'
import { DevicesModal } from './DevicesModal'

import API from '../../API/config'
import { Device, DeviceStore } from '../../stores/DeviceStore';

export interface IValidError {
    code: string
    detail: string
    status: string
    title: string
}

interface IUserFormProps {
    user?: any,
    roles?: any,
    displaySnackbar: any
}

interface IFormControlProps {
  user?: any,
  roles?: any
}

// TODO: replace recordingEnabled with inboundRecordingEnabled and outboundRecordingEnabled
// inboundRecordingEnabled: boolean
// outboundRecordingEnabled: boolean
// voicemailGreeting: File

export type IUser = {
  firstName?: string,
  lastName?: string,
  email?: string,
  status?: string,
  password?: string,
  passwordConfirmation?: string,
  outboundCallPhoneNumber?: string,
  autopylotNumber?: string,
  didAllocationId?: number,
  group?: number,
  roleId?: number,
  receiverType?: string,
  isAdmin?: boolean,
  recordingEnabled?: boolean,
  textingEnabled?: boolean,
  transcriptionEnabled?: boolean,
  callNotificationEnabled?: boolean,
  pluginInstalled?: boolean,
  voicemailGreeting?: string
};

export type IDisableMobileApp = {
  userId: number | null
}

interface RoleOption {
    id?: number
    value: string
    label: string
}

class FormController {
  _roles
  @observable
  isAdmin
  user
  @observable
  users
  @observable
  devices
  currentUsersPage
  userStore = new UserStore()
  deviceStore = new DeviceStore()
  constructor(props: IFormControlProps){
    this.user = props.user
    this.isAdmin = props.user.isAdmin
    this._roles = props.roles
    this.users = [] as Array<User>
    this.currentUsersPage = 1
    this.devices = [] as Array<Device>

    makeObservable(this);
  }

  statuses = [
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'inactive',
      label: 'Inactive',
    }
  ]
  roleLabels = {
    admin: 'AP Admin',
    sales_director: 'Sales Director',
    manager: 'Sales Manager',
    sales_person: 'Sales Person',
  }

  receiverTypes = [
    {
      value: 'direct_number',
      label: 'Mobile Number'
    },
    {
      value: 'knowme_iq_number',
      label: 'AutoPylot Number'
    }
  ]

  @computed
  get roles() {
    return this._roles.map((role: IRole) => {
      let key = role.name as 'admin' | 'sales_director' | 'manager' | 'sales_person'
      return {id: role.id, value: role.name, label: this.roleLabels[key]||''}
    });
  }

  @computed
  get usersWithAPNumber() {
    return this.users.filter((u: User) => u.didAllocation?.did && this.user.id !== u.id)
  }

  @computed
  get activeUsers() {
    return this.users.filter((u: User) => u.status !== 'inactive' && this.user.id !== u.id )
  }

  @action
  fetchUsers = async () => {
    await this.userStore.getUsersAsync({include: 'group,roles,did_allocations.did', sort: 'first_name', 'page[number]': this.currentUsersPage, 'page[size]': '50'})
    this.users.push(...this.userStore.users)
    if (this.userStore.meta.pageCount > this.currentUsersPage) {
      this.currentUsersPage++
      this.fetchUsers()
    }
  }

  @action
  setIsAdmin = (isAdmin: string) => {
    this.isAdmin = isAdmin === 'true'
  }
}


const UserForm = observer(({ user, roles, displaySnackbar }: IUserFormProps) => {
  const navigate = useNavigate()
  const [groupStore] = useState<GroupStore>(new GroupStore())
  const [accountStore] = useState<AccountStore>(new AccountStore())
  const [didAllocationIDForTransfer, setDidAllocationIDForTransfer] = useState(user.transferredDidAllocations[0]?.id)
  const didAllocationForTransfer = useMemo<DidAllocation>(() => user.transferredDidAllocations.find((didAllocation: DidAllocation) => didAllocation.id === didAllocationIDForTransfer ), [didAllocationIDForTransfer])
  const [enableRecording, setEnableRecording] = useState(false)
  const [enableTexting, setEnableTexting] = useState(false)
  const [_voicemailGreetingBlobUrl, setVoicemailGreetingBlobUrl] = useState('')
  const [voicemailGreetingBlob, setVoicemailGreetingBlob] = useState<ActiveStorage.Blob | null>(null)
  const [_message, setMessage] = useState('')
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const [showDisableMobileAppModal, setShowDisableMobileAppModal] = useState(false)
  const [showDevicesModal, setShowDevicesModal] = useState(false)
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [autocompleteOptions, setAutoCompleteOptions] = useState<Array<DidResponse>>([])
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [formControl] = useState<FormController>(new FormController({user: user, roles: roles}))
  const openPopUp = Boolean(anchorElement)
  const loadingNumbers = showAutocomplete && autocompleteOptions.length === 0;

  const { currentUser, loading, setLoading, openSidebar } = useAppContext()

  // TODO: replace recordingEnabled with inboundRecordingEnabled and outboundRecordingEnabled
  // inboundRecordingEnabled: user.inboundRecordingEnabled
  // outboundRecordingEnabled: user.outboundRecordingEnabled
  const defaultValues = useMemo(() => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      status: user.status,
      password: user.password,
      passwordConfirmation: user.passwordConfirmation,
      outboundCallPhoneNumber: user.outboundCallPhoneNumberFormatted,
      autopylotNumber: user.didAllocation?.did?.phoneNumber,
      group: user.group?.id,
      roleId: user.role?.id,
      receiverType: user.receiverType,
      isAdmin: user.isAdmin.toString(),
      recordingEnabled: user.recordingEnabled,
      textingEnabled: user.textingEnabled,
      transcriptionEnabled: user.transcriptionEnabled,
      callNotificationEnabled: user.callNotificationEnabled,
      pluginInstalled: user.pluginInstalled,
      voicemailGreeting: user.voicemailGreeting
    }
  }, [user])

  const { register, handleSubmit, setValue, watch, formState: { isValid, isDirty, dirtyFields, errors }, control } = useForm<IUser>({
      mode: 'onChange',
      defaultValues: defaultValues
  })

  const watchPassword = watch("password", "");

  const getAccount = async (accountId: number) => {
    await accountStore.getAccountAsync(accountId, 'settings')
    if (accountStore.account.enableInboundRecordingSetting) {
      setEnableRecording(accountStore.account.enableInboundRecordingSetting.value)
    }
    if (accountStore.account.enableTextingSetting) {
      setEnableTexting(accountStore.account.enableTextingSetting.value)
    }
  }

  useEffect(() => {
    groupStore.getGroupsAsync()
    const accountId = user.relationships.account.data.id;
    if (accountId) {
      getAccount(accountId)
    }
  }, [])

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
      setAnchorElement(null);
  };

  const getTwilioNumbersList = async (number: string) => {
    try {
      setShowAutocomplete(true);
      const response = await getTwilioNumbers(number) //as Promise<TwilioNumbersResponse>
      setAutoCompleteOptions(response.data)
    } catch (error) {
      setMessage('Error retrieving Autopylot numbers')
      // setSnackbarProps({ severity: 'error', open: true })
    }
  }

  const debouncedGetTwilioNumbers = debounce((number) => getTwilioNumbersList(number), 200)

  const onSubmitUser = async (values: IUser) => {
    try {
      setLoading(true)
      if (voicemailGreetingBlob) {
        let file = fileFromBlob(voicemailGreetingBlob)
        let blob = await uploadRecording(file) as ActiveStorage.Blob
        values.voicemailGreeting = blob.signed_id
        setVoicemailGreetingBlob(null)
      }
      values.didAllocationId = user.didAllocation?.id
      if (values.outboundCallPhoneNumber) {
        values.outboundCallPhoneNumber = values.outboundCallPhoneNumber.replace(/[()-\s]/g, '').replace(/_/g, '')
      }
      await user.update(values)
      setLoading(false)
      navigate('/users')
    } catch (error: any) {
      if (error.response.status == 422) {
        error.response.data.errors.forEach((error: IValidError) => {
            let parts = error.detail.split('-')

            let field = parts[0];
            let reason = parts[1];

            displaySnackbar(`${capitalize(field.replace(/_/g, ' '))} ${reason}`, 'error')
        });
      } else {
        displaySnackbar('Something went wrog', 'error')
      }
      setLoading(false)
    }
  }

  const fileFromBlob = (blob: any) => {
    return new File([blob], 'voicemail.wav', { type: 'audio/wav' })
  }

  const uploadRecording = async (file: File) => {
    const url = `${API.defaults.baseURL}v1/uploads`
    const upload = new ActiveStorage.DirectUpload(
      file,
      url,
      new UploadDelegate()
    )

    const uploadVoicemailgreeting = () => {
      return new Promise((resolve, reject) => {
        upload.create((error: any, blob: ActiveStorage.Blob) => {
          if (error) {
            reject(error)
          } else {
            resolve(blob)
          }
        })
      })
    }

    const blob = await uploadVoicemailgreeting()
    return blob
  }

  const handleAudioUpload = (url: any, blob: ActiveStorage.Blob) => {
    setVoicemailGreetingBlobUrl(url)
    setVoicemailGreetingBlob(blob)
  }

  const deleteVoiceMailGreeting = async () => {
    if (user.voicemailGreetingAttachment) {
      await user.removeVoicemailGreetingAttachment()
    }
    setVoicemailGreetingBlob(null);
  }

  const disableMobileApp = async () => {
    setShowDisableMobileAppModal(show => !show)
    await formControl.fetchUsers()
  }

  const displayDevices = async () => {
    if (user.mobileAppStatusFormatted === 'Not Installed' || user.mobileAppStatusFormatted === 'Disabled') {

    } else {
      setShowDevicesModal(show => !show)
    }
  }

  const onDisableMobileApp = async () => {
      setShowDisableMobileAppModal(show => !show)
      await user.reload()
  }

  const openTransferAPModal = async (e: Event | React.SyntheticEvent<any, Event>) => {
    e.preventDefault()
    setShowTransferModal(showTransferModal => !showTransferModal)
    await formControl.fetchUsers()
  }

  const onTransferedAPNumber = async () => {
    setShowTransferModal(showTransferModal => !showTransferModal)
    await user.reload()
  }

  const endAdornment = useMemo(() => (
    currentUser?.isAdmin ? (
      <StyledInputAdornment className={user.transferredDidAllocations.length > 1 ? 'for-dropdown' : ''} position="end">
        <StyledLink title='Transfer' onClick={(e) => openTransferAPModal(e) }>
          <span>Transfer</span>
          <SyncAltIcon titleAccess='Transfer' htmlColor='rgb(107, 106, 110)' fontSize='small'/>
        </StyledLink>
      </StyledInputAdornment>
    ) : null),
  [currentUser?.isAdmin, user.transferredDidAllocations.length])

  return (
    <>
      {didAllocationForTransfer && <TransferAPNumberModal didAllocation={didAllocationForTransfer} users={formControl.activeUsers} showModal={showTransferModal} onClose={()=> setShowTransferModal(false)} afterTransfer={onTransferedAPNumber} />}
      <DisableMobileAppModal users={formControl.activeUsers} user={user} showModal={showDisableMobileAppModal} onClose={()=> setShowDisableMobileAppModal(false)} afterDisable={onDisableMobileApp} />
      {user?.id && <DevicesModal userId={user.id} showModal={showDevicesModal} onClose={()=> setShowDevicesModal(false)} />}

      <ResponsiveForm onSubmit={handleSubmit(onSubmitUser)} className={openSidebar ? 'with-sidebar' : ''} >
        <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid item xs={4} sm={4} md={4} >
            <Row>
              <label>First Name</label>
              <StyledTextField
                {...register('firstName', { required: 'Required Field' })}
                onKeyUp={(e) => { capitalizeInput(e, 'firstName', setValue) }}
                variant='outlined' size='small'
                error={!!errors?.firstName}
                helperText={errors?.firstName?.message}
              />
            </Row>
            <Row>
              <label>Last Name</label>
              <StyledTextField
                {...register('lastName', { required: 'Required Field' })}
                onKeyUp={(e) => { capitalizeInput(e, 'lastName', setValue) }}
                variant='outlined' size='small'
                error={!!errors?.lastName}
                helperText={errors?.lastName?.message}
              />
            </Row>
            <Row>
              <label>User ID</label>
              <StyledTextField
                {...register('email', { required: 'Required Field', value: user.email })}
                variant='outlined' size='small'
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
            </Row>
            <Row>
              <label>Console Status</label>
              <StyledTextField
                defaultValue={user.consoleStatus}
                InputProps={{
                  readOnly: true,
                }}
                variant='outlined' size='small'
              />
            </Row>
            <Row>
              <label>Mobile App Status</label>
              <StyledTextField
                name="mobileAppStatus"
                defaultValue={user.mobileAppStatusFormatted}
                InputProps={{
                  readOnly: true,
                }}
                onClick={displayDevices}
                variant='outlined' size='small'
              />
            </Row>
          </Grid>

          <Grid item xs={4} sm={4} md={4} >
            <Row>
              <label>Mobile Number</label>
              <PhoneNumberField
                  {...register('outboundCallPhoneNumber', { validate: (value) => { return !value || value && isValidPhoneNumber(value.match(/^\+1*/) ? value : `+1${value}`)||'Invalid Phone Number' }  })}
                  size='small'
                  sx={{'minWidth': '65%', ['fieldset']: {border: 'none'}, ['@media (max-width:1280px)']: {'minWidth': '55%', ['& .MuiInputBase-root']: {fontSize: '12px'}}}}
                  mask={'+1 (299) 999-9999'}
                  error={!!errors?.outboundCallPhoneNumber}
                  helperText={errors?.outboundCallPhoneNumber?.message}
              />
            </Row>
            <Row>
              <label>
                AutoPylot Number
                <div className='help-box'
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <HelpIcon htmlColor='#42B1F2' fontSize='small' />
                </div>
                <PopUp
                    open={openPopUp}
                    anchorElement={anchorElement}
                    onClose={handlePopoverClose}
                >
                    <PopupTypography sx={{ p: 1 }}>
                        Allows users to make and receive business calls and send and receive text messages, through AutoPylot Mobile app.
                        Users can easily separate their business and personal communication.
                        Such activities are automatically logged and organized in AutoPylot Console and Mobile app.
                    </PopupTypography>
                </PopUp>
              </label>
              {user.originalDidAllocation?.did?.phoneNumber || user.isMobileAppDisabled ?
                  <StyledTextField
                    disabled
                    value={user.originalDidAllocation?.did?.phoneNumber}
                    variant='outlined' size='small'
                    error={!!errors?.autopylotNumber}
                    helperText={errors?.autopylotNumber?.message}
                  />
                 :
                 <AutoPylotAutocomplete
                  sx={{'minWidth': '65%', ['@media (max-width:1280px)']: {'minWidth': '55%'}}}
                  placeholder={"Type Desired Area Code"}
                  id="autopylotnums-autocomplete"
                  register={register('autopylotNumber', {
                      required: 'Required Field', validate: value => { return isValidPhoneNumber(value||'') || "Invalid Phone Number" }
                  })}
                  onSelectOption={(val: any) => { setValue('autopylotNumber', val?.number?.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4'), {shouldValidate: true}); }}
                  open={showAutocomplete}
                  onClose={() => setShowAutocomplete(false)}
                  options={autocompleteOptions}
                  onChangeValue={debouncedGetTwilioNumbers}
                  loading={loadingNumbers}
                  error={!!errors?.autopylotNumber}
                  helperText={errors?.autopylotNumber?.message}
                />
              }
            </Row>

            <Row>
              <label>
                Transferred AP number(s)
              </label>
              {user.transferredDidAllocations.length === 0 ? (
                <StyledTextField
                  defaultValue={'None'}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant='outlined' size='small'
                />)
              : user.transferredDidAllocations.length === 1 ? (
                <StyledTextField
                  defaultValue={user.transferredDidAllocations[0]?.did.phoneNumber}
                  variant='outlined' size='small'
                  InputProps={{
                    endAdornment: endAdornment
                }}
                ></StyledTextField>
              ) : (
                <StyledTextField
                  select
                  defaultValue={user.transferredDidAllocations[0]?.id}
                  variant='outlined' size='small'
                  onChange={(e) => { setDidAllocationIDForTransfer(e.target.value) } }
                  InputProps={{
                    endAdornment: endAdornment
                }}
                >
                  {user.transferredDidAllocations.map((didAllocation: any) => (
                    <StyledMenuItem key={didAllocation.id} value={didAllocation.id} >
                      {didAllocation.did.phoneNumber}

                    </StyledMenuItem>
                  ))}
                </StyledTextField>)
              }
            </Row>

            {user.isMobileAppInstalled && <Row>
              <label>Receive calls at</label>
              <StyledTextField
                {...register('receiverType')}
                select
                defaultValue={user.receiverType}
                variant='outlined' size='small'
                disabled={user.mobileAppStatus === 'inactive'}
                error={!!errors?.receiverType}
                helperText={errors?.receiverType?.message}
              >
                {formControl.receiverTypes.map((option) => (
                  <StyledMenuItem key={option.value} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                ))}
              </StyledTextField>
            </Row>}

          </Grid>
          <Grid item xs={4} sm={4} md={4} >
            <Row>
              <label>Role</label>
              <StyledTextField
                {...register('roleId', { required: 'Required Field' })}
                select
                defaultValue={user.role?.id}
                variant='outlined' size='small'
                error={!!errors?.roleId}
                helperText={errors?.roleId?.message}
              >
                {formControl.roles.map((option: RoleOption) => (
                  <StyledMenuItem key={option.value} value={option.id}>
                    {option.label}
                  </StyledMenuItem>
                ))}
              </StyledTextField>
            </Row>
            <Row>
              <label>Group(s)</label>
              <StyledTextField
                {...register('group', { required: 'Required Field' })}
                select
                defaultValue={user.group?.id}
                variant='outlined' size='small'
                error={!!errors?.group}
                helperText={errors?.group?.message}
              >
                {groupStore.groups.map((option: any) => (
                  <StyledMenuItem key={option.id} value={option.id}>
                    {option.name}
                  </StyledMenuItem>
                ))}
              </StyledTextField>
            </Row>
            {!user.isSalesPerson && <Row>
                <label>Password</label>
                <StyledTextField
                    {...register('password')}
                    inputProps={{
                        autoComplete: 'off',
                        form: {
                            autoComplete: 'off',
                        }
                    }}
                    variant='outlined' size='small' type="password"
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                />
            </Row>}
            {!user.isSalesPerson && <Row>
                <label>Confirm Password</label>
                <StyledTextField
                    {...register('passwordConfirmation', {
                      validate: value => value === watchPassword || "The passwords do not match"
                    })}
                    inputProps={{
                        autoComplete: 'off',
                        form: {
                            autoComplete: 'off',
                        }
                    }}
                    variant='outlined' size='small' type="password"
                    error={!!errors?.passwordConfirmation}
                    helperText={errors?.passwordConfirmation?.message}
                />
            </Row>}
          </Grid>
        </Grid>

        <Divider sx={{margin: '20px 0'}}/>

        <StyledTypography align='left'>User specific settings</StyledTypography>
        <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 12, sm: 12, md: 12 }}>
          {enableRecording && !user.isMobileAppDisabled && <Grid item xs={3} sm={3} md={3} >
            <Row>
              <Controller
                name="recordingEnabled"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <CheckboxLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label='Inbound Call Recording Enabled'
                  />
                )}
              />
            </Row>
          </Grid>}

          {enableTexting && !user.isMobileAppDisabled && <Grid item xs={3} sm={3} md={3} >
            <Row>
              <Controller
                name="textingEnabled"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <CheckboxLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label='Text Messaging Enabled'
                  />
                )}
              />
            </Row>
          </Grid>}

          <Grid item xs={3} sm={3} md={3} >
            <Row style={{justifyContent: 'flex-start'}}>
              <Controller
                  name="pluginInstalled"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <CheckboxLabel
                      control={
                        <Checkbox
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                      label='CRM Plugin Enabled'
                      style={{marginLeft: '0px'}}
                    />
                  )}
                />
            </Row>
          </Grid>

          {enableRecording && !user.isMobileAppDisabled && <Grid item xs={3} sm={3} md={3} >
            <Row>
              <Controller
                name="transcriptionEnabled"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <CheckboxLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label='Transcription Enabled'
                  />
                )}
              />
            </Row>
          </Grid>}
        </Grid>

        <ButtonRow>
          {loading ? <CircularProgress /> : <Button type='submit' disabled={!(!!voicemailGreetingBlob || ( Object.keys(dirtyFields).length > 0 && isValid))}> Save</Button>}
          {user.didAllocation && <Button sx={{marginLeft: '10px'}} type='button' onClick={disableMobileApp}>Disable Mobile App</Button>}
        </ButtonRow>
      </ResponsiveForm>
    </>
  )
})

export default UserForm
