import capitalize from 'lodash/capitalize'
import { IValidError } from '../types/types'

export const getError = (error: any) => {
    let message = 'There was an error adding the new User'
    if (error.response.status === 422) {
        error.response.data.errors.forEach((error: IValidError) => {
            let parts = error.detail.split('-')

            let field = parts[0];
            let reason = parts[1];

            message = `${capitalize(field.replace(/_/g, ' '))} ${reason}`
        });
    }

    return message
}