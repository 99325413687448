import {useEffect, useState} from "react";
import {getcallScoreRules} from "../../../services/callScoreRules";
import Card from "../../../components/Card/Card";
import {groupBy, capitalize} from "lodash";
import {CategoryTd, CategoryTr, RuleTd} from "../StyledComponents";

const GreatCallScoring = () => {
  const [callScoreRules, setCallScoreRules] = useState<any>();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchCallRatingWords();
  }, []);

  const fetchCallRatingWords = async () => {
    let resp = await getcallScoreRules({
      "filter[kind]": "great",
    });

    const groupedRules = groupBy(resp.data.data, (item: any) => {
      // console.log(item.attributes.category);
      return item.attributes.category;
    });

    // console.log("groupedRules", groupedRules);
    setCallScoreRules(groupedRules);

    setIsLoaded(true);
  };
  if (!isLoaded) {
    return <></>;
  }

  return (
    <Card
      title="call scoring details"
      titleAlign="left"
      helpText="Feature still in development."
    >
      <table style={{borderCollapse: "collapse"}}>
        <thead>
          <tr>
            <th></th>
            <th>Value %</th>
            {/* <th>Great</th>
            <th>Great</th>
            <th>Great</th> */}
          </tr>
        </thead>

        {Object.keys(callScoreRules).map((item, i) => {
          return (
            <thead>
              <CategoryTr key={`${i}`}>
                <CategoryTd>{capitalize(item.replace(/_/g, " "))}</CategoryTd>
                <th></th>
                {/* <th></th> */}
              </CategoryTr>
              {callScoreRules[item].map((rule: any, j: number) => (
                <tr key={`${i}-${j}`}>
                  <RuleTd className="input-label">
                    {rule.attributes.name}
                  </RuleTd>
                  <td className="input-label">{rule.attributes.value}%</td>
                  {/* <td className="input-label">
                    {rule.attributes.enabled ? "x" : ""}
                  </td> */}
                </tr>
              ))}
            </thead>
          );
        })}
      </table>
    </Card>
  );
};

export default GreatCallScoring;
