import styled from '@emotion/styled'

type StyledProps = {
    width?: string
}

export const Container = styled.div`
    border-radius: 10px;
    background-color: #fff;
    width: ${({width}: StyledProps) => width || '100%'};
    padding: 20px 20px 20px 27px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const TitleWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: flex-end;
`

export const Title = styled.div`
    letter-spacing: 0.4px;
    color: #6B6A6E;
    font-weight: 500;
`

export const Subtitle = styled.div`
    letter-spacing: 0.28px;
    color: #6B6A6E;
    font-size: 14px;
    opacity: 0.6;
`