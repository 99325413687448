import styled from '@emotion/styled'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import { Breakpoint } from '@mui/material';

interface IModalProps {
    title?: string
    open: boolean
    fullWidth?: boolean
    maxWidth?: false | Breakpoint | undefined
    onClose?: () => void
    closeIcon?: boolean
    children: React.ReactNode
}

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    & > div > svg {
        cursor: pointer;
        opacity: 0.3;
         &:hover {
             opacity: 0.8;
         }
    }
`

const Title = styled.span`
    font-weight: normal;
    color: #6B6A6E;
    letter-spacing: 0.72px;
    font-size: 14px;
    text-transform: uppercase;
`

const CustomModal = ({ open, title = '', fullWidth, maxWidth, onClose, closeIcon = true, children }: IModalProps) => {
    return (
        <Dialog {...{ open, fullWidth, maxWidth }}>
            <DialogTitle>
                <TitleWrapper>
                    <Title>{title}</Title>
                    {closeIcon && <div onClick={onClose}><CloseIcon /></div>}
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default CustomModal
