import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../context/AppContext'
import { useStores } from '../../stores/rootStore'
import { useState, useEffect } from 'react'
import { observer } from "mobx-react";

import SubscriptionFeeComponent from './SubscriptionFee/SubscriptionFee'
import BillingDetailsComponent from './BillingDetails/BillingDetails'
import BillHistoryComponent from './BillHistory/BillHistory'
import BillingContactComponent from './BillingContact/BillingContact'
// import ScreenHeader from '../../components/ScreenHeader/ScreenHeader'

import { Container, Content } from './StyledComponents'

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

const Billing = () => {
    let navigate = useNavigate()
    let stores = useStores()
    const { currentUser, setLoading} = useAppContext()
    const [errorMsg, setErrorMsg] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })

    const getAccount = async () => {
      try {
        setLoading(true)

        const accountId = currentUser?.account?.id;
        if (accountId) {
          await stores.accountStore.getAccountAsync(accountId, 'settings,subscription.plan,subscription.promo_code,account_addresses')
        }

        setLoading(false)
      } catch (error) {
        setLoading(false)
        setErrorMsg('Something went wrong')
        setSnackbarProps((state) => ({ ...state, severity: 'error', open: true }))
      }
    }

    useEffect(() => {
      if (currentUser) {
        if (!currentUser.isAdmin) {
          navigate('/dashboard', { replace: true })
        }
        getAccount()
      }
    }, [currentUser])

    return (
        <Container>
            <Content>
                <div className='row'>
                    <BillHistoryComponent account={stores.accountStore.account} isLoading={!stores.accountStore.isLoaded}/>
                    <BillingDetailsComponent account={stores.accountStore.account} isLoading={!stores.accountStore.isLoaded}/>
                </div>
                <div className='row'>
                    <SubscriptionFeeComponent account={stores.accountStore.account} isLoading={!stores.accountStore.isLoaded}/>
                    <BillingContactComponent accountStore={stores.accountStore} isLoading={!stores.accountStore.isLoaded}/>
                </div>
            </Content>
        </Container>
    )
}

export default observer(Billing)
