import { Component, useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form'

import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import type { SnackbarCloseReason } from '@mui/material'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { PromoCodeStore, PromoCode } from '../../stores/PromoCodeStore'
import { IPayload } from '../../types/storeTypes'
import { AppContext } from '../../context/AppContext'

import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'
import Snackbar from '../../components/CustomSnackbar/CustomSnackbar'
import { CustomTable as Table, IHeader } from '../../components/Table/Table'
import { QueryParams } from '../../services/promoCodes'
import { getAutoPylotPlans } from '../../services/signup'
import { makeObservable, observable, action, configure, computed, runInAction } from 'mobx';
import { observer } from "mobx-react";
import withRouter from "../../HOC/WithRouter/WithRouter";
import { ItemOption } from '../../types/types'

import { Container, Row, ButtonRow } from './styledComponents'

import startCase from 'lodash/startCase'
import get from 'lodash/get'
import moment from 'moment'

interface PromoCodeFormProps {
    promoCode?: PromoCode
    plans: Array<any>
    onSubmitForm: (values: IPromoCode) => void
}

interface IPromoCode {
    couponId: string
    name: string
    percentOff: number
    startDate: any
    endDate: any
    planId: number
}

const PromoCodeForm = (props: PromoCodeFormProps): JSX.Element => {
    const { promoCode, onSubmitForm, plans } = props

    const defaultValues = {
        couponId: promoCode?.couponId,
        name: promoCode?.name,
        percentOff: promoCode?.percentOff,
        startDate: promoCode?.startDate||moment(),
        endDate: promoCode?.endDate||moment(),
        planId: promoCode?.plan?.id
    }
    const { register, handleSubmit, control, formState: { errors, isValid, isDirty } } = useForm<IPromoCode>({
        mode: 'onChange',
        defaultValues: defaultValues
    })

    return (
        <form id="promo-code-form" onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <label>Discount Code</label>
                <TextField
                    {...register('couponId', {
                        validate: (value) => {
                            if ( !value || value.match(/^[a-zA-Z0-9_\\-]+$/) ) {
                                return true
                            } else {
                                return 'Invalid discount code'
                            }
                        }
                    })}
                    disabled={!!promoCode?.id}
                    variant='outlined' size='small'
                    error={!!errors?.couponId}
                    helperText={errors?.couponId?.message}
                />
            </Row>
            <Row>
                <label>Name</label>
                <TextField
                    {...register('name', { required: 'Required Field' })}
                    variant='outlined' size='small'
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                />
            </Row>
            <Row>
                <label>Percent Off</label>
                <TextField
                    {...register('percentOff',
                        {
                            valueAsNumber: true, 
                            validate: (value) => value > 0 && value <= 100||'Invalid percentage',
                            required: 'Required Field' 
                        }
                    )}
                    disabled={!!promoCode?.id}
                    variant='outlined' size='small'
                    error={!!errors?.percentOff}
                    helperText={errors?.percentOff?.message}
                />
            </Row>
            <Row>
                <label>Plan</label>
                <Controller
                    name='planId'
                    control={control}
                    render={() =>
                        <Select
                            {...register('planId', { required: 'Required Field' })}
                            size='small'
                            displayEmpty
                            disabled={!!promoCode?.id}
                            variant='outlined'
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={promoCode?.plan?.id}
                        >
                            <MenuItem disabled value="">Select Plan</MenuItem>
                            {
                                plans.map((plan) => (
                                    <MenuItem key={`plan-${get(plan, 'id')}`} value={get(plan, 'id')}>{startCase(get(plan, 'attributes.key', ''))}</MenuItem>
                                ))
                            }
                        </Select>
                    }
                />
            </Row>
            <Row>
                <label>Start Date</label>
                <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => (
                        <DesktopDatePicker
                            {...field}
                            value={field.value}
                            onChange={field.onChange}
                            renderInput={(params) => <TextField {...params} {...register('startDate', { required: 'Required Field' })}  variant='outlined' size='small'/>}
                        />
                    )}
                />
            </Row>
            <Row>
                <label>End Date</label>
                <Controller
                    control={control}
                    name="endDate"
                    render={({ field }) => (
                        <DesktopDatePicker
                            {...field}
                            value={field.value}
                            onChange={field.onChange}
                            renderInput={(params) => <TextField {...params} {...register('endDate', { required: 'Required Field' })}  variant='outlined' size='small'/>}
                        />
                    )}
                />
            </Row>
            <ButtonRow>
                {false
                    ? <CircularProgress />
                    :
                    <>
                        <Button type='submit' disabled={!isValid || (!isDirty && !!promoCode?.id)}>Save</Button>
                    </>}
            </ButtonRow>
        </form>
    )
}

interface FilterByPlanProps {
    onChange: (value: string) => void
}

const FilterByPlan = (props: FilterByPlanProps): JSX.Element => {
    const { onChange } = props
    const defaultSelect = {key: 'all', label: 'Filter by Plan'}
    const [selectedOption, setSelectedOption] = useState<ItemOption>(defaultSelect)
    const statuses = [
        {
            key: 'autopylot.starter',
            label: 'AutoPylot Starter'
        },
        {
            key: 'autopylot.unlimited',
            label: 'AutoPylot Unlimited'
        }
    ]

    const handleChange = (event: any) => {
        const value = event.target.value
        const selected = statuses.find((item: ItemOption) => {
            return item.key === value;
        })
        setSelectedOption(selected ? selected : defaultSelect)
        onChange(value)
    }

    return (
        <FormControl>
            <Select
                value={selectedOption.key}
                variant='outlined' size='small'
                onChange={handleChange}
                renderValue={(value: any) => {
                    if (value.length === 0) {
                        return <em>Filter by Plan</em>;
                    }

                    const selected = statuses.find((item: ItemOption) => {
                        return item.key === value;
                    })

                    return selected?.label ?? 'Filter by Plan';
                }}
            >
                <MenuItem key='all' value="all">
                    <em>Filter by Plan</em>
                </MenuItem>
                {
                    statuses.map((status: any) => {
                        return (
                            <MenuItem key={status.key} value={status.key}>{status.label}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}

interface OrderProps {
    orderBy: string
    order: 'asc' | 'desc'
}

interface PaginateProps {
    page: number
    perPage: number
}

class PromoCodesStore {
    @observable
    plans = [] as Array<any>
    @observable
    promoCodes = [] as Array<PromoCode>
    promoCodeStore
    setLoading: (loading: boolean) => void
    @observable
    openSnackbar = false
    @observable
    errorMessage = ''
    @observable
    planKey: string | undefined
    @observable
    currentPromoCode: PromoCode | undefined
    @observable
    showPromoCodeForm = false
    @observable
    orderProps = {orderBy: 'name', order: 'asc'} as OrderProps
    @observable
    paginateProps = {page: 0, perPage: 25} as PaginateProps

    tableHeaders = [
        {
            title: 'Discount Code',
            alignment: 'left'
        },
        {
            title: 'Name',
            orderBy: 'name',
            order: 'asc',
            alignment: 'left'
        },
        {
            title: 'Percent Off',
            orderBy: 'percent_off',
            order: 'asc',
            alignment: 'left'
        },
        {
            title: 'Plan',
            orderBy: 'plan.key',
            order: 'asc',
            alignment: 'left'
        },
        {
            title: 'Start Date',
            orderBy: 'start_date',
            order: 'asc',
            alignment: 'left'
        },
        {
            title: 'End Date',
            orderBy: 'end_date',
            order: 'asc',
            alignment: 'left'
        },
        {
            title: 'Actions'
        }
    ] as Array<IHeader>

    constructor() {
        this.promoCodeStore = new PromoCodeStore();
        this.loadAutopylotPlans()
        this.setLoading = () => { }
        makeObservable(this);
    }

    @action
    loadAutopylotPlans = async () => {
        const { data } = await getAutoPylotPlans('filter[key][]=autopylot.starter&filter[key][]=autopylot.unlimited')
        this.plans = data.data
    }

    loadData = async () => {
        this.setLoading(true)
        await this.promoCodeStore.getPromoCodesAsync(this.params)
        runInAction(() => {
            this.promoCodes = this.promoCodeStore.promoCodes
        })
        this.setLoading(false)
    }

    @action
    sortPromoCodes = (orderBy: string, order: 'asc' | 'desc') => {
        this.orderProps.orderBy = orderBy
        if (order === 'asc') {
            this.orderProps.order = 'desc'
        } else {
            this.orderProps.order = 'asc'
        }
        this.loadData()
    }

    @action
    paginateHandler = (number: number, size: number) => {
        this.paginateProps = {page: number, perPage: size}
        this.loadData()
    }

    @action
    filterPromoCodes = (planKey: string | undefined) => {
        this.planKey = planKey
        this.loadData()
    }

    @action
    openPromoCodeForm = (promoCodeId?: number) => {
        this.currentPromoCode = this.promoCodes.find((promoCode: PromoCode) => promoCode.id === promoCodeId)
        this.showPromoCodeForm = true
    }

    @action
    closePromoCodeForm = () => {
        this.currentPromoCode = undefined
        this.showPromoCodeForm = false
    }

    savePromoCode = async (values: IPromoCode) => {
        const attributes = {
            coupon_id: values.couponId,
            name: values.name,
            percent_off: values.percentOff,
            start_date: values.startDate.format('DD-MM-YYYY'),
            end_date: values.endDate.format('DD-MM-YYYY')
        }
        const relationships = {
            plan: {
                data: {
                    id: values.planId,
                    type: 'plans'
                }
            }
        }
        const payload = {
            data: {
                id: this.currentPromoCode?.id,
                type: 'promo_codes',
                attributes: attributes,
                relationships: relationships
            }
        }
        try {
            if (this.currentPromoCode?.id) {
                await this.promoCodeStore.updatePromoCodeAsync(this.currentPromoCode?.id, payload as IPayload)
            } else {
                await this.promoCodeStore.createPromoCodeAsync(payload as IPayload)
            }

            this.closePromoCodeForm()
            this.loadData()
        } catch (error: any) {
            runInAction(() => {
                if (error.response.status == 422) {
                    const title = error.response.data.errors[0].title
                    const field = error.response.data.errors[0].source.pointer.split('/').slice(-1)[0]
                    this.errorMessage = `${field === 'coupon_id' ? 'Discount Code' : field} ${title}`
                } else {
                    this.errorMessage = 'Something went wrog'
                }
                this.openSnackbar = true
            })
        }
    }

    @action
    handleClose = (_evt: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        this.openSnackbar = false
    }

    @computed
    get params() {
        const sort = this.orderProps.order === 'asc' ? this.orderProps.orderBy : `-${this.orderProps.orderBy}`
        return {
            include: 'plan',
            'filter[plans.key]': this.planKey,
            sort: sort,
            'page[number]': this.paginateProps.page + 1,
            'page[size]': this.paginateProps.perPage
        } as QueryParams
    }

    @computed
    get filteredPromoCodes() {
        return this.promoCodes;
    }

    @computed
    get totalCount() {
        return this.promoCodeStore.meta.recordCount
    }
}

@observer class PromoCodes extends Component<any, any> {
    static contextType = AppContext
    store
    constructor(props: any) {
        super(props)
        this.store = new PromoCodesStore();
    }

    componentDidMount() {
        const { setLoading } = this.context
        this.store.setLoading = setLoading
        this.store.loadData()
    }

    handlePlanChange = (planKey: string) => {
        this.store.filterPromoCodes(planKey === 'all' ? undefined : planKey)
    }

    render() {
        const tableContent = this.store.filteredPromoCodes.map((promoCode: PromoCode, i) => {
            return (
                <TableRow key={i}>
                    <TableCell >
                        <Typography>{promoCode.couponId}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{promoCode.name}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{promoCode.percentOff} %</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{promoCode.plan?.name}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{promoCode.startDate?.format('MM/DD/YYYY')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{promoCode.endDate?.format('MM/DD/YYYY')}</Typography>
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => this.store.openPromoCodeForm(promoCode.id)}>
                            <EditRoundedIcon sx={{'fontSize': '1.2rem'}} />
                        </IconButton>
                    </TableCell>
                </TableRow>
            )
        })

        return (
            <Container>
                <Modal
                    open={this.store.showPromoCodeForm}
                    title={this.store.currentPromoCode ? 'Edit Discount Code' : 'Add New Discount Code'}
                    maxWidth='xs'
                    fullWidth
                    onClose={this.store.closePromoCodeForm}
                >
                    <PromoCodeForm plans={this.store.plans} promoCode={this.store.currentPromoCode} onSubmitForm={this.store.savePromoCode} />
                </Modal>

                <div className='filters-wrapper'>
                    <FilterByPlan onChange={this.handlePlanChange}/>
                    <Button
                        sx={{float: 'right'}}
                        variant='contained'
                        onClick={() => this.store.openPromoCodeForm()}
                    >Add Discount Code</Button>
                </div>
                <div className='table-wrapper'>
                    <Table
                        orderBy={this.store.orderProps.orderBy}
                        order={this.store.orderProps.order}
                        sortHandler={this.store.sortPromoCodes}
                        headers={this.store.tableHeaders}
                        tableContent={tableContent}

                        paginateProps={{
                            page: this.store.paginateProps.page,
                            perPage: this.store.paginateProps.perPage,
                            totalCount: this.store.totalCount,
                            paginateHandler: this.store.paginateHandler
                        }}
                    />
                </div>
                <Snackbar open={this.store.openSnackbar} message={this.store.errorMessage} severity={'error'} autoHideDuration={6000} onClose={this.store.handleClose} />
            </Container>
        )
    }
}

export default withRouter(PromoCodes)