import { createContext, useContext } from "react"
import { UserStore } from "./UserStore"
import { AccountStore } from "./AccountStore"
import { RoleStore } from './RoleStore'

export class RootStore {
    userStore: UserStore
    accountStore: AccountStore
    rolesStore: RoleStore
    constructor() {
        this.userStore = new UserStore()
        this.accountStore = new AccountStore()
        this.rolesStore = new RoleStore()
    }
}

export const StoresContext = createContext(new RootStore())

export const useStores = () => useContext(StoresContext)
