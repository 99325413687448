import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const StyledLink = styled.a`
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: inherit;
    font-weight: normal;
    text-decoration: none;
`

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    letter-spacing: 0.28px;
`

export const SectionName = styled(Typography)`
    font-size: 12px;
    letter-spacing: 0.72px;
    color: #6B6A6E;
    text-transform: uppercase;
    margin-top: 20px;
    font-weight: 500;
`

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .left-section {
        width: 59%;
    }

    & .right-section {
        width: 39%;
    }

    & .left-section {
        > div {
            height: 100%;
        }
    }

    & .right-section {
        display: grid;
        gap: 20px;
    }

    & > section {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`
