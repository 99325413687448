import {makeObservable, runInAction, observable, computed} from "mobx";
import {TextService} from "../services/texts";
import {
  Model,
  IModel,
  IMeta,
  IAttributes,
  IPayload,
  HasOneRelationship,
} from "../types/storeTypes";
import {Contact} from "./ContactStore";

import moment from "moment";

export class TextStore {
  textService: any;
  constructor() {
    this.textService = new TextService();
    makeObservable(this);
  }
  @observable
  texts: Array<Text> = [];
  @observable
  meta = {} as IMeta;
  status = "initial";
  searchQuery = "";

  getAllTextsAsync = async (params: any) => {
    params["page[size]"] = 50;
    await this.getTextsAsync(params);
    if (this.meta.pageCount > 1) {
      let currentPage = 1;
      while (currentPage < this.meta.pageCount) {
        currentPage++;
        params["page[number]"] = currentPage;
        await this.getTextsAsync(params, true);
      }
    }
  };

  getTextsAsync = async (params: any, keepPreviousItems?: boolean) => {
    try {
      // const urlParams = new URLSearchParams(Object.entries(params));
      const data = await this.textService.get(params);

      runInAction(() => {
        if (!keepPreviousItems) {
          this.texts = [];
        }

        this.meta.pageCount = data.meta.page_count;
        this.meta.recordCount = data.meta.record_count;

        data.data.forEach((json: any) => {
          let payload = {
            data: json,
            included: data.included as IModel[],
          } as IPayload;

          let text = new Text(this, payload);

          this.texts.push(text);
        });
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
  };

  @computed
  get totalTexts() {
    return this.texts.length;
  }

  @computed
  get groupedCountByDate() {
    return this.texts.reduce((hash: any, text) => {
      let day = moment(text.createdAt).format("YYYY-MM-DD");

      if (!hash[day]) {
        hash[day] = 0;
      }

      hash[day] += 1;

      return hash;
    }, {});
  }
}

export interface TextAttributes extends IAttributes {
  created_at: string;
  body: string;
  from: string;
  to: string;
  short_message_type: string;
}

export interface IText extends IModel {}

export interface TextRelationships {
  contact: HasOneRelationship;
}

// Domain object Text.
export class Text extends Model {
  attributes: TextAttributes;
  relationships: TextRelationships;

  constructor(store: any, payload: IPayload) {
    super(store, payload);
    this.attributes = payload.data.attributes;
    this.relationships = payload.data.relationships;
  }

  @computed
  get createdAt() {
    return this.attributes.created_at;
  }

  @computed
  get from() {
    return this.attributes.from;
  }

  @computed
  get to() {
    return this.attributes.to;
  }

  @computed
  get textType() {
    return this.attributes.short_message_type;
  }

  @computed
  get contact() {
    let contact_id = this.relationships.contact.data.id;
    let contactData = this.included.find((item: any) => {
      return item.type === "contacts" && item.id === contact_id;
    });
    const payload = {data: contactData, included: [] as IModel[]} as IPayload;

    if (contactData) {
      let contact = new Contact(null, payload);
      return contact;
    } else {
      return null;
    }
  }
}
