import styled from '@emotion/styled'
import Typography from "@mui/material/Typography"
import Popover from "@mui/material/Popover"


type StyledProps = {
    width?: string
}

export const Container = styled.div`
    ${({ width }: StyledProps) => width ? `width: ${width};` : ''}
    padding: 1.8rem;
    border-radius: 10px;
    background-color: #fff;
    height: fit-content;
`

export const Title = styled.div`
    margin-bottom: 18px;
    display: flex;
    align-items: center;
`

export const HelpBox = styled.div`
    border-radius: 15px;
    width: 15px;
    height: 15px;
    font-size: 15px;
    display: flex;
    margin-right: 5px;
    background-color: #BBEF1E;
`

export const PopupTypography = styled(Typography)`
    padding: 10px 15px;
    display: block;

    color: #333;
    font-weight: 400;

    h3, p {
        margin: 0;
        font-size: 15px;
    }
    p {
        margin-bottom: 10px;
        font-size: 13px;
        &.nmb {
            margin-bottom: 0;
        }
    }
`

export const StyledPopover = styled(Popover)`
    .MuiPaper-root {
        max-width: 400px;
        border-radius: 10px;
        border: 1.5px solid #6B6A6E;
        background-color: #BBEF1E;
    }
`