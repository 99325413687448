import { makeObservable, runInAction, observable, computed } from 'mobx';
import { GroupService } from '../services/groups'
import { Model, IModel, IMeta, IAttributes, IPayload } from '../types/storeTypes'

export class GroupStore {
    groupService: any;
    constructor(){
        this.groupService = new GroupService();
        makeObservable(this);
    }
    @observable
    groups: Array<Group> = []
    @observable
    meta = {} as IMeta;
    @observable
    status = "initial";
    searchQuery = "";

    getGroupsAsync = async () => {
        try {
           // const urlParams = new URLSearchParams(Object.entries(params));
           const data = await this.groupService.get()

           runInAction(() => {
               this.groups = []

               this.meta.pageCount = data.meta.page_count;
               this.meta.recordCount = data.meta.record_count;

               data.data.forEach((json: any) => {
                   let payload = {data: json, included: data.included as IModel[]} as IPayload;

                   let text = new Group(this, payload)

                   this.groups.push(text)
               })
               this.status = "loaded";
               console.log(this.status);
           });
        } catch (error) {
           runInAction(() => {
               this.status = "error";
           });
        }
    };

    @computed
    get isLoaded() {
        return this.status === 'loaded'
    }
}


export interface GroupAttributes extends IAttributes {
    created_at: string;
    updated_at: string;
    name: string;
}

export interface IGroup extends IModel {};

// Domain object Group.
export class Group extends Model {
    attributes: GroupAttributes

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
    }

    @computed
    get createdAt() {
        return this.attributes.created_at;
    }

    @computed
    get updatedAt() {
        return this.attributes.updated_at;
    }

    @computed
    get name() {
        return this.attributes.name;
    }
}
