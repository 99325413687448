import API from '../API/config'
import { IContactForm } from '../views/ContactAdmin'
import { IChangePasswordForm } from '../views/ChangePassword'

export interface QueryParams {
  [key: string]: string | undefined;
}

export interface ICheckEmailForm {
  email: string
}

export const checkEmail = (email: ICheckEmailForm) => {
    return API.post('/v1/signup/check_email', email)
}

export const contactToAdministrator = (message: IContactForm) => {
  return API.post('/v1/signup/contact_admin', message)
}

export const getUserByToken = (confirmationToken: string) => {
  return API.get(`/v1/signup/user?confirmation_token=${confirmationToken}`)
}

export const getAgreement = () => {
  return API.get('/v1/contract_versions?per_page=1&order[created_at]=desc&product=AutoPylot')
}

export const getDiscountCode = (code: string) => {
  return API.get(`/promo_codes/${code}`)
}

export const getAutoPylotPlans = (query?: string) => {
  if (query) {
    return API.get(`/plans?${query}`)  //filter[key]=autopylot.unlimited
  } else {
    return API.get('/plans?filter[key]=autopylot.unlimited')
  }
}

export const createSubscription = (accountId: number, planId: number) => {
  const payload = {
      type: 'subscriptions',
      relationships: {
        plan: {
          data: {
            id: planId,
            type: 'plans'
          }
        }
      }
  }

  return API.post('/v1/subscriptions', {data: payload})
}


export const changePassword = (data: IChangePasswordForm) => {
  const payload = {
    password: data.password,
    password_confirmation: data.passwordConfirmation,
    reset_password_token: data.resetPasswordToken
  }
  return API.put('/v1/users/password', payload)
}


export const resetPassword = (userEmail: string) => {
  return API.post('/v1/users/password', {email: userEmail})
}