import API from '../API/config'
import { ICall } from '../views/Calls/Calls'
import { IPayload } from '../types/storeTypes'

export const getCalls = () => {
    return API.get('/calls?include=contact')
}

interface QueryParams {
  filter: string;
  from: string;
  to: string;
}

export class CallService {
  get = async (urlParams: QueryParams) => {
    const options = {
      method: "GET",
    }
    const resp: IPayload = await API.get('/calls', { params: urlParams} )
    return resp.data;
  }
}
