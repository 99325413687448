import styled from '@emotion/styled'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'


export const CheckboxLabel = styled(FormControlLabel)`
    max-width: initial !important;
`

export const ResponsiveForm = styled.form`
    &.with-sidebar {
        div > div > div {
            gap: 13px;
        }
        label {
            font-size: 12px;
            span {
                font-size: 12px;
            }
            .help-box {
                svg {
                    font-size: 14px;
                }
            }
        }
        .MuiFormControl-root {
            width: 55%;
            & div {
                font-size: 12px;
            }
        }
    }
`

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    font-weight: normal;
`

export const PopupTypography = styled(Typography)`
    padding: 10px 15px;
    display: block;
    background-color: rgb(52, 51, 58);
    color: rgb(210, 209, 215);
    font-weight: 400;
`

export const StyledSelect = styled(Select)`
    text-align: left;
    min-width: 65%;

    &.MuiOutlinedInput-root {
        em {
            letter-spacing: 0.28px;
            opacity: 0.4;
            text-align: left;
            font-style: normal;
        }

        > div {
            color: #6B6A6E;
            font-size: 14px;
        }

        & fieldset {
            border-width: 0px;
        }
        &.Mui-focused {
            & fieldset {
                border-width: 0px;
            }
        }
    }
`

export const StyledInputAdornment = styled(InputAdornment)`
    position: absolute;
    right: 1em;

    &.for-dropdown {
        right: 2.4em;
    }
`

export const StyledLink = styled(Link)`
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: 0.8rem;
    font-weight: normal;
    display: flex;

    cursor: pointer;
    svg {
        display: none;
    }
    @media (max-width: 1280px) {
        span {
            display: none;
        }
        svg {
            display: inline-block;
            font-size: 14px;
        }
    }
`

export const StyledTextField = styled(TextField)`
  min-width: 65%;
  text-align: left;
  background-color: #F0F2F5;
  border-radius: 4px;
  & div {
    color: #6B6A6E;
    font-size: 14px;
  }
  & input  {
    background: #F0F2F5 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border-color: #F0F2F5;
  }
  & fieldset {
    border: none;
  }
  @media (max-width: 1280px) {
    width: 55%;
    min-width: auto;
    & div {
      font-size: 12px;
    }
  }
`

export const StyledFormControl = styled(FormControl)`
  width: 65%;
  text-align: left;
  background-color: #F0F2F5;
  border-radius: 4px;
  @media (max-width: 1280px) {
    width: 55%;
    min-width: auto;
    & input {
      font-size: 12px;
    }
  }
`

export const StyledMenuItem = styled(MenuItem)`
  min-width: 60%;
  text-align: left;
  background-color: #F0F2F5;
`

export const Container = styled.div`
    background-color: #F0F2F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 70px;

    & > .title-wrapper, .table-wrapper {
        width: 80%;
        margin-bottom: 20px;
    }
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    gap: 20px;

    & label {
        position: relative;
        display: flex;
        font-size: 14px;
        letter-spacing: 0.28px;
        color: rgba(107, 106, 110, 55%);
        text-align: left;
        max-width: calc(45% - 20px);

        & .help-box {
            margin-left: 5px;
            display: inline-flex;
            // position: absolute;
            // top: 0;
            // right: -1.5em;
        }
    }
    @media (max-width: 1280px) {
      & label {
          font-size: 12px;
      }
    }
`

export const ButtonRow = styled.div`
    margin-top: 20px;
    text-align: left;

    & > span > svg {
        color: #EF681E;
    }
`

export const Title = styled.div`
    letter-spacing: 0.4px;
    color: #6B6A6E;
`

export const Subtitle = styled.div`
    letter-spacing: 0.28px;
    color: #6B6A6E;
    font-size: 14px;
    opacity: 0.3;
`

export const BulkRow = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
`