import { useState } from 'react'
import Card, { Help } from '../../../components/Card/Card'
import { FormContainer, StyledTextField, Row } from '../StyledComponents'
import { ISetting } from "../../../stores/AccountStore";


interface ICRMConsole {
  crmConsoleVersionSetting: ISetting
}

interface CustomSnackbarProps {
  open: boolean
  severity: 'success' | 'error' | 'warning' | 'info'
}

const CRMConsole = ({ crmConsoleVersionSetting }: ICRMConsole) => {
  const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
  const [message, setMessage] = useState('')

  const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
      setMessage(message)
      setSnackbarProps({ severity, open: true })
  }
  const helpText = '<h3>CRM Console Version</h3> <p> To obtain maximum value from AutoPylot, the AutoPylot CRM Console App must be installed in the Account’s CRM system. </p> <p>If this field is blank, no CRM Console App is detected. </p> <p> For more information, contact <a href="mailto:support@autopylot.com">support@autopylot.com</a>. </p>';
    return (
        <Card title='CRM Console' titleAlign='left'>
            <FormContainer>
                <Row>
                    <label>
                        Enterprise CRM
                    </label>
                    <StyledTextField
                        sx={{ width: '40%'}}
                        inputProps={{style: {textAlign: 'right'} }}
                        disabled={true}
                        defaultValue='Microsoft Dynamics'
                        variant='outlined' size='small'
                    />
                </Row>
                <Row>
                    <label style={{marginLeft: '-20px'}}>
                        <Help text={helpText}/>
                        Version
                    </label>
                    <StyledTextField
                        sx={{ width: '40%'}}
                        inputProps={{style: {textAlign: 'right'} }}
                        disabled={true}
                        defaultValue={crmConsoleVersionSetting.rawValue}
                        variant='outlined' size='small'
                    />
                </Row>
            </FormContainer>
        </Card>
    )
}

export default CRMConsole
