import { useEffect } from 'react'
import { useLocation, useSearchParams,useNavigate, useMatch, matchPath, Routes, Route } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import AdapterDateMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MainLayout from '../../components/MainLayout/MainLayout'
import Loading from '../../components/Loading/Loading'
import routes from '../../config/routes'
import { useAppContext } from '../../context/AppContext'
import theme from '../../config/theme'

import { useAuth } from '../../hooks/authHook'
import { saveInLocal } from '../../utils/LocalStorageService'

import ProtectedRoute from '../../components/ProtectedRoute/ProtectedRoute'
import PublicRoute from '../../components/PublicRoute/PublicRoute'

import './App.css'

const App = () => {
  const {currentUser, setLoading, loading} = useAppContext()
  const navigate = useNavigate()
  const matchAutopylotSetup = useMatch('/profile/setup')
  const { isAuthenticated } = useAuth()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const authToken = searchParams.get('token')

  useEffect(() => {
    if (!isAuthenticated && matchAutopylotSetup && authToken) {
        setLoading(true)
        saveInLocal('token', authToken)
        setTimeout(() => {
          setLoading(false)
          navigate("/profile/setup", {replace: true})
        }, 500)
    }
  }, [isAuthenticated])


  const currentRoute = routes.find(route => matchPath(route.path as string, pathname) )
  const hideSidebar = !isAuthenticated || !currentRoute?.useSidebar

  return (
    <ThemeProvider {...{ theme }}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateMoment}>
        <div className="App">
          <MainLayout hideSidebar={!!hideSidebar}>
            <Routes>
              {routes.map((route, i) => (
                <Route
                  key={`route-${i}`}
                  path={route.path}
                  element={route?.protected ?
                    <ProtectedRoute isLoggedIn={isAuthenticated} currentUser={currentUser}>{route.element}</ProtectedRoute>
                    : route?.public ? <PublicRoute isLoggedIn={isAuthenticated}>{route.element}</PublicRoute> : route.element
                  }
                />
              ))}
            </Routes>
          </MainLayout>
          <Loading open={loading} />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
