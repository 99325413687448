import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'


export const StyledTextField = styled(TextField)`
    min-width: 65%;
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
    & div {
        color: #6B6A6E;
        font-size: 14px;
    }
    & input  {
        background: #F0F2F5 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border-color: #F0F2F5;
    }
    & fieldset {
        border: none;
    }
    @media (max-width: 1280px) {
        width: 55%;
        min-width: auto;
        & div {
        font-size: 12px;
        }
    }
`
