import API from "../API/config";
import {IPayload} from "../types/storeTypes";

export interface QueryParams {
  [key: string]: string | number | undefined;
}

export const getCallRatingWords = (params: QueryParams) => {
  return API.get("/call_rating_words", {params: params});
};

export const createCallRatingWord = (payload: IPayload) => {
  return API.post("/call_rating_words", payload);
};

export const updateCallRatingWord = (id: number, payload: IPayload) => {
  return API.put(`/call_rating_words/${id}`, payload);
};

export const destroyCallRatingWord = (id: number) => {
  return API.delete(`/call_rating_words/${id}`);
};

// export class CallRatingWordService {
//   get = async (params: QueryParams) => {
//     const resp: IPayload = await API.get("/call_rating_words", {
//       params: params,
//     });
//     return resp.data;
//   };
// }
