import * as React from "react";
import {
  AuBankAccountElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  FpxBankElement,
  IbanElement,
  IdealBankElement
} from "@stripe/react-stripe-js";
import { TextFieldProps } from '@mui/material/TextField'
import StripeInput from "./StripeInput";
import { StyledTextField } from '../../views/Home/styledComponents'

type StripeElement =
  | typeof AuBankAccountElement
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement
  | typeof FpxBankElement
  | typeof IbanElement
  | typeof IdealBankElement;

interface StripeTextFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, "onChange" | "inputComponent" | "inputProps"> {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>["onChange"];
  stripeElement?: T;
}


export const StripeTextField = <T extends StripeElement>(
  props: StripeTextFieldProps<T>
) => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <StyledTextField
      variant='outlined' size='small'
      error={error}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement
        },
        inputComponent: StripeInput
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...(other as any)}
    />
  );
};

export function StripeTextFieldNumber(
  props: StripeTextFieldProps<typeof CardNumberElement>
) {
  return (
    <StripeTextField
      stripeElement={CardNumberElement}
      {...props}
    />
  );
}

export function StripeTextFieldExpiry(
  props: StripeTextFieldProps<typeof CardExpiryElement>
) {
  return (
    <StripeTextField
      stripeElement={CardExpiryElement}
      {...props}
    />
  );
}

export function StripeTextFieldCVC(
  props: StripeTextFieldProps<typeof CardCvcElement>
) {
  return (
    <StripeTextField
      stripeElement={CardCvcElement}
      {...props}
    />
  );
}
