import API from '../API/config'
import { IPayload } from '../types/storeTypes'

export interface QueryParams {
  [key: string]: string | number | undefined;
}


export const getPromoCodes = () => {
    return API.get('/promo_codes')
}

export const createPromoCode = (payload: IPayload) => {
  return API.post('/promo_codes', payload)
}

export const updatePromoCode = (id: number, payload: IPayload) => {
  return API.put(`/promo_codes/${id}`, payload)
}

export class PromoCodeService {
  get = async (params: QueryParams) => {
    const options = {
      method: "GET",
    }
    const resp: IPayload = await API.get('/promo_codes', { params: params})
    return resp.data;
  }
}
