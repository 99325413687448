import { getFromLocal, removeFromLocal } from '../utils/LocalStorageService'
import { User } from '../services/users'
import jwt_decode from 'jwt-decode'

const useAuth = () => {
    const token = getFromLocal('token')
    const masterToken = getFromLocal('master_token')
    const isAuthenticated = !!token
    const isImpersonatedMode = !!masterToken

    const userInfo = getUserInfo(isAuthenticated, token)
    return { isAuthenticated, isImpersonatedMode, userInfo }
}

const getUserInfo = (authenticated: boolean, token: string) => {
    const user: User = authenticated ? jwt_decode(token) : {id: 0, email: '', expire: 0}
    return user
}

const logout = () => {
    removeFromLocal('token')
    removeFromLocal('master_token')
}

export { useAuth, getUserInfo, logout }