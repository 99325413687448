import get from "lodash/get";
import {makeObservable, runInAction, observable, action, computed} from "mobx";
import {
  UserService,
  QueryParams,
  getUser,
  updateUser,
  deleteAttachment,
} from "../services/users";
import {Account} from "./AccountStore";
import {Group} from "./GroupStore";
import {
  Model,
  IModel,
  IMeta,
  IAttributes,
  IPayload,
  RelationshipItem,
  IRelationships,
  HasOneRelationship,
  HasManyRelationship,
} from "../types/storeTypes";
import {IUser as IUserData} from "../components/UserForm/UserForm";

import startCase from "lodash/startCase";

import moment from "moment";

export class UserStore {
  userService: any;
  constructor() {
    this.userService = new UserService();
    makeObservable(this);
  }
  @observable
  user = {} as User;
  @observable
  users: Array<User> = [];
  @observable
  meta = {} as IMeta;
  @observable
  status = "initial";

  getUserAsync = async (id: number, include?: string) => {
    try {
      runInAction(() => {
        this.status = "initial";
      });
      const {data} = await getUser(id, include);

      runInAction(() => {
        this.user = new User(this, data);
        this.status = "loaded";
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
  };

  updateUserAsync = async (id: number, payload: IPayload) => {
    try {
      runInAction(() => {
        this.status = "initial";
      });
      const {data} = await updateUser(id, payload);

      runInAction(() => {
        this.user = new User(this, data);
        this.status = "loaded";
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
      throw error;
    }
  };

  getUsersAsync = async (params: QueryParams) => {
    try {
      const data = await this.userService.get(params);

      runInAction(() => {
        // TODO update attributes

        this.users = [];

        this.meta.pageCount = data.meta.page_count;
        this.meta.recordCount = data.meta.record_count;

        data.data.forEach((json: any) => {
          let payload = {
            data: json,
            included: data.included as IModel[],
          } as IPayload;

          let user = new User(this, payload);
          // let exists = false
          // if (user.id) {
          //     exists = this.users.map((u) => { return u.id }).includes(user.id)
          // }
          // if (exists) {
          //     this.users.forEach((u, i) => { if (u.id === user.id) this.users[i] = user; });
          // } else {
          this.users.push(user);
          // }
        });
      });
    } catch (error) {
      console.log(error);

      runInAction(() => {
        this.status = "error";
      });
    }
  };

  find(id: number | undefined) {
    if (id) {
      return this.users.find((user: User) => {
        return user.id == id;
      });
    }
  }

  @computed
  get usersWithActivities() {
    return this.users.filter((u: User) => {
      return (
        u.inboundCallsCount +
          u.outboundCallsCount +
          u.shortMessagesCount +
          u.notesCount >
        0
      );
    });
  }

  @computed
  get usersWithoutActivities() {
    return this.users.filter((u: User) => {
      return (
        u.inboundCallsCount +
          u.outboundCallsCount +
          u.shortMessagesCount +
          u.notesCount ==
        0
      );
    });
  }

  @computed
  get totalCalls() {
    return this.users.reduce(
      (partialSum, user: User) =>
        partialSum + user.inboundCallsCount + user.outboundCallsCount,
      0
    );
  }

  @computed
  get totalTexts() {
    return this.users.reduce(
      (partialSum, user: User) => partialSum + user.shortMessagesCount,
      0
    );
  }

  @computed
  get totalNotes() {
    return this.users.reduce(
      (partialSum, user: User) => partialSum + user.notesCount,
      0
    );
  }

  @computed
  get totalUsers() {
    return this.users.length;
  }

  @computed
  get isLoaded() {
    return this.status === "loaded";
  }

  @computed
  get isInitial() {
    return this.status === "initial";
  }
}

export interface UserAttributes extends IAttributes {
  created_at: string;
  confirmed_at: string;
  email: string;
  first_name: string;
  last_name: string;
  mobile_phone_number: string;
  outbound_call_phone_number: string;
  status: string;
  mobile_app_status: string;
  receiver_type: string;
  recording_enabled: boolean;
  // TODO: replace recording_enabled with inbound_recording_enabled and outbound_recording_enabled
  // inbound_recording_enabled: boolean;
  // outbound_recording_enabled: boolean;
  texting_enabled: boolean;
  transcription_enabled: boolean;
  call_with_audio_notification_enabled: boolean;
  plugin_installed: boolean;
  voicemail_greeting?: string;
  last_activity_at: string;
  last_activity_date?: string;
  inbound_calls_count?: number;
  outbound_calls_count?: number;
  short_messages_count?: number;
  sign_in_count?: number;
  notes_count?: number;
  password?: string;
  password_confirmation?: string;
  console_status?: string;
}

export interface UserRelationships extends IRelationships {
  roles: HasManyRelationship;
  did_allocations: HasManyRelationship;
  account: HasOneRelationship;
  group: HasOneRelationship;
  voicemail_greeting_attachment: HasOneRelationship;
}

export interface IUser extends IModel {}

// Domain object User.
export class User extends Model {
  store;
  // @observable
  attributes: UserAttributes;
  // @observable
  relationships: UserRelationships;

  constructor(store: any, payload: IPayload) {
    super(null, payload);
    this.store = store;
    this.attributes = payload.data.attributes;
    this.relationships = payload.data.relationships;
    this.included = payload.included;
  }

  @computed
  get payload() {
    // TODO: replace recording_enabled with inbound_recording_enabled and outbound_recording_enabled
    // inbound_recording_enabled: this.inboundRecordingEnabled,
    // outbound_recording_enabled: this.outboundRecordingEnabled,
    const attributes = {
      first_name: this.firstName,
      last_name: this.lastName,
      mobile_phone_number: this.mobileNumber,
      outbound_call_phone_number: this.outboundCallPhoneNumber,
      recording_enabled: this.recordingEnabled,
      texting_enabled: this.textingEnabled,
      transcription_enabled: this.transcriptionEnabled,
      call_with_audio_notification_enabled: this.callNotificationEnabled,
      plugin_installed: this.pluginInstalled,
      voicemail_greeting: this.voicemailGreeting,
      status: this.status,
      receiver_type: this.receiverType
    } as UserAttributes;
    if (this.attributes.password && this.attributes.password_confirmation) {
      attributes.password = this.attributes.password;
      attributes.password_confirmation = this.attributes.password_confirmation;
    }
    //
    let relationships = {} as UserRelationships;
    for (var relationshipName in this.relationships) {
      if (relationshipName === "voicemail_greeting_attachment") {
        continue;
      }
      if (this.relationships[relationshipName].data) {
        relationships[relationshipName] = this.relationships[relationshipName];
      }
    }

    const data = {
      id: this.id,
      type: "users",
      attributes: attributes,
      relationships: relationships,
    };

    return {
      data: data,
    };
  }

  @action
  assignAttributes = (attributes: UserAttributes) => {
    for (let key in attributes) {
      this.attributes[key] = attributes[key];
    }
  };

  @action
  reload = async (include?: string) => {
    await this.store.getUserAsync(this.id, include);
  };

  @action
  assignAutoPylotNumber = async (phoneNumber: string) => {
    const didAllocationAttributes = {
      type: "did_allocations",
      attributes: {
        did_number: phoneNumber,
      },
    };
    this.relationships.did_allocations = {data: [didAllocationAttributes]};
    await this.store.updateUserAsync(this.id, {
      data: {
        id: this.id,
        type: "users",
        relationships: {
          did_allocations: {
            data: [didAllocationAttributes],
          },
        },
      },
    });
  };

  @action
  update = async (values: IUserData) => {
    if (!this.didAllocation) {
      let data = [
        {
          type: "did_allocations",
          attributes: {
            did_number: values.autopylotNumber?.replace(/[()-\s]/g, ""),
          },
        },
      ];
      this.relationships.did_allocations = {data: data};
    }

    if (values.group !== this.group?.id) {
      if (!this.relationships.group.data) {
        this.relationships.group.data = {id: values.group, type: "groups"};
      } else {
        this.relationships.group.data.id = values.group;
      }
    }

    if (values.roleId && values.roleId !== this.role?.id) {
      if (this.relationships.roles.data.length === 0) {
        this.relationships.roles.data = [
          {id: values.roleId * 1, type: "roles"},
        ];
      } else {
        this.relationships.roles.data[0].id = values.roleId * 1;
      }
    }

    // TODO: replace recording_enabled with inbound_recording_enabled and outbound_recording_enabled
    // inbound_recording_enabled: values.inboundRecordingEnabled,
    // outbound_recording_enabled: values.outboundRecordingEnabled,
    const attributes = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      status: values.status,
      recording_enabled: values.recordingEnabled,
      texting_enabled: values.textingEnabled,
      transcription_enabled: values.transcriptionEnabled,
      call_with_audio_notification_enabled: values.callNotificationEnabled,
      plugin_installed: values.pluginInstalled,
      voicemail_greeting: values.voicemailGreeting,
      outbound_call_phone_number: values.outboundCallPhoneNumber,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      receiver_type: values.receiverType,
    } as UserAttributes;
    if (values.voicemailGreeting) {
      attributes.voicemail_greeting = values.voicemailGreeting;
    }
    this.assignAttributes(attributes);

    if (this.id) {
      await this.store.updateUserAsync(this.id, this.payload);
    } else {
    }
  };

  @computed
  get hasActivities() {
    return (
      this.inboundCallsCount +
        this.outboundCallsCount +
        this.shortMessagesCount +
        this.notesCount >
      0
    );
  }

  @computed
  get hasNoActivities() {
    return (
      this.inboundCallsCount +
        this.outboundCallsCount +
        this.shortMessagesCount +
        this.notesCount ==
      0
    );
  }

  @computed
  get email() {
    return this.attributes.email;
  }

  @computed
  get signInCount() {
    return this.attributes.sign_in_count;
  }

  @computed
  get firstName() {
    return this.attributes.first_name;
  }

  @computed
  get lastName() {
    return this.attributes.last_name;
  }

  @computed
  get fullName() {
    if (this.firstName || this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    } else {
      return "Unknown";
    }
  }

  @computed
  get mobileNumber() {
    return this.attributes.mobile_phone_number;
  }

  @computed
  get outboundCallPhoneNumberFormatted() {
    if (
      this.outboundCallPhoneNumber &&
      this.outboundCallPhoneNumber.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)
    ) {
      return this.outboundCallPhoneNumber.replace(
        /(\+\d+)(\d{3})(\d{3})(\d{4})/,
        "$1 ($2) $3-$4"
      );
    } else {
      return this.outboundCallPhoneNumber;
    }
  }

  @computed
  get outboundCallPhoneNumber() {
    return this.attributes.outbound_call_phone_number;
  }

  @computed
  get recordingEnabled() {
    return this.attributes.recording_enabled;
  }

  @computed
  get textingEnabled() {
    return this.attributes.texting_enabled;
  }

  @computed
  get transcriptionEnabled() {
    return this.attributes.transcription_enabled;
  }

  @computed
  get callNotificationEnabled() {
    return this.attributes.call_with_audio_notification_enabled;
  }

  @computed
  get pluginInstalled() {
    return this.attributes.plugin_installed;
  }

  // TODO: replace recordingEnabled with inboundRecordingEnabled and outboundRecordingEnabled
  // @computed
  // get inboundRecordingEnabled() {
  //     return this.attributes.inbound_recording_enabled;
  // }

  // @computed
  // get outboundRecordingEnabled() {
  //     return this.attributes.outbound_recording_enabled;
  // }

  @computed
  get voicemailGreeting() {
    return this.attributes.voicemail_greeting;
  }

  @computed
  get inboundCallsCount() {
    return this.attributes.inbound_calls_count || 0;
  }

  @computed
  get outboundCallsCount() {
    return this.attributes.outbound_calls_count || 0;
  }

  @computed
  get shortMessagesCount() {
    return this.attributes.short_messages_count || 0;
  }

  @computed
  get notesCount() {
    return this.attributes.notes_count || 0;
  }

  @computed
  get status() {
    return this.attributes.status;
  }

  @computed
  get crmStatus() {
    return "Not Connected";
  }

  @computed
  get consoleStatus() {
    if (this.attributes.console_status)
      return startCase(this.attributes.console_status);

    if (this.status === "inactive") {
      return "Inactive";
    }
    if (this.attributes.sign_in_count === 0) {
      return "Invited";
    }
    if (this.isSalesPerson) {
      return "No Access";
    }
    return "Active";
  }

  @computed
  get mobileAppStatus() {
    return this.attributes.mobile_app_status;
  }

  @computed
  get isMobileAppInstalled() {
    return (
      this.attributes.mobile_app_status !== "not_installed" &&
      !this.isMobileAppDisabled
    );
  }

  @computed
  get isMobileAppDisabled() {
    return this.attributes.mobile_app_status === "disabled";
  }

  @computed
  get receiverType() {
    return this.attributes.receiver_type;
  }

  @computed
  get mobileAppStatusFormatted() {
    if (this.attributes.mobile_app_status === "not_installed") {
      return "Not Installed";
    } else if (this.attributes.mobile_app_status === "disabled") {
      return "Disabled";
    } else {
      return `Installed (${this.attributes.mobile_app_status})`;
    }
  }

  @computed
  get lastActiveFormatted() {
    return this.attributes.last_activity_date
      ? moment(this.attributes.last_activity_date).format("MM/DD/YYYY")
      : "";
  }

  @computed
  get role() {
    let roleId = get(this, "relationships.roles.data[0].id", "");
    let roleData = this.included.find((item: any) => {
      return item.type === "roles" && item.id === roleId;
    });

    const payload = {data: roleData, included: [] as IModel[]} as IPayload;

    if (roleData) {
      let role = new Role(null, payload);
      return role;
    } else {
      return null;
    }
  }

  @computed
  get isAdmin() {
    return this.role?.name === "admin" || this.role?.name === "sales_director";
  }

  @computed
  get isSalesPerson() {
    return this.role?.name === "sales_person";
  }

  @computed
  get isMasterAdmin() {
    return this.role?.name === "master_admin";
  }

  @computed
  get confirmed() {
    return !!this.attributes.confirmed_at;
  }

  @computed
  get account() {
    let accountId = this.relationships.account.data.id;
    let accountData = this.included.find((item: any) => {
      return item.type === "accounts" && item.id === accountId;
    });
    const payload = {data: accountData, included: this.included} as IPayload;

    if (accountData) {
      let account = new Account(null, payload);
      return account;
    } else {
      return null;
    }
  }

  @computed
  get group() {
    let groupId = this.relationships.group?.data?.id;
    if (!groupId) {
      return null;
    }
    let groupData = this.included.find((item: any) => {
      return item.type === "groups" && item.id === groupId;
    });
    const payload = {data: groupData, included: [] as IModel[]} as IPayload;

    if (groupData) {
      let group = new Group(null, payload);
      return group;
    } else {
      return null;
    }
  }

  @computed
  get didAllocation() {
    return this.activeDidAllocations.find(
      (didAllocation: DidAllocation) => didAllocation.original
    );
  }

  @computed
  get activeDidAllocations() {
    return this.didAllocations.filter(
      (didAllocation: DidAllocation) => didAllocation.status === "active"
    );
  }

  @computed
  get orderedDidAllocations() {
    return this.activeDidAllocations.sort(
      (a: DidAllocation, b: DidAllocation) => {
        return a.createdAt.isAfter(b.createdAt) ? 1 : -1;
      }
    );
  }

  @computed
  get originalDidAllocation() {
    return this.orderedDidAllocations[0];
  }

  @computed
  get transferredDidAllocations() {
    return this.orderedDidAllocations.filter(
      (didAllocation: any) => didAllocation.id !== this.originalDidAllocation.id
    );
  }

  @computed
  get didAllocations() {
    if (!this.relationships.did_allocations.data.length) {
      return [];
    }

    const allocations = [] as Array<DidAllocation>;
    this.relationships.did_allocations.data.map(
      (relationship: RelationshipItem) => {
        let didAllocationsData = this.included.filter(
          (item: RelationshipItem) => {
            return (
              relationship.id == item.id && item.type === "did_allocations"
            );
          }
        );

        didAllocationsData.map((didAllocationData) => {
          const payload = {
            data: didAllocationData,
            included: this.included,
          } as IPayload;
          allocations.push(new DidAllocation(null, payload, this));
        });
      }
    );

    return allocations;
  }

  @computed
  get voicemailGreetingAttachment() {
    if (!this.included) {
      return null;
    }

    let attachmentData = this.included.find((item: any) => {
      return (
        item.type === "attachments" &&
        item.attributes.name === "voicemail_greeting"
      );
    });
    const payload = {
      data: attachmentData,
      included: [] as IModel[],
    } as IPayload;

    if (attachmentData) {
      let attachment = new Attachment(null, payload, this);
      return attachment;
    } else {
      return null;
    }
  }

  @action
  removeVoicemailGreetingAttachment = async () => {
    if (this.voicemailGreetingAttachment) {
      await this.voicemailGreetingAttachment.destroy();
      let index = this.included.findIndex((item: any) => {
        return (
          item.type === "attachments" &&
          item.attributes.name === "voicemail_greeting"
        );
      });
      this.included.splice(index, 1);
    }
  };
}

export interface RoleAttributes extends IAttributes {
  name: string;
}

// Domain object Role.
export class Role extends Model {
  attributes: RoleAttributes;
  constructor(store: any, payload: IPayload) {
    super(store, payload);
    this.attributes = payload.data.attributes;
  }

  @computed
  get name() {
    return this.attributes.name;
  }
}

export interface DidAllocationAttributes extends IAttributes {
  status: string;
  original?: boolean;
  created_at?: string;
}

export interface DidAllocationRelationships {
  did: HasOneRelationship;
}

// Domain object DidAllocation.
export class DidAllocation extends Model {
  // id: number
  _user = {} as User;
  attributes = {} as DidAllocationAttributes;
  relationships = {} as DidAllocationRelationships;
  constructor(store: any, payload: IPayload, user: User) {
    super(store, payload);
    this.attributes = payload.data.attributes;
    this.relationships = payload.data.relationships;
    this._user = user;

    // makeObservable(this);
  }

  @computed
  get user() {
    return this._user;
  }

  @computed
  get did() {
    let didId = this.relationships.did.data?.id;

    let didData = this.included.find((item: any) => {
      return item.type === "dids" && item.id === didId;
    });

    const payload = {data: didData, included: [] as IModel[]} as IPayload;

    if (didData) {
      let did = new Did(null, payload);
      return did;
    } else {
      return null;
    }
  }

  @computed
  get status() {
    return this.attributes.status;
  }

  @computed
  get original() {
    return !!this.attributes.original;
  }

  @computed
  get createdAt() {
    return moment(this.attributes.created_at);
  }
}

export interface DidAttributes extends IAttributes {
  phone_number: string;
  status: string;
}

// Domain object Did.
export class Did extends Model {
  attributes: DidAttributes;

  constructor(store: any, payload: IPayload) {
    super(store, payload);
    this.attributes = payload.data.attributes;

    // makeObservable(this);
  }

  @computed
  get phoneNumber() {
    if (this.attributes.phone_number.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
      return this.attributes.phone_number.replace(
        /(\+\d+)(\d{3})(\d{3})(\d{4})/,
        "$1 ($2) $3-$4"
      );
    } else {
      return this.attributes.phone_number;
    }
  }
}

export interface AttachmentAttributes extends IAttributes {
  name: string;
  url: string;
  filename: string;
  byte_size: string;
}

// Domain object Attachment.
export class Attachment extends Model {
  attributes: AttachmentAttributes;
  user: User;

  constructor(store: any, payload: IPayload, user: User) {
    super(store, payload);
    this.attributes = payload.data.attributes;
    this.user = user;
    // makeObservable(this);
  }

  @computed
  get url() {
    return this.attributes.url;
  }

  @action
  destroy = async () => {
    if (this.id) {
      await deleteAttachment(this.id);
    }
  };
}
