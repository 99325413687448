import styled from '@emotion/styled'
import Link from '@mui/material/Link'

export const StyledLink = styled(Link)`
    margin 40px 0;
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: 0.8rem;
    font-weight: normal;

    padding: 8.5px 14px 8.5px 0;
    cursor: pointer;
    display: flex;
`

export const InvoiceTable = styled.table`
    font-size: 0.8rem;
    font-weight: 400;
    width: 100%;
    margin-bottom: 15px;
    border-collapse: collapse;

    thead, tbody {
        tr {
            border-bottom: 1px solid rgb(224, 224, 224);
        }
    }
    th {
        font-size: 0.9rem;
        font-weight: 500;
    }

    td, th {
        padding: 5px;
    }
`