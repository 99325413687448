import styled from '@emotion/styled';
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel'
import { Button as IconWrapButton } from '@mui/material'
import Button from '../../components/Button/Button'

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    letter-spacing: 0.28px;
    font-weight: 400;
`

export const StyledIconButton = styled(IconWrapButton)`
    min-width: auto;
    margin-right: -12px;
    color: rgba(107, 106, 110, 1)
`

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    & > button {
        margin-top: 20px;
    }
`

export const ButtonRow = styled.div`
    margin: 30px 0 20px 0;
    text-align: center;

    & button {
        width: 100%;
    }
    & > span > svg {
        color: #EF681E;
    }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
`

export const ForgotPasswordButton = styled(Button)`
   padding: 10px;
   width: 100%;
`

export const StyledLink = styled(Link)`
    margin 40px 0;
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: 0.8rem;
    font-weight: normal;
`

export const StyledPaper = styled(Paper)`
    border-radius: 10px;
    border: 1px solid #707070;
`


export const ForgotPasswordPaper = styled(StyledPaper)`
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    height: fit-content;

    width: 40%;
    max-width: 433px;
    font: normal normal normal 0.8rem/35px Roboto;
    & .signup-link {
        font-size: 0.8rem;
        margin-top: 30px;
    }
`

export const FooterRow = styled.div`
    padding: 0 1em;
    margin: 0 auto 0 auto;
    width: 50%;
    max-width: 433px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8rem;
    & svg {
        font-size: 0.3rem;
    }
    & div a {
        margin: 0 0.2rem;
        cursor: poiter;
    }
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    & > div > svg {
        cursor: pointer;
        opacity: 0.3;
         &:hover {
             opacity: 0.8;
         }
    }
`

export const ContentWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
`

export const Title = styled.span`
    font-weight: normal;
    color: #6B6A6E;
    letter-spacing: 0.72px;
    font-size: 0.8rem;
    text-transform: uppercase;
`

export const Row = styled.div`
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    text-align: initial;

    &.remember-me {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & label {
            color: rgba(107, 106, 110, 1);
            .Mui-checked {
                svg {
                    color: rgb(3, 117, 255);
                }
            }
        }
    }

    & label {
        color: rgba(107, 106, 110, 0.5);
        /* font: normal normal normal 0.95em/35px Roboto; */
        letter-spacing: 0.28px;
    }
    & span {
        font-size: 0.8rem;
    }

    & input:-webkit-autofill {
        -webkit-background-clip: text;
    }
`

export const StyledTextField = styled(TextField)`
  min-width: 65%;
  text-align: left;
  background-color: #F0F2F5;
  border-radius: 4px;
  & div {
    color: #6B6A6E;
    font-size: 0.8rem;
  }
  & input  {
    background: #F0F2F5 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border-color: #F0F2F5;
  }
  & fieldset {
    border: none;
  }
`
