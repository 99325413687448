import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import { useForm } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'

import { useAppContext } from '../../context/AppContext'
import { User } from "../../stores/UserStore";
import { changePassword } from '../../services/signup'
import { getUser } from '../../services/users'
import { loginUser } from '../../services/login'
import { logout} from '../../hooks/authHook'
import { saveInLocal } from '../../utils/LocalStorageService'
import Snackbar from '../../components/CustomSnackbar/CustomSnackbar'

import { Container, Row } from './styledComponents'
import { ButtonRow } from '../Users/StyledComponents'
import { StyledTextField } from '../Home/styledComponents'

import capitalize from 'lodash/capitalize'

export interface IChangePasswordForm {
    password: string
    passwordConfirmation: string
    resetPasswordToken: string
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

const ChangePassword = (): JSX.Element => {
    let [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate()
    const [showModal, setShowModal] = useState(true)
    const [message, setMessage] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })

    const { loading, setLoading } = useAppContext()
    const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm<IChangePasswordForm>({
        mode: 'onChange',
        defaultValues: { password: '', passwordConfirmation: '' }
    })

    const watchPassword = watch("password", "");

    useEffect(() => {
        if (!searchParams.get('reset_password_token')) {
            navigate('/')
        }
    }, [])

    const closeModal = () => {
        navigate('/')
    }

    const onSubmitChangePassword = async (values: IChangePasswordForm) => {
        try {
            setLoading(true)
            const response = await changePassword({
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
                resetPasswordToken: searchParams.get('reset_password_token') || ''
            })

            const email = response.data.data.attributes.email
            const userResponse = await loginUser({ email: email, password: values.password.trim() })
            if (userResponse.status !== 200) {
                return setMessage('Error')
            }

            const { data } = await getUser(response.data.data.id)
            const user = new User(null, data)
            if (user.isSalesPerson) {
                logout()
                navigate('/')
            } else {
                saveInLocal('token', userResponse.data.token)
                navigate('/dashboard', { replace: true })
            }

            setLoading(false)
        } catch (error: any) {
            console.log(error.response.data.errors);
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                console.log(key, value);

                let reason = value[0];

                setMessage(`${capitalize(key.replace(/_/g, ' '))} ${reason}`)
            });

            setLoading(false)
            setSnackbarProps({ severity: 'error', open: true })
        }
    }

    const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
        setSnackbarProps(state => ({ ...state, open: false }))
    }

    return (
        <Container>
            <Modal
                open={showModal}
                title="CREATE PASSWORD"
                maxWidth='xs'
                fullWidth
                onClose={closeModal}
            >
                <form id="create-password" onSubmit={handleSubmit(onSubmitChangePassword)}>
                    <Row>
                        <label>New Password</label>
                        <StyledTextField
                            {...register('password', { required: 'Required Field' })}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                }
                            }}
                            variant='outlined' size='small' type='password'
                            error={!!errors?.password}
                            helperText={errors?.password?.message}
                        />
                    </Row>
                    <Row>
                        <label>Re-enter Password</label>
                        <StyledTextField
                            {...register('passwordConfirmation', {
                                validate: value => value === watchPassword || "The passwords do not match"
                            })}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                }
                            }}
                            variant='outlined' size='small' type='password'
                            error={!!errors?.passwordConfirmation}
                            helperText={errors?.passwordConfirmation?.message}
                        />
                    </Row>
                    <ButtonRow>
                        {loading ? <CircularProgress /> : <Button type='submit' disabled={!isValid} >Submit</Button>}
                    </ButtonRow>
                </form>
            </Modal>
            <Snackbar open={snackbarProps.open} message={message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />
        </Container>
    )
}

export default ChangePassword
