import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface FilterByLengthProps {
  onChange: any
}

const callLengths = [
  {
    key: 'lt_minute',
    label: 'Calls < 1 Minute',
    from: () => {
      return 0
    },
    to: () => {
      return 59
    }
  },
  {
    key: 'gt_1_lt_5_minutes',
    label: 'Calls > 1 < 5 Minutes',
    from: () => {
      return 60
    },
    to: () => {
      return 299
    }
  },
  {
    key: 'gt_5_minutes',
    label: 'Call > 5 Minutes',
    from: () => {
      return 300
    },
    to: () => {
      return null
    }
  }
]

const FilterByLength = (props: FilterByLengthProps): JSX.Element => {
  const [length, setLength] = React.useState('none');
  const { onChange } = props

  const handleChange = (event: any) => {
    let selectedLength = callLengths.find((length: any) => {
      return length.key === event.target.value;
    })

    if (selectedLength) {
      onChange(selectedLength.from(), selectedLength.to())
    } else {
      onChange(null, null)
    }
    setLength(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <Select
        value={length}
        onChange={handleChange}
      >
        <MenuItem key='none' value="none">
          <em>Filter by Length</em>
        </MenuItem>
        {callLengths
          .map((length: any) => {
            return (
              <MenuItem key={length.key} value={length.key}>{length.label}</MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

export default FilterByLength
