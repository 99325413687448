import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List';

import { StyledPaper } from '../../components/PublicLayout/styledComponents'

export const PaperWrapper = styled.div`
    display: flex;
    gap: 30px;
    width: 100%;
    height: fit-content;
`

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    letter-spacing: 0.28px;
    font-weight: 400;
`

export const SignupPaper = styled(Paper)`
    border-radius: 10px;
    border: 1px solid #707070;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    min-width: 433px;
    width: 45%;
    font: normal normal normal 0.9em/35px Roboto;
    @media (max-width: 1024px) {
        min-width: 400px;
    }
`

export const AgreementPaper = styled(Paper)`
    text-align: initial;
    max-height: 150px;
    overflow: auto;
    padding: 0 10px;
    margin: 20px 0 30px;
    font-size: 0.8rem;
    color: #6B6A6E;
    background-color: #F0F2F5;
    & > div {
        font-size: 0.7rem;
        * {
            display: inline-block;
            line-height: 1.5em;
        }
    }
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;

    & > div > svg {
        cursor: pointer;
        opacity: 0.3;
         &:hover {
             opacity: 0.8;
         }
    }
`

export const ContentWrapper = styled.div`
    padding: 10px 10px 0 10px;
    height: 100%;
    & form {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
`

export const Title = styled.span`
    font-weight: normal;
    color: #6B6A6E;
    letter-spacing: 0.72px;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & label {
        color: rgba(107, 106, 110, 0.5);
        /* font: normal normal normal 0.95em/35px Roboto; */
        letter-spacing: 0.28px;
        align-items: center;
        display: flex;
        & .help-box {
            margin-left: 5px;
            display: inline-flex;
        }
    }
`

export const StyledTextField = styled(TextField)`
    min-width: 60%;
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
    & div {
        color: #6B6A6E;
        font-size: 0.9rem;
    }
    & input  {
        background: #F0F2F5 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border-color: #F0F2F5;
    }
    & fieldset {
        border: none;
  }
`

export const DiscountRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin: 0 auto;
    & div {
        min-width: 50%;
    }

    & label {
        /* color: rgba(107, 106, 110, 0.5); */
        /* font: normal normal normal 0.95em/35px Roboto; */
        white-space: nowrap;
        letter-spacing: 0.28px;
    }
`

export const StyledList = styled(List)`
    font-size: 1em;
    padding-bottom: 0;
    text-align: center;
    & li {
        padding: 0;
        span {
          font-size: 1em;
        }
        svg {
            font-size: 1.5em;
            margin-right: 0.3em;
        }
    }
`

export const MarketingPaper = styled(StyledPaper)`
    height: fit-content;

    width: 55%;
    padding: 16px 24px;

    display: flex;
    flex-direction: column;

    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: 0.9em;
    font-weight: normal;
    @media (max-width: 1024px) {
        min-width: 400px;
    }

    & h4 {
      font-size: 1.2em;
      font-weight: 500;
      margin: 20px 0;
    }

    & h6 {
        font-size: 1em;
        font-weight: normal;
        &.price {
            font-size: 1.2rem;
            margin: 1em 0;
            font-weight: 500;
            & span {
                font-size: 0.7em;
            }
        }
        &.more-info {
            font-size: 0.8em;
            margin-top: 3em;
        }
        &.solving {
            color: #EF681E;
        }
    }

    & hr {
        color: #707070;
        width: 100%
    }

    & .discount-information {
        margin-top: 15px;
        font-size: 1.2em;
        font-weight: 500;
    }
`

export const FeatureBox = styled.div`
    margin: 0 auto;
`

export const ButtonRow = styled.div`
    margin-top: 15px;
    text-align: center;

    & > span > svg {
        color: #EF681E;
    }
`