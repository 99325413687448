import { useState } from 'react'

import Card, { Help } from '../../../components/Card/Card'
import { ISetting } from "../../../stores/AccountStore";
import { FormContainer, StyledTextField, StyledMenuItem, Row } from '../StyledComponents'

import capitalize from 'lodash/capitalize'

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

interface IInboundCallRecording {
    inboundRecordingSetting: ISetting
}

const InboundCallRecording = ({ inboundRecordingSetting }: IInboundCallRecording) => {
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
    const [message, setMessage] = useState('')

    const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setMessage(message)
        setSnackbarProps({ severity, open: true })
    }

    const saveInboundRecording = async (recordingEnabled: string) => {
        try {
            await inboundRecordingSetting.updateValue(recordingEnabled === 'true')
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    const helpText = '<h3>Inbound call recording</h3> <p>By enabling inbound call recording, all inbound calls to your AutoPylot Users are recorded.</p> <p>When enabled, caller are notified with the message “This call may be monitored or recorded for quality assurance purposes.” before connecting to User.</p><p>Admins can disable recording for specific Users by going to the Portal/Users page. </p><p class="nmb">More information about Inbound Call Recording within the AutoPylot service can be found at:</p> <a target="_blank" href="https://www.autopylot.com/features/inbound_call_recording">www.autopylot.com/features/inbound_call_recording.</a>'

    return (
        <Card title='INBOUND CALL RECORDING' titleAlign='left'>
            <FormContainer>
                <Row>
                    <label style={{marginLeft: '-20px'}}>
                        <Help text={helpText}/>
                        Global Inbound Call Recording
                    </label>
                    <StyledTextField
                        sx={{ width: '40%' }}
                        select
                        onChange={(e) => saveInboundRecording(e.target.value)}
                        defaultValue={inboundRecordingSetting.value.toString()}
                        variant='outlined' size='small'
                    >
                        <StyledMenuItem key='yes' value='true'>
                            Enabled
                        </StyledMenuItem>
                        <StyledMenuItem key='no' value='false'>
                            Disabled
                        </StyledMenuItem>
                    </StyledTextField>
                </Row>
            </FormContainer>
        </Card>
    )
}

export default InboundCallRecording
