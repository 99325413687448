import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

export const Container = styled.div`
    background-color: #F0F2F5;
    padding: 30px 70px;
    margin: 0 auto;
`

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    & .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        > div {
            width: 49%;
            height: 100%;
        }
    }

    & > section {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`

export const FormContainer = styled.form`
    p {
        font-size: 14px;
        opacity: .7;
    }

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

    }
`

export const StyledTextField = styled(TextField)`
    width: 40%;
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
    & div {
      color: #6B6A6E;
      font-size: 14px;
    }
    & input  {
      background: #F0F2F5 0% 0% no-repeat padding-box;
      border-radius: 4px;
      border-color: #F0F2F5;
    }
    & fieldset {
      border: none;
    }
`

export const StyledMenuItem = styled(MenuItem)`
    min-width: 60%;
    text-align: left;
    background-color: #F0F2F5;
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    gap: 20px;

    & label {
        font-size: 14px;
        letter-spacing: 0.28px;
        color: rgba(107, 106, 110, 55%);
        text-align: left;
    }
`
