import {useNavigate} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {useAppContext} from "../../context/AppContext";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import LockIcon from "@mui/icons-material/Lock";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import HelpIcon from "@mui/icons-material/Help";

import PopUp from "../../components/PopUp/PopUp";
import Button from "../../components/Button/Button";
import Snackbar from "../../components/CustomSnackbar/CustomSnackbar";

import {
  Account,
  AccountAttributes,
  AccountRelationships,
} from "../../stores/AccountStore";
import {UserStore} from "../../stores/UserStore";

import {observer} from "mobx-react";
import {
  makeObservable,
  /*runInAction,*/ observable,
  computed,
  action,
} from "mobx";
import {IModel} from "../../types/storeTypes";
import {useAuth, logout} from "../../hooks/authHook";
import {isValidPhoneNumber} from "react-phone-number-input";
import {loadStripe} from "@stripe/stripe-js";
import {
  CardNumberElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import {StyledTextField} from "../../views/Home/styledComponents";
import {SubsriptionDetailsBox} from "../../views/OrderSummary/styledComponents";
import {
  Row,
  StyledSelect,
  BillingPaper,
  TitleWrapper,
  Title,
  ContentWrapper,
  StyledMenuItem,
  StyledAutocomplete,
  StyledFormControl,
  PopupTypography,
} from "./styledComponents";
import {ButtonRow} from "../SignUp/StyledComponents";
import {PhoneNumberField} from "../../components/PhoneNumberInput/PhoneNumberInput";
import PublicLayout from "../../components/PublicLayout/PublicLayout";
import {capitalizeInput} from "../../utils/InputUtils";

import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from "./commonTextFields";

import {currencyFormat} from "../../utils/NumberFormatService";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import "moment-timezone";

interface ItemOption {
  key: string;
  label: string;
  disabled?: boolean;
}

export interface IBillingInformationForm {
  name: string;
  contactFirstName: string;
  contactLastName: string;
  billingEmail: string;
  businessEmail: string;
  addressMainStreet: string;
  addressSecondaryStreet: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  addressCountry: string;
  phoneNumber: string;
  businessPhoneNumber: string;
  paymentMethod: string;
  cardNumber: string;
  expirationDate: string;
  cvc: string;
  billingType: string;
  stripeToken: string;
  holderName: string;
  holderType: string;
  routingNumber: string;
  accountNumber: string;
  //billing address
  billingStreet: string;
  billingStreet2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingCountry: string;
}

interface CustomSnackbarProps {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
}

class BillingInformationStore {
  userStore = new UserStore();
  @observable
  user = {} as IModel;
  @observable
  country: string = "";
  countries: ItemOption[] = [
    {key: "us", label: "United States of America"},
    {key: "ca", label: "Canada"},
  ];
  usStates: ItemOption[] = [
    {key: "AK", label: "Alaska"},
    {key: "AL", label: "Alabama"},
    {key: "AR", label: "Arkansas"},
    {key: "AZ", label: "Arizona"},
    {key: "CA", label: "California"},
    {key: "CO", label: "Colorado"},
    {key: "CT", label: "Connecticut"},
    {key: "DC", label: "District of Columbia"},
    {key: "DE", label: "Delaware"},
    {key: "FL", label: "Florida"},
    {key: "GA", label: "Georgia"},
    {key: "HI", label: "Hawaii"},
    {key: "IA", label: "Iowa"},
    {key: "ID", label: "Idaho"},
    {key: "IL", label: "Illinois"},
    {key: "IN", label: "Indiana"},
    {key: "KS", label: "Kansas"},
    {key: "KY", label: "Kentucky"},
    {key: "LA", label: "Louisiana"},
    {key: "MA", label: "Massachusetts"},
    {key: "MD", label: "Maryland"},
    {key: "ME", label: "Maine"},
    {key: "MI", label: "Michigan"},
    {key: "MN", label: "Minnesota"},
    {key: "MO", label: "Missouri"},
    {key: "MS", label: "Mississippi"},
    {key: "MT", label: "Montana"},
    {key: "NC", label: "North Carolina"},
    {key: "ND", label: "North Dakota"},
    {key: "NE", label: "Nebraska"},
    {key: "NH", label: "New Hampshire"},
    {key: "NJ", label: "New Jersey"},
    {key: "NM", label: "New Mexico"},
    {key: "NV", label: "Nevada"},
    {key: "NY", label: "New York"},
    {key: "OH", label: "Ohio"},
    {key: "OK", label: "Oklahoma"},
    {key: "OR", label: "Oregon"},
    {key: "PA", label: "Pennsylvania"},
    {key: "RI", label: "Rhode Island"},
    {key: "SC", label: "South Carolina"},
    {key: "SD", label: "South Dakota"},
    {key: "TN", label: "Tennessee"},
    {key: "TX", label: "Texas"},
    {key: "UT", label: "Utah"},
    {key: "VA", label: "Virginia"},
    {key: "VT", label: "Vermont"},
    {key: "WA", label: "Washington"},
    {key: "WI", label: "Wisconsin"},
    {key: "WV", label: "West Virginia"},
    {key: "WY", label: "Wyoming"},
  ];
  canadianProvinces: ItemOption[] = [
    {key: "ON", label: "Ontario"},
    {key: "QC", label: "Quebec"},
    {key: "BC", label: "British Columbia"},
    {key: "AB", label: "Alberta"},
    {key: "MB", label: "Manitoba"},
    {key: "SK", label: "Saskatchewan"},
    {key: "NS", label: "Nova Scotia"},
    {key: "NB", label: "New Brunswick"},
    {key: "NL", label: "Newfoundland and Labrador"},
    {key: "PE", label: "Prince Edward Island"},
    {key: "NT", label: "Northwest Territories"},
    {key: "NU", label: "Nunavut"},
    {key: "YT", label: "Yukon"},
  ] as Array<ItemOption>;

  constructor() {
    makeObservable(this);
  }

  @action
  getUser = async (userId: number) => {
    try {
      await this.userStore.getUserAsync(
        userId,
        "account,account.settings,account.subscription,account.subscription.plan,account.subscription.promo_code"
      );
    } catch (error: any) {
      throw new Error("Something went wrong");
    }
  };

  @action
  submitBillingInformation = async (
    values: IBillingInformationForm,
    saveBillingAddress: boolean
  ) => {
    try {
      const subscription = {
        data: {
          id: this.userStore.user.account?.subscription?.id,
          type: "subscriptions",
          attributes: {
            kind: "trial",
          },
        },
      };
      const attributes = {
        name: values.name,
        contact_first_name: values.contactFirstName,
        contact_last_name: values.contactLastName,
        business_email: values.businessEmail,
        address_main_street: values.addressMainStreet,
        address_secondary_street: values.addressSecondaryStreet,
        address_city: values.addressCity,
        address_state: values.addressState,
        address_zip: values.addressZip,
        address_country: values.addressCountry,
        phone_number: values.phoneNumber
          .replace(/[()-\s]/g, "")
          .replace(/_/g, ""),
        business_phone_number: values.businessPhoneNumber
          .replace(/[()-\s]/g, "")
          .replace(/_/g, ""),
        billing_email: values.billingEmail,
        billing_type: values.billingType,
        stripe_token: values.stripeToken,
        time_zone: moment.tz.guess(),
      } as AccountAttributes;

      const accountAddresses = [];
      if (saveBillingAddress) {
        accountAddresses.push({
          type: "account_addresses",
          attributes: {
            category: "billing",
            street: values.billingStreet,
            street2: values.billingStreet2,
            city: values.billingCity,
            state: values.billingState,
            zip: values.billingZip,
            country: values.billingCountry,
          },
        });
      }

      const relationships = {
        account_addresses: {
          data: accountAddresses,
        },
        subscription: subscription,
      };

      await this.userStore.user.account?.update(
        attributes,
        relationships as AccountRelationships
      );
    } catch (error: any) {
      throw new Error("Something went wrong");
    }
  };

  @action
  setCountry = (country: string) => {
    this.country = country;
  };

  @computed
  get account() {
    return this.userStore.user.account;
  }

  @computed
  get states() {
    if (this.country === "us") {
      return this.usStates;
    }
    if (this.country === "ca") {
      return this.canadianProvinces;
    }
    return [];
  }

  @computed
  get stateLabel() {
    if (this.country === "ca") {
      return "Province";
    }
    return "State";
  }

  @computed
  get zipCodeLabel() {
    if (this.country === "ca") {
      return "Postal Code";
    }
    return "Zip Code";
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || "");

const BillingInformation = observer((): JSX.Element => {
  let navigate = useNavigate();
  const [store] = useState<BillingInformationStore>(
    new BillingInformationStore()
  );
  const {userInfo} = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({
    open: false,
    severity: "success",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const {loading, setLoading, refreshCurrentUser} = useAppContext();

  const loadData = async () => {
    try {
      setLoading(true);
      await store.getUser(userInfo.id);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
      setSnackbarProps((state) => ({...state, severity: "error", open: true}));
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const openSignUpModal = () => {};

  const saveBillingInformation = async (
    values: IBillingInformationForm,
    saveBillingAddress: boolean
  ) => {
    try {
      setLoading(true);

      await store.submitBillingInformation(values, saveBillingAddress);
      await refreshCurrentUser();
      navigate("/congratulations");
    } catch (error) {
      setLoading(false);
      setErrorMsg("Something went wrong");
      setSnackbarProps((state) => ({...state, severity: "error", open: true}));
    }
  };

  const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
    setOpenSnackbar(false);
  };

  return (
    <PublicLayout>
      <BillingPaper>
        <TitleWrapper>
          <Title>Business and Billing Information</Title>
        </TitleWrapper>
        <ContentWrapper>
          <Elements stripe={stripePromise}>
            {store.account?.id && (
              <CheckoutForm
                onSubmitForm={saveBillingInformation}
                account={store.account}
                countries={store.countries}
                setCountry={store.setCountry}
                states={store.states}
                stateLabel={store.stateLabel}
                zipCodeLabel={store.zipCodeLabel}
                setSnackbarProps={setSnackbarProps}
                setErrorMsg={setErrorMsg}
              />
            )}
          </Elements>
        </ContentWrapper>
      </BillingPaper>

      <Snackbar
        open={snackbarProps.open}
        message={errorMsg}
        severity={snackbarProps.severity}
        autoHideDuration={6000}
        onClose={handleClose}
      />
    </PublicLayout>
  );
});

interface ICheckoutForm {
  onSubmitForm: (
    values: IBillingInformationForm,
    saveBillingAddress: boolean
  ) => void;
  account: Account;
  countries: Array<ItemOption>;
  states: Array<ItemOption>;
  stateLabel: string;
  zipCodeLabel: string;
  setCountry: (msg: string) => void;
  setSnackbarProps: (arg: CustomSnackbarProps) => void;
  setErrorMsg: (msg: string) => void;
}

const CheckoutForm = (props: ICheckoutForm) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [showBillingAddressFields, setShowBillingAddressFields] =
    useState(false);
  const {account, countries, states, setCountry, stateLabel, zipCodeLabel} =
    props;
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const openPopUp = Boolean(anchorElement);
  const [state, setState] = useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: undefined,
    expiredError: undefined,
    cvcError: undefined,
  });
  const billingMethods = [
    {
      key: "card",
      label: "Bill Card",
      disabled: false,
    },
    {
      key: "bill_us",
      label: "Bill us (Send invoice - Must have 50 + Users)",
      disabled: true,
    },
    // {
    //     key: 'ach',
    //     label: 'ACH',
    //     disabled: false
    // }
  ];

  const {loading, setLoading} = useAppContext();
  const defaultValues = {
    name: account.name,
    contactFirstName: account.contactFirstName,
    contactLastName: account.contactLastName,
    businessEmail: account.businessEmail,
    phoneNumber: account.phoneNumber,
    businessPhoneNumber: account.businessPhoneNumber,
    addressMainStreet: account.addressMainStreet,
    addressSecondaryStreet: account.addressSecondaryStreet,
    addressCity: account.addressCity,
    addressState: account.addressState,
    addressZip: account.addressZip,
    addressCountry: account.addressCountry,
    billingEmail: account.billingEmail,
    // billing address
    billingStreet: "",
    billingStreet2: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    billingCountry: "",

    paymentMethod: "card",
    billingType: "automatic",
    // cc
    cardNumber: "",
    cvc: "",
    expirationDate: "",
    // ach
    holderName: "",
    holderType: "individual",
    routingNumber: "",
    accountNumber: "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors, isValid},
    watch,
    control,
    trigger,
  } = useForm<IBillingInformationForm>({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const watchPaymentMethod = watch("paymentMethod", "card");

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    trigger();
  }, [showBillingAddressFields]);

  const onSubmitBilligInformation = async (values: IBillingInformationForm) => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      setLoading(true);

      let payload = null;

      switch (values.paymentMethod) {
        case "card":
          const card = elements.getElement(CardNumberElement);

          if (card == null) {
            setLoading(false);
            return;
          }
          payload = await stripe?.createToken(card);
          values.billingType = "automatic";
          break;
        case "ach":
          const bankAccountData = {
            country: "us",
            currency: "usd",
            routing_number: values.routingNumber,
            account_number: values.accountNumber,
            account_holder_name: values.holderName,
            account_holder_type: values.holderType,
          };
          payload = await stripe?.createToken("bank_account", bankAccountData);
          values.billingType = "automatic";
          break;
        case "bill_us":
          values.billingType = "manual";
          break;
        default:
          console.log(`Sorry, we are out of.`);
          setLoading(false);
          return;
      }

      if (values.billingType === "automatic") {
        if (payload?.error) {
          props.setErrorMsg(payload.error?.message || "Something went wrog");
          props.setSnackbarProps({severity: "error", open: true});
          setLoading(false);
          return;
        } else {
          values.stripeToken = payload?.token?.id || "";
        }
      }

      await props.onSubmitForm(values, showBillingAddressFields);

      setLoading(false);
    } catch (error: any) {
      props.setErrorMsg("Something went wrog");
      props.setSnackbarProps({severity: "error", open: true});
      setLoading(false);
    }
  };

  const handleChange = (e: React.SyntheticEvent) => {
    setShowBillingAddressFields((e.target as HTMLInputElement).value === "no");
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };

  const logEvent = (name: string) => (event: any) => {
    console.log(`[${name}]`, event);
  };

  const onElementChange = (field: any, errorField: any) => () => {
    console.log(field, errorField);
  };

  const {cardNumberError, expiredError, cvcError} = state;

  const cardForm = (
    <>
      <Row>
        <label>Card number</label>
        <StripeTextFieldNumber
          error={Boolean(cardNumberError)}
          labelErrorMessage={cardNumberError}
          onChange={() => {
            onElementChange("cardNumberComplete", "cardNumberError");
          }}
        />
      </Row>
      <Row>
        <label>CCV</label>
        <StripeTextFieldCVC
          error={Boolean(cvcError)}
          labelErrorMessage={cvcError}
          onChange={() => {
            onElementChange("cvcComplete", "cvcError");
          }}
        />
      </Row>
      <Row>
        <label>Expiration date</label>
        <StripeTextFieldExpiry
          error={Boolean(expiredError)}
          labelErrorMessage={expiredError}
          onChange={() => {
            onElementChange("expiredComplete", "expiredError");
          }}
        />
      </Row>
    </>
  );

  const achForm = (
    <>
      <Row>
        <label>Name</label>
        <StyledTextField
          {...register("holderName", {
            validate: (value) =>
              watchPaymentMethod !== "ach" ||
              (!!value && watchPaymentMethod === "ach") ||
              "Required Field",
          })}
          defaultValue={account.holderName}
          variant="outlined"
          size="small"
          error={!!errors?.holderName}
          helperText={errors?.holderName?.message}
        />
      </Row>
      <Row>
        <label>Type</label>
        <Controller
          name="holderType"
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value}}) => (
            <StyledTextField
              {...register("holderType", {
                validate: (value) =>
                  watchPaymentMethod !== "ach" ||
                  (!!value && watchPaymentMethod === "ach") ||
                  "Required Field",
              })}
              defaultValue="individual"
              select
              variant="outlined"
              size="small"
            >
              <StyledMenuItem key="individual" value="individual">
                Individual
              </StyledMenuItem>
              <StyledMenuItem key="company" value="company">
                Company
              </StyledMenuItem>
            </StyledTextField>
          )}
        />
      </Row>
      <Row>
        <label>Routing number</label>
        <StyledTextField
          {...register("routingNumber", {
            validate: (value) => {
              return (
                watchPaymentMethod !== "ach" ||
                (!!value && watchPaymentMethod === "ach") ||
                "Required Field"
              );
            },
          })}
          placeholder="110000000"
          variant="outlined"
          size="small"
          error={!!errors?.routingNumber}
          helperText={errors?.routingNumber?.message}
        />
      </Row>
      <Row>
        <label>Account number</label>
        <StyledTextField
          {...register("accountNumber", {
            validate: (value) =>
              watchPaymentMethod !== "ach" ||
              (!!value && watchPaymentMethod === "ach") ||
              "Required Field",
          })}
          placeholder="000123456789"
          variant="outlined"
          size="small"
          error={!!errors?.accountNumber}
          helperText={errors?.accountNumber?.message}
        />
      </Row>
    </>
  );

  return (
    <form onSubmit={handleSubmit(onSubmitBilligInformation)}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{padding: "0 40px 0 0"}}>
          <Row>
            <label>Business Legal Name</label>
            <StyledTextField
              {...register("name", {required: "Required Field"})}
              defaultValue={account.name}
              onKeyUp={(e) => {
                capitalizeInput(e, "name", setValue);
              }}
              variant="outlined"
              size="small"
              error={!!errors?.name}
              helperText={errors?.name?.message}
            />
          </Row>
          <Row>
            <label>Address 1</label>
            <StyledTextField
              {...register("addressMainStreet", {required: "Required"})}
              onKeyUp={(e) => {
                capitalizeInput(e, "addressMainStreet", setValue);
              }}
              variant="outlined"
              size="small"
              error={!!errors?.addressMainStreet}
              helperText={errors?.addressMainStreet?.message}
            />
          </Row>
          <Row>
            <label>Address 2</label>
            <StyledTextField
              {...register("addressSecondaryStreet")}
              onKeyUp={(e) => {
                capitalizeInput(e, "addressSecondaryStreet", setValue);
              }}
              variant="outlined"
              size="small"
              error={!!errors?.addressSecondaryStreet}
              helperText={errors?.addressSecondaryStreet?.message}
            />
          </Row>
          <Row>
            <label>City</label>
            <StyledTextField
              {...register("addressCity", {required: "Required"})}
              onKeyUp={(e) => {
                capitalizeInput(e, "addressCity", setValue);
              }}
              variant="outlined"
              size="small"
              error={!!errors?.addressCity}
              helperText={errors?.addressCity?.message}
            />
          </Row>
          <Row>
            <label>Country</label>
            <StyledFormControl>
              <Controller
                name="addressCountry"
                control={control}
                render={({field: {onChange, value}}) => (
                  <StyledSelect
                    {...register("addressCountry", {required: "Required"})}
                    displayEmpty
                    onChange={(e) => {
                      setCountry(e.target.value as string);
                      onChange(e);
                    }}
                    variant="outlined"
                    size="small"
                    error={!!errors?.addressCountry}
                    renderValue={(value: any) => {
                      if (!value || value.length === 0) {
                        return <em>Select Country</em>;
                      }

                      const selected = countries.find((item: ItemOption) => {
                        return item.key === value;
                      });

                      return selected?.label ?? "Select Country";
                    }}
                  >
                    {countries.map((option: ItemOption) => (
                      <StyledMenuItem key={option.key} value={option.key}>
                        {option.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                )}
              />
              {!!errors?.addressCountry && (
                <FormHelperText error>
                  {!!errors?.addressCountry?.message
                    ? errors?.addressCountry?.message
                    : "Required Field"}
                </FormHelperText>
              )}
            </StyledFormControl>
          </Row>

          <Row>
            <label>{stateLabel}</label>
            <Controller
              name="addressState"
              control={control}
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <StyledAutocomplete
                  options={states}
                  autoHighlight
                  getOptionLabel={(option: ItemOption | any) => option.label}
                  noOptionsText="Select Country"
                  onChange={(event: React.SyntheticEvent<Element, Event>) =>
                    onChange(event)
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      {...register("addressState", {required: "Required"})}
                      name="addressState"
                      autoComplete="off"
                      variant="outlined"
                      size="small"
                      error={!!errors?.addressState}
                      helperText={errors?.addressState?.message}
                    />
                  )}
                />
              )}
            />
          </Row>
          <Row>
            <label>{zipCodeLabel}</label>
            <StyledTextField
              {...register("addressZip", {required: "Required"})}
              variant="outlined"
              size="small"
              error={!!errors?.addressZip}
              helperText={errors?.addressZip?.message}
            />
          </Row>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: "0 40px 0 0"}}>
          <Row>
            <label>First Name</label>
            <StyledTextField
              {...register("contactFirstName", {required: "Required"})}
              defaultValue={account.contactFirstName}
              onKeyUp={(e) => {
                capitalizeInput(e, "contactFirstName", setValue);
              }}
              variant="outlined"
              size="small"
              error={!!errors?.contactFirstName}
              helperText={errors?.contactFirstName?.message}
            />
          </Row>
          <Row>
            <label>Last Name</label>
            <StyledTextField
              {...register("contactLastName", {required: "Required"})}
              defaultValue={account.contactLastName}
              onKeyUp={(e) => {
                capitalizeInput(e, "contactLastName", setValue);
              }}
              variant="outlined"
              size="small"
              error={!!errors?.contactLastName}
              helperText={errors?.contactLastName?.message}
            />
          </Row>
          <Row>
            <label>Business Email</label>
            <StyledTextField
              {...register("businessEmail", {required: "Required"})}
              defaultValue={account.businessEmail}
              variant="outlined"
              size="small"
              error={!!errors?.businessEmail}
              helperText={errors?.businessEmail?.message}
            />
          </Row>
          <Row>
            <label>Contact Phone</label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: "Required",
                validate: (value) => {
                  return (
                    isValidPhoneNumber(`+1${value}`) || "Invalid Phone Number"
                  );
                },
              }}
              render={({field}) => (
                <PhoneNumberField
                  {...register("phoneNumber", {
                    required: "Required",
                    validate: (value) => {
                      return (
                        isValidPhoneNumber(`+1${value}`) ||
                        "Invalid Phone Number"
                      );
                    },
                  })}
                  name={field.name}
                  size="small"
                  sx={{minWidth: "65%", ["fieldset"]: {border: "none"}}}
                  error={!!errors?.phoneNumber}
                  helperText={errors?.phoneNumber?.message}
                />
              )}
            />
          </Row>
          <Row>
            <label>
              Billing Email
              <div
                className="help-box"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <HelpIcon htmlColor="#42B1F2" fontSize="small" />
              </div>
              <PopUp
                open={openPopUp}
                anchorElement={anchorElement}
                onClose={handlePopoverClose}
              >
                <PopupTypography sx={{p: 1}}>
                  If you don't know billing e-mail enter the same email as in
                  business email box above. You can update Billing email in
                  Console later on..
                </PopupTypography>
              </PopUp>
            </label>
            <StyledTextField
              {...register("billingEmail", {required: "Required"})}
              defaultValue={account.billingEmail}
              variant="outlined"
              size="small"
              error={!!errors?.billingEmail}
              helperText={errors?.billingEmail?.message}
            />
          </Row>
          <Row>
            <label>Business Phone</label>
            <PhoneNumberField
              {...register("businessPhoneNumber", {
                required: "Required",
                validate: (value) => {
                  return (
                    isValidPhoneNumber(
                      value.match(/^\+1*/) ? value : `+1${value}`
                    ) || "Invalid Phone Number"
                  );
                },
              })}
              size="small"
              sx={{minWidth: "65%", ["fieldset"]: {border: "none"}}}
              error={!!errors?.businessPhoneNumber}
              helperText={errors?.businessPhoneNumber?.message}
            />
          </Row>
        </Grid>

        <Grid item xs={12} md={12} sx={{padding: "0 40px 0 0"}}>
          <Row>
            <FormControl>
              <FormLabel id="show-billing-address-radio-buttons-group-label">
                Is your billing address the same as business address?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="show-billing-address-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                onChange={handleChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Row>
        </Grid>

        {showBillingAddressFields && (
          <>
            <Grid item xs={12} md={6} sx={{padding: "0 40px 0 0"}}>
              <Row>
                <label>Address 1</label>
                <StyledTextField
                  {...register("billingStreet", {required: "Required"})}
                  onKeyUp={(e) => {
                    capitalizeInput(e, "billingStreet", setValue);
                  }}
                  variant="outlined"
                  size="small"
                  error={!!errors?.billingStreet}
                  helperText={errors?.billingStreet?.message}
                />
              </Row>
              <Row>
                <label>Address 2</label>
                <StyledTextField
                  {...register("billingStreet2")}
                  onKeyUp={(e) => {
                    capitalizeInput(e, "billingStreet2", setValue);
                  }}
                  variant="outlined"
                  size="small"
                  error={!!errors?.billingStreet2}
                  helperText={errors?.billingStreet2?.message}
                />
              </Row>
              <Row>
                <label>City</label>
                <StyledTextField
                  {...register("billingCity", {required: "Required"})}
                  onKeyUp={(e) => {
                    capitalizeInput(e, "billingCity", setValue);
                  }}
                  variant="outlined"
                  size="small"
                  error={!!errors?.billingCity}
                  helperText={errors?.billingCity?.message}
                />
              </Row>
            </Grid>
            <Grid item xs={12} md={6} sx={{padding: "0 40px 0 0"}}>
              <Row>
                <label>Country</label>
                <StyledFormControl>
                  <Controller
                    name="billingCountry"
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <StyledSelect
                        {...register("billingCountry", {required: "Required"})}
                        displayEmpty
                        onChange={(e) => {
                          setCountry(e.target.value as string);
                          onChange(e);
                        }}
                        variant="outlined"
                        size="small"
                        error={!!errors?.billingCountry}
                        renderValue={(value: any) => {
                          if (!value || value.length === 0) {
                            return <em>Select Country</em>;
                          }

                          const selected = countries.find(
                            (item: ItemOption) => {
                              return item.key === value;
                            }
                          );

                          return selected?.label ?? "Select Country";
                        }}
                      >
                        {countries.map((option: ItemOption) => (
                          <StyledMenuItem key={option.key} value={option.key}>
                            {option.label}
                          </StyledMenuItem>
                        ))}
                      </StyledSelect>
                    )}
                  />
                  {!!errors?.billingCountry && (
                    <FormHelperText error>
                      {!!errors?.billingCountry?.message
                        ? errors?.billingCountry?.message
                        : "Required Field"}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Row>
              <Row>
                <label>{stateLabel}</label>
                <Controller
                  name="billingState"
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value}}) => (
                    <StyledAutocomplete
                      options={states}
                      autoHighlight
                      getOptionLabel={(option: ItemOption | any) =>
                        option.label
                      }
                      noOptionsText="Select Country"
                      onChange={(event: React.SyntheticEvent<Element, Event>) =>
                        onChange(event)
                      }
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          {...register("billingState", {required: "Required"})}
                          name="billingState"
                          autoComplete="off"
                          variant="outlined"
                          size="small"
                          error={!!errors?.billingState}
                          helperText={errors?.billingState?.message}
                        />
                      )}
                    />
                  )}
                />
              </Row>
              <Row>
                <label>{zipCodeLabel}</label>
                <StyledTextField
                  {...register("billingZip", {required: "Required"})}
                  variant="outlined"
                  size="small"
                  error={!!errors?.billingZip}
                  helperText={errors?.billingZip?.message}
                />
              </Row>
            </Grid>
          </>
        )}
      </Grid>
      <hr />
      <SubsriptionDetailsBox>
        <div>
          <Typography>Service Plan Selected</Typography>
        </div>
        <div>
          <Typography>
            <b>AutoPylot Unlimited</b>
          </Typography>
        </div>
      </SubsriptionDetailsBox>
      <SubsriptionDetailsBox>
        <div>
          <Typography>
            <b>Subscription Fee</b>
          </Typography>
        </div>
        <div>
          <Typography>
            <b>{currencyFormat(account.subscriptionFee)} (billed monthly)</b>
          </Typography>
        </div>
      </SubsriptionDetailsBox>
      <SubsriptionDetailsBox>
        <div>
          <Typography>
            <small>
              You'll be billed 30 days after signup unless you cancel within
              first 30 days period.
            </small>
          </Typography>
        </div>
        <div>&nbsp;</div>
      </SubsriptionDetailsBox>
      <hr />
      <Grid container sx={{padding: "40px 0 0 0"}}>
        <Grid item xs={12} md={6} sx={{padding: "0 4rem"}}>
          <Typography>Secure Payment Info</Typography>
          <LockIcon sx={{fontSize: "5rem"}} />
          <Typography>
            All transactions are secure and encrypted, and we do not store your
            credit card information.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: "0 40px 0 0"}}>
          <Row>
            <label>Payment Method</label>
            <Controller
              name="paymentMethod"
              control={control}
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <StyledTextField
                  {...register("paymentMethod")}
                  select
                  id="paymentMethod"
                  defaultValue={paymentMethod}
                  variant="outlined"
                  size="small"
                >
                  {billingMethods.map((option: ItemOption) => (
                    <StyledMenuItem
                      key={option.key}
                      value={option.key}
                      disabled={option.disabled}
                    >
                      {option.label}
                    </StyledMenuItem>
                  ))}
                </StyledTextField>
              )}
            />
          </Row>

          {watchPaymentMethod === "card" && cardForm}
          {watchPaymentMethod === "ach" && achForm}
        </Grid>
      </Grid>

      <ButtonRow>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button type="submit" disabled={!isValid && !isEmpty(errors)}>
            Sign Up
          </Button>
        )}
        {/*<StyledLink>Cancel</StyledLink>*/}
      </ButtonRow>
    </form>
  );
};

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#6B6A6E",
      letterSpacing: "0.025em",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.4375em",
      fontFamily: "roboto",
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
export default BillingInformation;
