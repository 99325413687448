import React, {useState} from "react"
import Typography from "@mui/material/Typography"
import { Container, Title, HelpBox, PopupTypography, StyledPopover } from './StyledComponents'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


import uuid from 'lodash/uniqueId'

interface IHelpProps {
    text: string
}

export const Help = ({text}: IHelpProps) => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
    const openPopUp = Boolean(anchorElement)
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorElement(null);
    };

    return (
        <>
            <HelpBox
                onClick={handlePopoverOpen}
            >
                <QuestionMarkIcon htmlColor='#6B6A6E' fontSize='inherit'/>
            </HelpBox>
            <StyledPopover
                id={uuid()}
                open={openPopUp}
                anchorEl={anchorElement}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <PopupTypography sx={{ p: 1 }} dangerouslySetInnerHTML={{ __html: text }}></PopupTypography>
            </StyledPopover>
        </>
    )
}


interface ICardProps {
    title?: string
    titleAlign?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined
    width?: string
    children: React.ReactChild
    helpText?: string
}

const Card = ({ title, titleAlign, width, children, helpText }: ICardProps) => {
    return (
        <Container {...{ width }}>
            <Title  style={{marginLeft: !helpText ? '0px' : '-20px'}} >
                {helpText && <Help text={helpText}/>}
                <Typography align={titleAlign||'center'} variant="subtitle1">
                    {title}
                </Typography>
            </Title>
            {children}
        </Container>
    )
}

export default Card
