import { useNavigate } from 'react-router-dom'
import PublicLayout from '../../components/PublicLayout/PublicLayout'

import { CongratulationsPaper, TitleWrapper, Title, ContentWrapper, StyledTypography, StyledLink } from './styledComponents'
import Button from '../../components/Button/Button'

const Congratulations = () => {
    let navigate = useNavigate()

    return (
        <PublicLayout>
            <CongratulationsPaper>
                <TitleWrapper>
                    <Title>
                        Congratulations!
                    </Title>
                </TitleWrapper>
                <ContentWrapper>
                    <StyledTypography>Your AutoPylot account has successfully been created.</StyledTypography>
                    <StyledTypography>
                        To log in, you'll need to create a password.
                    </StyledTypography>
                    <Button onClick={()=> navigate('/create-password')}>CREATE PASSWORD</Button>
                    <StyledTypography sx={{marginTop: '20px'}}>
                        If you have any questions or need help, please contact us at <StyledLink href='mailto:support@autopylot.com'>support@autopylot.com</StyledLink>
                    </StyledTypography>
                    <StyledTypography>
                        Your AutoPylot team
                    </StyledTypography>
                </ContentWrapper>
            </CongratulationsPaper>

        </PublicLayout>
    )
}

export default Congratulations
