import React, {useEffect, useState} from "react";

import PublicLayout from "../../components/PublicLayout/PublicLayout";
import {getAgreement} from "../../services/signup";

import {AgreementPaper} from "../OrderSummary/styledComponents";

export interface LoginFormData {
  email: string;
  password: string;
  remember_me?: boolean;
}

export interface ILoginForm {
  email: string;
  password: string;
}

const Agreement = () => {
  const [agreementContent, setAgreementContent] = useState("false");

  const loadData = async () => {
    try {
      const {data} = await getAgreement();
      setAgreementContent(data[0].content);
    } catch (error: any) {
      throw new Error("Something went wrong");
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <PublicLayout hideFooter={true}>
      <AgreementPaper>
        <div dangerouslySetInnerHTML={{__html: agreementContent}} />
      </AgreementPaper>
    </PublicLayout>
  );
};

export default Agreement;
