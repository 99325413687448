import { ItemOption } from './types'

export const countries: ItemOption[] = [
  {key: 'us', label: 'United States of America'},
  {key: 'ca', label: 'Canada'}
]

export const usaStates: ItemOption[] = [
  {key: "AK", label: "Alaska"}, {key: "AL", label: "Alabama"}, {key: "AR", label: "Arkansas"}, {key: "AZ", label: "Arizona"}, {key: "CA", label: "California"}, {key: "CO", label: "Colorado"}, {key: "CT", label: "Connecticut"}, {key: "DC", label: "District of Columbia"}, {key: "DE", label: "Delaware"}, {key: "FL", label: "Florida"}, {key: "GA", label: "Georgia"}, {key: "HI", label: "Hawaii"}, {key: "IA", label: "Iowa"}, {key: "ID", label: "Idaho"}, {key: "IL", label: "Illinois"}, {key: "IN", label: "Indiana"}, {key: "KS", label: "Kansas"}, {key: "KY", label: "Kentucky"}, {key: "LA", label: "Louisiana"}, {key: "MA", label: "Massachusetts"}, {key: "MD", label: "Maryland"}, {key: "ME", label: "Maine"}, {key: "MI", label: "Michigan"}, {key: "MN", label: "Minnesota"}, {key: "MO", label: "Missouri"}, {key: "MS", label: "Mississippi"}, {key: "MT", label: "Montana"}, {key: "NC", label: "North Carolina"}, {key: "ND", label: "North Dakota"}, {key: "NE", label: "Nebraska"}, {key: "NH", label: "New Hampshire"}, {key: "NJ", label: "New Jersey"}, {key: "NM", label: "New Mexico"}, {key: "NV", label: "Nevada"}, {key: "NY", label: "New York"}, {key: "OH", label: "Ohio"}, {key: "OK", label: "Oklahoma"}, {key: "OR", label: "Oregon"}, {key: "PA", label: "Pennsylvania"}, {key: "RI", label: "Rhode Island"}, {key: "SC", label: "South Carolina"}, {key: "SD", label: "South Dakota"}, {key: "TN", label: "Tennessee"}, {key: "TX", label: "Texas"}, {key: "UT", label: "Utah"}, {key: "VA", label: "Virginia"}, {key: "VT", label: "Vermont"}, {key: "WA", label: "Washington"}, {key: "WI", label: "Wisconsin"}, {key: "WV", label: "West Virginia"}, {key: "WY", label: "Wyoming"}
]

export const canadianProvinces: ItemOption[] = [
  {key: 'ON', label: 'Ontario'}, {key: 'QC', label: 'Quebec'}, {key: 'BC', label: 'British Columbia'}, {key: 'AB', label: 'Alberta'}, {key: 'MB', label: 'Manitoba'}, {key: 'SK', label: 'Saskatchewan'}, {key: 'NS', label: 'Nova Scotia'}, {key: 'NB', label: 'New Brunswick'}, {key: 'NL', label: 'Newfoundland and Labrador'}, {key: 'PE', label: 'Prince Edward Island'}, {key: 'NT', label: 'Northwest Territories'}, {key: 'NU', label: 'Nunavut'}, {key: 'YT', label: 'Yukon'}
] as Array<ItemOption>
