import { StyledTypography, StyledLink } from './StyledComponents'

const SetupMessage = () => {
    return (
        <>
            <StyledTypography align="center" sx={{ marginBottom: '1em' }}>Congratulations! You are set-up and ready to download AutoPylot app.</StyledTypography>
            <StyledTypography align="center">Go <StyledLink href="http://autopylot.com/appstore">here</StyledLink> for iOS or <StyledLink href="http://autopylot.com/playstore">here</StyledLink> for Android version</StyledTypography>
        </>
    )
}

export default SetupMessage