import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

export const Container = styled.div`
  background-color: #f0f2f5;
  padding: 30px 70px;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  & > section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  & .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    > div {
      width: 49%;
      height: 100%;
    }
  }
`;

export const FormContainer = styled.form`
  p {
    font-size: 14px;
    opacity: 0.7;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 40%;
  text-align: left;
  background-color: #f0f2f5;
  border-radius: 4px;
  & div {
    color: #6b6a6e;
    font-size: 14px;
    background-color: #fff;
  }
  & input {
    background: #f0f2f5 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border-color: #f0f2f5;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-width: 1px;
    }
    &.Mui-focused {
      & fieldset {
        border-color: #54abd9;
        border-width: 1px;
      }
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  min-width: 60%;
  text-align: left;
  background-color: #fff;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  gap: 20px;

  & label {
    font-size: 14px;
    letter-spacing: 0.28px;
    color: rgba(107, 106, 110, 55%);
    text-align: left;

    display: flex;
  }
`;

export const PopupTypography = styled(Typography)`
  padding: 10px 15px;
  display: block;
  background-color: rgb(52, 51, 58);
  color: rgb(210, 209, 215);
  font-weight: 400;
`;

export const RuleTd = styled.td`
  text-align: left;
`;

export const CategoryTr = styled.tr`
  border-top: 1px solid #333;
  border-bottom: 2px solid #333;
`;

export const CategoryTd = styled.td`
  text-align: left;
  font-weight: 700;
`;
