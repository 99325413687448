import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '../../../components/Card/Card'
import Typography from '@mui/material/Typography'
import Modal from '../../../components/Modal/Modal'
import { getUpcomingInvoice } from "../../../services/accounts"
import { StyledLink, InvoiceTable } from './styledComponents'
import get from 'lodash/get'
import moment from 'moment'
import { currencyFormat } from "../../../utils/NumberFormatService"

interface ISubscriptionFee {
    account: any
    isLoading: boolean
}

const SubscriptionFee = ({account, isLoading}: ISubscriptionFee) => {
    const [showFullEstimate, setShowFullEstimate] = useState(false)
    const [estimateLoaded, setEstimateLoaded] = useState(false)
    const [estimate, setEstimate] = useState({})

    const getEstimate = async () => {
        const { data } = await getUpcomingInvoice()

        setEstimate(data)
        setEstimateLoaded(true)
    }

    useEffect(() => {
        if (!isLoading) {
            getEstimate()
        }
    }, [isLoading])

    return (
        <>
            <Modal
                open={showFullEstimate}
                title="Upcoming Invoice"
                maxWidth='sm'
                fullWidth
                onClose={() => setShowFullEstimate(showFullEstimate => !showFullEstimate)}
            >
                <InvoiceTable>
                    <thead>
                        <tr>
                            <th align='left'>
                                Description
                            </th>
                            <th>
                                Users
                            </th>
                            <th>
                                Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                            AutoPylot Unlimited Service Licence for ({moment(get(estimate, 'data.attributes.lines[0].period_start')).format('MMM DD')} - {moment(get(estimate, 'data.attributes.lines[0].period_end')).format('MMM DD, YYYY')})
                            </td>
                            <td align='center'>
                                { get(estimate, 'data.attributes.lines[0].quantity') }
                            </td>
                            <td align='center'>
                                { currencyFormat(get(estimate, 'data.attributes.lines[0].amount')/100.0) }
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                            <td colSpan={2} align='right'>
                                Subotal
                            </td>
                            <td align='center'>
                                { currencyFormat(get(estimate, 'data.attributes.subtotal')/100.0) }
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align='right'>
                                Total
                            </td>
                            <td align='center'>
                                { currencyFormat(get(estimate, 'data.attributes.total')/100.0) }
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align='right'>
                                Amount due
                            </td>
                            <td align='center'>
                                { currencyFormat(get(estimate, 'data.attributes.amount_due')/100.0) }
                            </td>
                        </tr>
                    </tfoot>
                </InvoiceTable>
            </Modal>
            <Card title='Next Month Invoice / Subscription Fee' titleAlign='left'>
                {!estimateLoaded ? <CircularProgress /> : <>
                    <Typography variant='h5' align='left'>{ currencyFormat(get(estimate, 'data.attributes.amount_due')/100.0) }</Typography>
                    <Typography align='left'>Next Charge: { moment(get(estimate, 'data.attributes.period_end')).format('MM/DD/YYYY') }</Typography>
                    <StyledLink onClick={(e) => setShowFullEstimate(showFullEstimate => !showFullEstimate) }>View details</StyledLink>
                </>}
            </Card>
        </>
    )
}

export default SubscriptionFee
