import { makeObservable, observable } from 'mobx';

export interface IModel {
    id?: number | undefined;
    type: string;
    attributes: any;
    relationships: any;
    included: Array<any>;
    meta: IMeta;
}

export interface RelationshipItem {
    id?: number;
    type: string;
    attributes?: IAttributes;
}

export interface HasOneRelationship {
    data: RelationshipItem;
}

export interface HasManyRelationship {
    data: Array<RelationshipItem>;
}

export interface IMeta {
    pageCount: number;
    recordCount: number;
}

export interface IAttributes {
   [key: string]: string | number | boolean | undefined;
}

export interface IRelationships {
   [key: string]: HasOneRelationship | HasManyRelationship;
}

export interface Data {
    data: IModel
}

export interface IPayload {
    data: IModel
    included: Array<IModel>
    meta: IMeta
}

export class Model implements IModel {
    id: number | undefined
    type: string
    @observable
    attributes = {} as IAttributes
    @observable
    relationships = {}
    @observable
    included = [] as IModel[]
    meta: IMeta

    constructor(store: any, payload: IPayload) {
        this.id = payload.data.id
        this.type = payload.data.type
        this.meta = payload.meta
        this.included = payload.included

        this.attributes = payload.data.attributes
        this.relationships = payload.data.relationships

        makeObservable(this);
    }
}
