import { useState } from 'react'
import Card, { Help } from '../../../components/Card/Card'
import { FormContainer, StyledTextField, StyledMenuItem, Row } from '../StyledComponents'
import { ISetting } from "../../../stores/AccountStore";
import capitalize from 'lodash/capitalize'

interface TextConsent {
    textConsentSetting: ISetting
    verbalTextingConsentSetting: ISetting,
    onTextConsentUpdated: () => void
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

const TextConsent = ({ textConsentSetting, verbalTextingConsentSetting, onTextConsentUpdated }: TextConsent) => {
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
    const [message, setMessage] = useState('')

    const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setMessage(message)
        setSnackbarProps({ severity, open: true })
    }

    const onChange = async (value: string) => {
        try {
            await textConsentSetting.updateValue(value === 'true');
            onTextConsentUpdated();
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    const onChangeVerbacConsent = async (value: string) => {
        try {
            return await verbalTextingConsentSetting.updateValue(value === 'true')
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    const helpText = '<h3>Enterprise Text Messaging</h3> <p>Enables AutoPylot Users to send, receive text messages from the mobile app and AutoPylot CRM Console App.</p> <p class="nmb">More information about Text within the AutoPylot service can be found at:</p> <a target="_blank" href="https://www.autopylot.com/features/consent_to_text">www.autopylot.com/features/consent_to_text.</a>'
    const verbalHelpText = '<h3>Verbal Consent</h3> <p>When enabled, AutoPylot Users can confirm they have verbally received consent to sent text messages to their contacts and/or leads.</p> <p>When disabled, AutoPylot Users will need to have their contact and/or lead send a text to their AutoPylot number first to obtain implied consent. </p><p class="nmb">More information about Text and Consent to Text within the AutoPylot service can be found at:</p> <a target="_blank" href="https://www.autopylot.com/features/consent_to_text">www.autopylot.com/features/consent_to_text.</a>'

    return (
        <Card title='Text Messaging' titleAlign='left'>
            <FormContainer>
                <Row>
                    <label style={{marginLeft: '-20px'}}>
                        <Help text={helpText}/>
                        Enterprise Texting
                    </label>
                    <StyledTextField
                        sx={{ width: '40%' }}
                        select
                        onChange={(e) => onChange(e.target.value)}
                        defaultValue={textConsentSetting.value.toString()}
                        variant='outlined' size='small'
                    >
                        <StyledMenuItem key='yes' value='true'>
                            Enabled
                        </StyledMenuItem>
                        <StyledMenuItem key='no' value='false'>
                            Disabled
                        </StyledMenuItem>
                    </StyledTextField>
                </Row>
                <Row>
                    <label style={{marginLeft: '-20px'}}>
                        <Help text={verbalHelpText}/>
                        Verbal Consent
                    </label>
                    <StyledTextField
                        sx={{ width: '40%' }}
                        select
                        onChange={(e) => onChangeVerbacConsent(e.target.value)}
                        defaultValue={verbalTextingConsentSetting.value.toString()}
                        variant='outlined' size='small'
                    >
                        <StyledMenuItem key='yes' value='true'>
                            Enabled
                        </StyledMenuItem>
                        <StyledMenuItem key='no' value='false'>
                            Disabled
                        </StyledMenuItem>
                    </StyledTextField>
                </Row>
            </FormContainer>
        </Card>
    )
}

export default TextConsent
