import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar'
import { Container, ImpersonatePanel } from './styledComponents'
import { useAuth, logout } from '../../hooks/authHook'
import Footer from '../../components/Footer/Footer'
import { getFromLocal, saveInLocal, removeFromLocal } from '../../utils/LocalStorageService'
import { useAppContext } from '../../context/AppContext'

import Button from '@mui/material/Button';


interface ISidebar {
    hideSidebar?: boolean
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null
}

const MainLayout = ({ hideSidebar, children }: ISidebar): JSX.Element => {
    const { currentUser, openSidebar, setOpenSidebar } = useAppContext()
    const { isImpersonatedMode } = useAuth()
    const navigate = useNavigate()

    const toggleDrawer = () => {
        setOpenSidebar((open) => !open);
    };

    const stopImpersonatedMode = () => {
        const masterToken = getFromLocal('master_token')
        removeFromLocal('master_token')
        saveInLocal('token', masterToken)

        navigate('/accounts', { replace: true })
    }

    const handleLogout = (evt: React.MouseEvent<HTMLLIElement>) => {
        setOpenSidebar(false)
        logout()
        navigate('/')
    }

    return (
        <React.Fragment>
            {hideSidebar ? null : <Sidebar open={openSidebar} handleOpen={toggleDrawer} handleLogout={handleLogout} />}
            {isImpersonatedMode ? <ImpersonatePanel>Viewing as: <b>{currentUser?.fullName}</b> <Button onClick={() => stopImpersonatedMode()} size="small" variant="outlined">Stop</Button> </ImpersonatePanel> : null}
            <Container isPublicPage={!currentUser} isSidebarOpen={openSidebar} sidebarHidden={hideSidebar}>
                {children}
            </Container>
            {!hideSidebar && <Footer />}
        </React.Fragment>
    )
}

export default MainLayout
