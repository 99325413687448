import React, { useState, useEffect, useMemo } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { Control, FieldErrors } from 'react-hook-form'
import { User } from "../../stores/UserStore";

import {
    StyledTextField
} from './StyledComponents'

interface IUserSelectorProps {
    users: Array<User>
    errors: FieldErrors
    onSelectUser: (userId: number | null) => void
}

interface Option {
    id?: number;
    fullName: string;
}


export const UserSelector = ({ users, errors, onSelectUser }: IUserSelectorProps) => {
    const [options, setOptions] = React.useState<readonly Option[]>([]);
    const loading = false

    React.useEffect(() => {
        const items = users.map((u) => {return {id: u.id, fullName: u.fullName} })
        setOptions([...items]);
    }, [users.length]);

    return (
        <Autocomplete
            isOptionEqualToValue={(option, value) => option.fullName === value.fullName}
            getOptionLabel={(option) => option.fullName}
            options={options}
            loading={loading}
            onChange={(e, option) => { onSelectUser(option?.id||null) }}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    placeholder={"Select User"}
                    name="userId"
                    variant='outlined'
                    size='small'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    error={!!errors?.userId}
                    helperText={errors?.userId?.message}
                />
            )}
        />
    )
}

