const saveInLocal = (identifier: string, data: any) => {
    localStorage.setItem(identifier, JSON.stringify(data))
}

const getFromLocal = (identifier: string) => {
    return JSON.parse(localStorage.getItem(identifier) as any)
}

const removeFromLocal = (identifier: string) => {
    localStorage.removeItem(identifier)
}
export { saveInLocal, getFromLocal, removeFromLocal }