import React from 'react'
import Autocomplete, { AutocompleteCloseReason, AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { SxProps } from '@mui/system'

interface IProps {
    id?: string
    open: boolean
    options: any
    loading: boolean
    value?: any
    placeholder?: string
    onSelectOption?: (option: any) => void
    onClose: ((event: React.SyntheticEvent<Element, Event>, reason: AutocompleteCloseReason) => void) | undefined
    onChangeValue: (value: string) => void
    error?: boolean | undefined
    helperText?: React.ReactNode | undefined
    register?: any
    sx?: SxProps
}

const AutopylotAutoComplete = (
    {
        id,
        open,
        loading,
        value,
        placeholder,
        options,
        onClose,
        onChangeValue,
        onSelectOption,
        error,
        helperText,
        register,
        sx
    }
        : IProps) => {

    const onChange = (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => {
        onSelectOption && onSelectOption(value)
    }

    return (
        <Autocomplete
            id={id}
            sx={sx}
            open={open}
            onClose={onClose}
            getOptionLabel={(option: any) => option.number.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')}
            options={options}
            loading={loading}
            value={value}
            onChange={onChange}
            renderInput={(params: any) => (
                <TextField
                    {...register}
                    {...params}
                    placeholder={placeholder}
                    onChange={(evt) => onChangeValue(evt.currentTarget.value)}
                    variant='outlined'
                    size='small'
                    error={error}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress id="spinner" color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    )
}

export default AutopylotAutoComplete
