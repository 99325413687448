import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { getInvoices } from "../../../services/accounts"

import Card from '../../../components/Card/Card'
import Modal from '../../../components/Modal/Modal'
import { StyledLink, Row, InvoicesTable } from './styledComponents'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { currencyFormat } from "../../../utils/NumberFormatService"


interface IBillHistory {
    account: any
    isLoading: boolean
}

interface IInvoice {
    id: string
    type: string
    attributes: IInvoiceAttributes
}

interface IInvoiceAttributes {
    status: string
    number: string
    amount_due: number
    total: number
    invoice_pdf?: string
    period_start: string
    period_end: string
    lines: Array<ILine>
}

interface ILine {
    period_start: string
    period_end: string
    description: string
    amount: number
    quantity: number
}

const BillHistory = ({account, isLoading}: IBillHistory) => {
    const [showFullHistory, setShowFullHistory] = useState(false)
    const [invoicesLoaded, setInvoicesLoaded] = useState(false)
    const [invoices, setInvoices] = useState([] as Array<IInvoice>)

    const getInvoiceHistory = async () => {
        const { data } = await getInvoices(3)

        setInvoices(data.data)
        setInvoicesLoaded(true)
    }

    useEffect(() => {
        if (!isLoading) {
            getInvoiceHistory()
        }
    }, [isLoading])

    const invoiceTitle = (invoice: IInvoice) => {
        if (get(invoice, 'attributes.invoice_pdf')) {
            return (
                <StyledLink target='_blank' href={get(invoice, 'attributes.invoice_pdf')}>
                    {get(invoice, 'attributes.total') === 0 ? 'Your 30 Day Free Trial Invoice' : moment(get(invoice, 'attributes.period_end')).format('MMM YYYY')}
                </StyledLink>)

        } else {
            return (
                moment(get(invoice, 'attributes.period_end')).format('MMM YYYY')
            )
        }
    }

    return (
        <>
            <Modal
                open={showFullHistory}
                title="Full History"
                maxWidth='sm'
                fullWidth
                onClose={() => setShowFullHistory(showFullHistory => !showFullHistory)}
            >
                <InvoicesTable>
                    <thead>
                        <tr>
                            <th align='left'>
                                Date
                            </th>
                            <th align='left'>
                                Invoice number
                            </th>
                            <th align='left'>
                                Status
                            </th>
                            <th align='left'>
                                Billing period
                            </th>
                            <th align='left'>
                                Amount
                            </th>
                            <th align='left'>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        invoices.map((invoice) => (
                            <tr key={invoice.id}>
                                <td>{moment(get(invoice, 'attributes.period_end')).format('MMM YYYY')}</td>
                                <td>{get(invoice, 'attributes.number')}</td>
                                <td>{capitalize(get(invoice, 'attributes.status'))}</td>
                                <td>Monthly</td>
                                <td>
                                    {currencyFormat(get(invoice, 'attributes.total')/100.0) }
                                </td>
                                <td>
                                    {get(invoice, 'attributes.invoice_pdf') && <StyledLink target='_blank' href={get(invoice, 'attributes.invoice_pdf')}>
                                        Download
                                    </StyledLink>}
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </InvoicesTable>
            </Modal>
            <Card title='Invoice History' titleAlign='left'>
                {!invoicesLoaded ? <CircularProgress /> : <>
                    {invoices.map((invoice: IInvoice) => (
                        <Row key={invoice.id}>
                            {invoiceTitle(invoice)}
                            <p>{currencyFormat(get(invoice, 'attributes.total')/100.0) }</p>
                        </Row>
                    ))}
                    <StyledLink onClick={(e) => setShowFullHistory(showFullHistory => !showFullHistory) }>View full history</StyledLink>
                </>}
            </Card>
        </>
    )
}

export default BillHistory
