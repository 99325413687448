import words from 'lodash/words'

const nonPrentedKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Enter',
    'Shift',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown'
]

export const capitalizeInput = (e: React.KeyboardEvent<HTMLDivElement>, fieldName: string, setValue: any) => {
    const value = (e.target as HTMLInputElement).value
    let parts = words(value, /[^, ]+/g)
    parts = parts.map((word)=> {
        return word.charAt(0).toUpperCase() + word.slice(1);
    })

    if ( e.code !== 'Space') setValue(fieldName, parts.join(' '))
}

export const validateAreaCodeInput = (e: React.KeyboardEvent<HTMLDivElement>, fieldName: string, setValue: any) => {
    const key = e.key
    const value = (e.target as HTMLInputElement).value

    if (!nonPrentedKeys.includes(key) && (isNaN(parseInt(key)) || value.length >= 3)) {
        e.preventDefault()
    }
}