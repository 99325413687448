import {useEffect, useState} from "react";

import {StylesConfig} from "react-select";
import CreatableSelect from "react-select/creatable";
import Card from "../../../components/Card/Card";
import {FormContainer, Row} from "../StyledComponents";
import {ISetting} from "../../../stores/AccountStore";

import {
  createCallRatingWord,
  destroyCallRatingWord,
  getCallRatingWords,
} from "../../../services/callRatingWords";

import {IModel, IPayload} from "../../../types/storeTypes";
import {capitalize} from "lodash";

interface IRateCallRules {
  settings?: ISetting;
}

const goodCallWords = [
  {value: "product_name_mentioned", label: "Product name mentioned"},
  {value: "good", label: "Good"},
  {value: "positive", label: "Positive"},
  {value: "satisfactory", label: "Satisfactory"},
  {value: "performance", label: "Performance"},
  {value: "impressed", label: "Impressed"},
  {value: "appointment", label: "Appointment"},
  {value: "direction", label: "Direction"},
  {value: "location", label: "Location"},
  {value: "address", label: "Address"},
];

const badCallWords = [
  {value: "issue", label: "Issue"},
  {value: "problem", label: "Problem"},
  {value: "report", label: "Report"},
  {value: "unhappy", label: "Unhappy"},
  {value: "negative", label: "Negative"},
  {value: "unsatisfactory", label: "Unsatisfactory"},
];

const RateCallRules = ({settings}: IRateCallRules) => {
  const [highPotentialWords, setHighPotentialWords] = useState([]);
  const [badRatingWords, setBadRatingWords] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  const onSelectGoodOption = async (newValues: any, actionMeta: any) => {
    saveRatingWords(
      actionMeta,
      "high_potential",
      newValues,
      highPotentialWords
    );
  };

  const onSelectBadOption = (newValues: any, actionMeta: any) => {
    saveRatingWords(actionMeta, "bad", newValues, badRatingWords);
  };

  const saveRatingWords = async (
    actionMeta: any,
    ratingType: string,
    newValues: any,
    words: Array<any>
  ) => {
    switch (actionMeta.action) {
      case "create-option":
      case "select-option":
        const label = capitalize(actionMeta.option.label);
        await createCallRatingWord({
          data: {
            type: "call_rating_words",
            attributes: {
              content: label,
              rating_type: ratingType,
            },
          },
        } as IPayload);
        await fetchCallRatingWords();
        break;
      case "remove-value":
        const value = actionMeta.removedValue.value;
        const word = words.find((x) => x.value === value);
        if (word) {
          await destroyCallRatingWord(word.id);
          await fetchCallRatingWords();
        }
        break;
    }
  };

  useEffect(() => {
    fetchCallRatingWords();
  }, []);

  const fetchCallRatingWords = async () => {
    let resp = await getCallRatingWords({
      "filter[rating_type]": "high_potential",
    });

    setHighPotentialWords(
      resp.data.data.map((word: IModel) => {
        return {
          value: word.attributes.content.replace(/\s+/g, "_").toLowerCase(),
          label: capitalize(word.attributes.content),
          id: word.id,
        };
      })
    );
    resp = await getCallRatingWords({
      "filter[rating_type]": "bad",
    });
    setBadRatingWords(
      resp.data.data.map((word: IModel) => {
        return {
          id: word.id,
          value: word.attributes.content.replace(/\s+/g, "_").toLowerCase(),
          label: capitalize(word.attributes.content),
        };
      })
    );
    setIsLoaded(true);
  };

  const styles: StylesConfig = {
    control: (styles) => ({...styles, minWidth: 250, textAlign: "left"}),
    option: (styles) => ({...styles, minWidth: 250, textAlign: "left"}),
  };

  return (
    <Card
      title="call rating rules"
      titleAlign="left"
      helpText="Feature still in development."
    >
      <FormContainer>
        <Row>
          <label>
            Look for these words to rate call as high potential call
          </label>
        </Row>
        <Row>
          {isLoaded && (
            <CreatableSelect
              defaultValue={highPotentialWords}
              onChange={onSelectGoodOption}
              isMulti={true}
              options={goodCallWords}
              styles={styles}
            />
          )}
        </Row>
        <Row></Row>
        <Row></Row>
        <Row>
          <label>
            Look for these words to be able to rate a call as a bad call
          </label>
        </Row>
        <Row>
          {isLoaded && (
            <CreatableSelect
              defaultValue={badRatingWords}
              onChange={onSelectBadOption}
              isMulti={true}
              options={badCallWords}
              styles={styles}
            />
          )}
        </Row>
        <Row></Row>
        <Row></Row>
      </FormContainer>
    </Card>
  );
};

export default RateCallRules;
