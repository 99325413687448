import moment from 'moment';


export interface IDatePeriod {
    key: 'last_3_days' | 'last_7_days' | 'last_30_days' | 'last_month',
    label: string,
    from: () => moment.Moment,
    to: () => moment.Moment
}

export const datePeriods = [
  {
    key: 'today',
    label: 'Today',
    from: () => {
      return moment()
    },
    to: () => {
      return moment()
    }
  },
  // {
  //   key: 'yesterday',
  //   label: 'Yesterday',
  //   from: () => {
  //     return moment().subtract(1, 'day').startOf('day')
  //   },
  //   to: () => {
  //     return moment()
  //   }
  // },
  // {
  //   key: 'last_3_days',
  //   label: 'Last 3 days',
  //   from: () => {
  //     return moment().subtract(2, 'day').startOf('day');
  //   },
  //   to: () => {
  //     return moment()
  //   }
  // },
  {
    key: 'last_7_days',
    label: 'Last 7 Days',
    from: () => {
      return moment().subtract(6, 'day').startOf('day');
    },
    to: () => {
      return moment()
    }
  },
  {
    key: 'last_30_days',
    label: 'Last 30 Days',
    from: () => {
      return moment().subtract(29, 'day').startOf('day');
    },
    to: () => {
      return moment();
    }
  },
  {
    key: 'custom',
    label: 'Custom',
  }
  // {
  //   key: 'last_month',
  //   label: 'Last Month',
  //   from: () => {
  //     return moment().subtract(1, 'months').startOf('month').startOf('day')
  //   },
  //   to: () => {
  //     return moment().subtract(1, 'months').endOf('month').endOf('day');
  //   }
  // }
] as Array<IDatePeriod>
