import { useState } from 'react'

import Card from '../../../components/Card/Card'
import { FormContainer, StyledTextField, StyledMenuItem, Row } from '../StyledComponents'
import { ISetting } from "../../../stores/AccountStore";

import capitalize from 'lodash/capitalize'

export type ISettingData = {
    recordingEnabled: boolean
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

interface IOutboundCallRecording {
    outboundRecordingSetting: ISetting
}

const OutboundCallRecording = ({ outboundRecordingSetting }: IOutboundCallRecording) => {
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
    const [message, setMessage] = useState('')

    const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setMessage(message)
        setSnackbarProps({ severity, open: true })
    }

    const saveOutboundRecording = async (recordingEnabled: string) => {
        try {
            await outboundRecordingSetting.updateValue(recordingEnabled === 'true')
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    return (
        <Card title='OUTBOUND CALL RECORDING' titleAlign='left' helpText='Feature still in development.'>
            <FormContainer>
                <Row>
                    <label>Global Outbound Call Recording</label>
                    <StyledTextField
                        sx={{ width: '40%' }}
                        select
                        disabled={true}
                        onChange={(e) => saveOutboundRecording(e.target.value)}
                        defaultValue={outboundRecordingSetting.value.toString()}
                        variant='outlined' size='small'
                    >
                        <StyledMenuItem key='yes' value='true'>
                            Enabled
                        </StyledMenuItem>
                        <StyledMenuItem key='no' value='false'>
                            Disabled
                        </StyledMenuItem>
                    </StyledTextField>
                </Row>
            </FormContainer>
        </Card>
    )
}

export default OutboundCallRecording
