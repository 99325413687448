import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";

import Button from "../../components/Button/Button";

export const Container = styled.div`
  align-items: center;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  width: 65%;
  min-width: 909px;
  min-height: calc(100vh - 120px);

  @media (max-width: 1280px) {
    width: 80%;
    min-width: auto;
  }
  @media (max-width: 1024px) {
    margin: 5em 0 0 100px;
  }
`;

export const Root = styled.div`
  height: 100vh;
  margin: 0 auto;

  & > form {
    max-width: 500px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 10px 0;
  width: 80%;

  font-size: 0.8rem;
  letter-spacing: 0.28px;

  a {
    font-size: 0.8rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;

  font-size: 0.8rem;
  letter-spacing: 0.28px;

  a {
    font-size: 0.8rem;
  }
`;

export const Copyright = styled.div`
  /* margin: 0 auto; */
`;

export const FooterLinks = styled.div`
  & a {
    margin-left: 20px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  & > button {
    margin-top: 20px;
  }
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 10px;
`;

export const ButtonRow = styled.div`
  margin-top: 20px;
  text-align: center;

  & > span > svg {
    color: #ef681e;
  }
`;

export const LoginButton = styled(Button)`
  padding: 10px 60px;
`;

export const StyledLink = styled(Link)`
  color: #4086ff;
  text-decoration-color: #4086ff;
  letter-spacing: 0.24px;
  font-size: 14px;
  font-weight: normal;
`;

export const StyledPaper = styled(Paper)`
  border-radius: 10px;
  border: 1px solid #707070;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div > svg {
    cursor: pointer;
    opacity: 0.3;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ContentWrapper = styled.div`
  padding: 20px;
`;

export const Title = styled.span`
  font-weight: normal;
  color: #6b6a6e;
  letter-spacing: 0.72px;
  font-size: 14px;
  text-transform: uppercase;
`;

export const Logo = styled.div`
  cursor: pointer;
  & img {
    width: 103px;
  }
`;
