import styled from '@emotion/styled'

export const Container = styled.div`
    background-color: #F0F2F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 70px;

    & > .title-wrapper, .table-wrapper {
        width: 100%;
        margin-bottom: 20px;
    }
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & label {
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #6B6A6E;
    }
`

export const ButtonRow = styled.div`
    margin-top: 50px;
    text-align: center;

    & > span > svg {
        color: #EF681E;
    }
`
