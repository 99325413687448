import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close';

import Snackbar from '../../components/CustomSnackbar/CustomSnackbar'
import { Container, Title, CloseFormButton } from './StyledComponents'
import { SnackbarCloseReason } from '@mui/material'

import UserForm from '../../components/UserForm/UserForm'
import UserPerformance from '../../components/UserPerformance/UserPerformance'

import { UserStore } from "../../stores/UserStore";
import { RoleStore } from "../../stores/RoleStore";
import { observer } from "mobx-react";
import { useAuth } from '../../hooks/authHook'
import { useAppContext } from '../../context/AppContext'

interface CustomSnackbarProps {
  open: boolean
  severity: 'success' | 'error' | 'warning' | 'info'
}

export interface IFilter {
   [key: string]: string | number | undefined;
}

const Profile = () => {
  const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
  const [message, setMessage] = useState('')
  const [userStore] = useState<UserStore>(new UserStore())
  const [roleStore] = useState<RoleStore>(new RoleStore())
  const { userId } = useParams()
  const { userInfo } = useAuth()
  const location = useLocation()
  // const { /*currentUser,*/ } = useAppContext()
  const navigate = useNavigate()

  const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    setSnackbarProps((state) => ({ ...state, open: false }))
  }

  const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
    setMessage(message)
    setSnackbarProps({ severity, open: true })
  }

  useEffect(() => {
    const roles = roleStore.getRolesAsync({} as IFilter)
    const fetchId = userId ? parseInt(userId) : userInfo.id
    userStore.getUserAsync(fetchId, 'account,roles,did_allocations.did,group,voicemail_greeting_attachment')
  }, [location])

  const gotoUsers = () => {
    navigate('/users')
  }

  return (
    <>
      <Container >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          sx={{
            backgroundColor: '#fff',
            boxShadow: '0px 3px 22px #00000012',
            borderRadius: '10px',
            padding: '20px 40px'
          }}
        >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', textAlign: 'left', marginBottom: '20px' }}>
            <section>
              <Title>User Profile</Title>
            </section>
            <CloseFormButton onClick={gotoUsers}><CloseIcon /></CloseFormButton>
          </Grid>
          <Grid item xs={12}>
            {!userStore.isInitial && roleStore.isLoaded && <UserForm user={userStore.user} roles={roleStore.roles} displaySnackbar={displaySnackbar} />}
          </Grid>
        </Grid>

        {!userStore.isInitial && <UserPerformance user={userStore.user} />}
      </Container>

      <Snackbar open={snackbarProps.open} message={message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />
    </>
  )
}

// export default observer(withRouter(Profile))
export default observer(Profile)
