import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Snackbar, { CustomSnackbarProps } from '../../../components/CustomSnackbar/CustomSnackbar'
import { useAppContext } from '../../../context/AppContext'
import { logout} from '../../../hooks/authHook'
import Modal from '../../../components/Modal/Modal'

import AutopylotNumSetupForm from './AutoPylotNumSetupForm'
import SetupMessage from './SetupMessage'

export interface IUser {
    firstName: string
    lastName: string
    email: string
    userName?: string
    status?: string
    outboundCallPhoneNumber: string
    autopylotNumber?: string
    groups?: string
    role?: string
}

export interface IValidError {
    code: string
    detail: string
    status: string
    title: string
}

const AutopylotNumSetup = () => {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [updateSuccess, setUpdateSuccess] = useState(false)
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, message: '', severity: undefined })
    const { currentUser, refreshCurrentUser } = useAppContext()

    useEffect(() => {
        setShowModal(!!currentUser?.id)
    }, [currentUser?.id])

    const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
        setSnackbarProps({ open: false, message: '', severity: undefined })
    }

    const closeSuccessModal = async () => {
        setShowModal(false)

        if (currentUser?.store.user.isSalesPerson) {
            logout()
            navigate('/')
        } else {
            await refreshCurrentUser()
            if (currentUser && currentUser.isAdmin) {
                navigate('/users')
            } else {
                navigate('/dashboard')
            }
        }
    }

    const modalTitle = updateSuccess ? 'AutoPylot Number Setup' : 'User Information'

    return (
        <>
            <Modal
                open={showModal}
                title={modalTitle}
                closeIcon={updateSuccess}
                onClose={closeSuccessModal}
                maxWidth='xs'
                fullWidth
            >
                {/* {updateSuccess ? <SetupMessage/> : userStore.isLoaded && <AutopylotNumSetupForm user={userStore.user} setUpdateSuccess={setUpdateSuccess} setSnackbarProps={setSnackbarProps} />} TODO: use it when mobile apps will be ready */}
                {updateSuccess ? <SetupMessage/> : !!currentUser?.id && <AutopylotNumSetupForm user={currentUser} setUpdateSuccess={closeSuccessModal} setSnackbarProps={setSnackbarProps} />}
            </Modal>
            <Snackbar open={snackbarProps.open} message={snackbarProps.message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />
        </>
    )
}


export default AutopylotNumSetup