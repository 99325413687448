import API from '../API/config'
import { IPayload } from '../types/storeTypes'

interface QueryParams {
  filter: string;
  from: string;
  to: string;
}

export class DeviceService {
  get = async (urlParams: QueryParams) => {
    const resp: IPayload = await API.get('/devices', { params: urlParams})
    return resp.data;
  }

  update = async (id: number, payload: IPayload) => {
    const resp: IPayload = await API.put(`/devices/${id}`, payload);
    return resp.data;
  }

  destroy = async (id: number) => {
    const resp: IPayload = await API.delete(`/devices/${id}`)
    return resp.data;
  }
}
