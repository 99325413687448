import API from '../API/config'
import { DidAllocationAttributes } from '../stores/UserStore';

export const getDidAllocation = async (id: number) => {
    return API.get(`/did_allocations/${id}?include=did`)
}


export const updateDidAllocation = async (id: number, attributes: DidAllocationAttributes) => {
    const payload = {
        data: {
            id,
            type: 'did_allocations',
            attributes: {
                status: attributes.status
            }
        }
    }

    return API.put(`/did_allocations/${id}`, payload, {headers: {'Content-Type': 'application/vnd.api+json'}})
}
