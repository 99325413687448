import API from '../API/config'
import { IPayload } from '../types/storeTypes'

export const getGroups = () => {
    return API.get('/groups')
}

export class GroupService {
  get = async () => {
    const options = {
      method: "GET",
    }
    const resp: IPayload = await API.get('/groups')
    return resp.data;
  }
}
