import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    typography: {
        fontFamily: 'roboto',
        subtitle1: {
            fontSize: '12px',
            letterSpacing: '0.72px',
            color: '#6B6A6E',
            textTransform: 'uppercase',
            fontWeight: 500
        },
        body1: {
            fontSize: '14px'
        }
    },
    palette: {
        primary: {
            main: '#EF681E'
        },
        secondary: {
            main: '#BBEF1E'
        },
        text: {
            primary: '#6B6A6E'
        }
    },
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    margin: 0,
                    padding: '5px 0 0'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F0F2F5'
                }
            }
        }
    }
})

export default theme