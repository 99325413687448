import { Container, TitleWrapper, Title, Subtitle } from './StyledComponents'
import Button from '../../components/Button/Button'

type SCType = {
    title: string
    subtitle?: string
    width?: string
    buttonLabel?: string
    onClickButton?: () => void
    filter?: React.ReactNode
}

const ScreenHeader = ({ title, subtitle, width, buttonLabel, onClickButton, filter }: SCType) => {
    return (
        <Container {...{ width }}>
            <TitleWrapper>
                <div style={{ alignSelf: 'center' }}>
                    <Title>{title}</Title>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </div>
                {filter}
            </TitleWrapper>
            {!!buttonLabel &&
                <Button
                    variant='contained'
                    onClick={onClickButton}
                >{buttonLabel}</Button>
            }
        </Container>
    )
}

export default ScreenHeader
