import API from '../API/config'
import { IUser } from '../views/Users/Users'
import jwt_decode from 'jwt-decode'
import { getFromLocal } from '../utils/LocalStorageService'
import { IPayload } from '../types/storeTypes'

export interface QueryParams {
  [key: string]: string | number | undefined;
}

export const getUsers = (urlParams: QueryParams) => {
    if (!urlParams['include']) urlParams['include'] = 'account,roles,group'
    return API.get('/users', {params: urlParams})
}

export interface User {
    id: number;
    email: string;
    expire: number;
}

export const addUser = ({firstName, lastName, email, mobileNumber, autopylotNumber, role}: IUser) => {
    const didAllocations = []
    if (autopylotNumber) {
        didAllocations.push({
            type: 'did_allocations',
            attributes: {
                did_number: autopylotNumber
            }
        })
    }
    const payload = {
        data: {
            type: 'users',
            attributes: {
                first_name: firstName,
                last_name: lastName,
                email,
                outbound_call_phone_number: mobileNumber
            },
            relationships: {
                did_allocations: {
                    data: didAllocations,
                },
                roles: {
                    data: [
                        {
                        id: role ? parseInt(role, 10) : role,
                        type: "roles"
                        }
                    ]
                }
            }
        }
    }

    return API.post('/users', payload)
}

export const updateUser = (id: number, payload: IPayload) => {
    return API.put(`/users/${id}?include=account,roles,did_allocations.did,group`, payload)
}

export const deleteUser = (id: string) => {
    return API.delete(`/users/${id}`)
}

export const deleteAttachment = (id: number) => {
    return API.delete(`/attachments/${id}`)
}

export const getUser = (id: number, include?: string) => {
    if (!include) {
        include = 'account,roles,did_allocations.did,group'
    }
    return API.get(`/users/${id}?include=${include}`)
}

export const getCurrentUser = () => {
    const token = getFromLocal('token')
    const user: User = !!token ? jwt_decode(token) : {id: 0, email: '', expire: 0};

    return user
}

export class UserService {
  get = async (urlParams: QueryParams) => {
    const options = {
      method: "GET",
    }
    const resp: IPayload = await API.get('/users', { params: urlParams} )
    return resp.data;
  }
}
