import { useState, useEffect, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'

import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Card from '../../../components/Card/Card'
import { PhoneNumberField } from '../../../components/PhoneNumberInput/PhoneNumberInput'

import Button from '../../../components/Button/Button'

import {
    ContactWrapper,
    ContactIconWrapper,
    ContactInfoWrapper,
    PencilIconWraper,
    PencilIcon,
    RelativeForm,
    Row,
    StyledTextField,
    FormFields,
    StyledFormControl,
    StyledMenuItem,
    StyledAutocomplete,
    StyledSelect,
    StyledLink
} from './styledComponents'

import { AccountAttributes } from "../../../stores/AccountStore";
import { countries, usaStates, canadianProvinces } from '../../../types/countriesAndStates'
import { ItemOption } from '../../../types/types'

import capitalize from 'lodash/capitalize'
import { capitalizeInput } from '../../../utils/InputUtils'

export interface IContactForm {
    contactFirstName: string
    contactLastName: string
    billingEmail: string
    billingPhoneNumber: string
    billingStreet: string
    billingStreet2: string
    billingCity: string
    billingState: string
    billingZip: string
    billingCountry: string
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

interface BillingContact {
    accountStore: any
    isLoading: boolean
}

const BillingContact = ({accountStore, isLoading}: BillingContact) => {
    const [showBillingAddressFields, setShowBillingAddressFields] = useState(false)
    const [_snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
    const [showContactInfo, setShowContactInfo] = useState(false)
    const [_message, setMessage] = useState('')
    const defaultValues = {
        contactFirstName: accountStore.account.contactFirstName,
        contactLastName: accountStore.account.contactLastName,
        billingEmail: accountStore.account.billingEmail,
        billingPhoneNumber: accountStore.account.billingPhoneNumberFormatted,
        billingStreet: accountStore.account.billingAddress?.street||accountStore.account.addressMainStreet,
        billingStreet2: accountStore.account.billingAddress?.street2||accountStore.account.addressSecondaryStreet,
        billingCity: accountStore.account.billingAddress?.city||accountStore.account.addressCity,
        billingState: accountStore.account.billingAddress?.state||accountStore.account.addressState,
        billingZip: accountStore.account.billingAddress?.zip||accountStore.account.addressZip,
        billingCountry: accountStore.account.billingAddress?.country||accountStore.account.addressCountry
    }

    const [country, setCountry] = useState(defaultValues.billingCountry)
    const stateLabel = useMemo(() => country === 'ca' ? 'Province' : 'State', [country])
    const zipCodeLabel = useMemo(() => country === 'ca' ? 'Postal Code' : 'Zip Code', [country])
    const states = useMemo(() => country === 'ca' ? canadianProvinces : usaStates, [country])
    const [selectedBillingState, setSelectedBillingState] = useState<ItemOption | null>(null)
    const { register, handleSubmit, reset, getValues, setValue, formState: { errors, isDirty, isValid }, control } = useForm<IContactForm>({
        mode: 'onChange',
        defaultValues: defaultValues
    })

    const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setMessage(message)
        setSnackbarProps({ severity, open: true })
    }

    const onPencilClick = () => {
        setShowContactInfo(showContactInfo => !showContactInfo)
    }

    useEffect(() => {
        if (showContactInfo) {
            reset(defaultValues);
            setCountry(defaultValues.billingCountry)
        } else {
            setShowBillingAddressFields(false)
        }
    }, [showContactInfo]);

    useEffect(() => {
        setSelectedBillingState(states.find((item: ItemOption) => item.label === defaultValues.billingState) || null)
    }, [defaultValues.billingState, states]);

    const onSubmitOrganizationDeatils = async (values: IContactForm) => {
        try {
            const attributes = {
                id: accountStore.account.id,
                contact_first_name: values.contactFirstName,
                contact_last_name: values.contactLastName,
                billing_phone: values.billingPhoneNumber?.replace(/[()-\s]/g, '')?.replace(/_/g, ''),
                billing_email: values.billingEmail
            } as AccountAttributes
            const accountAddresses = []
            accountAddresses.push({
                id: accountStore.account.billingAddress?.id,
                type: 'account_addresses',
                attributes: {
                    category: 'billing',
                    street: values.billingStreet,
                    street2: values.billingStreet2,
                    city: values.billingCity,
                    state: values.billingState,
                    zip: values.billingZip,
                    country: values.billingCountry
                }
            })
            const relationships = {
                account_addresses: {
                    data: accountAddresses
                }
            }
            await accountStore.account.update(attributes, relationships, 'settings,subscription.plan,subscription.promo_code,account_addresses')
            setShowContactInfo(false)
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    return (
        <Card title='Billing Contacts and Address' titleAlign='left'>
           {isLoading ? <CircularProgress /> : <RelativeForm onSubmit={handleSubmit(onSubmitOrganizationDeatils)}>
                <PencilIconWraper onClick={(e) => onPencilClick()}>
                    <PencilIcon />
                </PencilIconWraper>
                <ContactWrapper>
                    <ContactIconWrapper>
                        <AccountCircleIcon/>
                    </ContactIconWrapper>
                    {showContactInfo ? <ContactInfoWrapper>
                        <FormFields>
                            <div>
                                <Row>
                                    <label>First Name</label>
                                    <StyledTextField
                                        {...register('contactFirstName', { required: 'Required Field' })}
                                        defaultValue={accountStore.account.contactFirstName}
                                        variant='outlined' size='small'
                                        error={!!errors?.contactFirstName}
                                        helperText={errors?.contactFirstName?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>Last Name</label>
                                    <StyledTextField
                                        {...register('contactLastName', { required: 'Required Field' })}
                                        defaultValue={accountStore.account.contactLastName}
                                        variant='outlined' size='small'
                                        error={!!errors?.contactLastName}
                                        helperText={errors?.contactLastName?.message}
                                    />
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <label>Billing E-mail</label>
                                    <StyledTextField
                                        {...register('billingEmail', { required: 'Required Field' })}
                                        defaultValue={accountStore.account.billingEmail}
                                        variant='outlined' size='small'
                                        error={!!errors?.billingEmail}
                                        helperText={errors?.billingEmail?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>Billing Phone</label>
                                    <Controller
                                        name="billingPhoneNumber"
                                        control={control}
                                        render={({ field }) => (
                                            <PhoneNumberField
                                                {...register('billingPhoneNumber', { validate: (value) => {
                                                    if (value) {
                                                        return isValidPhoneNumber(`+1${value}`)||'Invalid Phone Number'
                                                    } else {
                                                        return !!getValues('billingEmail')||'Required Field'
                                                    }
                                                }  })}
                                                name={field.name}
                                                size="small"
                                                sx={{minWidth: '60%', ['.MuiOutlinedInput-root input']: {backgroundColor: '#fff'}, ['.MuiOutlinedInput-root fieldset']: {borderWidth: '1px'}, ['.MuiOutlinedInput-root.Mui-focused fieldset']: {borderWidth: '1px', borderColor: '#54ABD9'} }}
                                                error={!!errors?.billingPhoneNumber}
                                                helperText={errors?.billingPhoneNumber?.message}
                                            />
                                        )}
                                    />
                                </Row>
                            </div>
                        </FormFields>
                        <Row align={'left'}>
                            <label>&nbsp;</label>
                            <StyledLink onClick={() => setShowBillingAddressFields(showAddress => !showAddress)}>{showBillingAddressFields ? 'Hide address' : 'Billing Address' }</StyledLink>
                        </Row>
                        {showBillingAddressFields && <FormFields>
                            <div>
                                <Row>
                                    <label>Address 1</label>
                                    <StyledTextField
                                        {...register('billingStreet', { required: 'Required' })}
                                        onKeyUp={(e) => { capitalizeInput(e, 'billingStreet', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.billingStreet}
                                        helperText={errors?.billingStreet?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>Address 2</label>
                                    <StyledTextField
                                        {...register('billingStreet2')}
                                        onKeyUp={(e) => { capitalizeInput(e, 'billingStreet2', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.billingStreet2}
                                        helperText={errors?.billingStreet2?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>City</label>
                                    <StyledTextField
                                        {...register('billingCity', { required: 'Required' })}
                                        onKeyUp={(e) => { capitalizeInput(e, 'billingCity', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.billingCity}
                                        helperText={errors?.billingCity?.message}
                                    />
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <label>Country</label>
                                    <StyledFormControl>
                                        <Controller
                                            name="billingCountry"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <StyledSelect
                                                    {...register('billingCountry', {required: 'Required'})}
                                                    displayEmpty
                                                    defaultValue={defaultValues.billingCountry}
                                                    onChange={(e) => {
                                                        setCountry(e.target.value as string);
                                                        onChange(e);
                                                    }}
                                                    variant='outlined' size='small'
                                                    error={!!errors?.billingCountry}
                                                    renderValue={(value: any) => {
                                                        if (!value || value.length === 0) {
                                                            return <em>Select Country</em>;
                                                        }

                                                        const selected = countries.find((item: ItemOption) => {
                                                            return item.key === value;
                                                        })

                                                        return selected?.label ?? 'Select Country';
                                                    }}
                                                >
                                                {countries.map((option: ItemOption) => (
                                                    <StyledMenuItem key={option.key} value={option.key}>
                                                        {option.label}
                                                    </StyledMenuItem>
                                                ))}
                                                </StyledSelect>
                                            )}
                                        />
                                    {!!errors?.billingCountry && <FormHelperText error>{!!errors?.billingCountry?.message ? errors?.billingCountry?.message : 'Required Field'}</FormHelperText>}
                                    </StyledFormControl>
                                </Row>
                                <Row>
                                    <label>{stateLabel}</label>
                                    <Controller
                                        name="billingState"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <StyledAutocomplete
                                                options={states}
                                                autoHighlight
                                                getOptionLabel={(option: ItemOption | any) => option.label }
                                                noOptionsText='Select Country'
                                                onChange={(event: React.SyntheticEvent<Element, Event>) => onChange(event)}
                                                defaultValue={selectedBillingState}
                                                renderInput={(params) => (
                                                    <StyledTextField
                                                        {...params}
                                                        {...register('billingState', { required: 'Required' })}
                                                        name="billingState"
                                                        autoComplete="off"
                                                        variant='outlined' size='small'
                                                        error={!!errors?.billingState}
                                                        helperText={errors?.billingState?.message}
                                                    />
                                                )}
                                            />

                                        )}
                                    />
                                </Row>
                                <Row>
                                    <label>{zipCodeLabel}</label>
                                    <StyledTextField
                                        {...register('billingZip', { required: 'Required' })}
                                        variant='outlined' size='small'
                                        error={!!errors?.billingZip}
                                        helperText={errors?.billingZip?.message}
                                    />
                                </Row>
                            </div>
                        </FormFields>}
                        <Row>
                            <Button type='submit' disabled={!isValid || !isDirty} >Save</Button>
                        </Row>
                    </ContactInfoWrapper> : <ContactInfoWrapper>
                        <Typography align='left'>{accountStore.account.contactFirstName} {accountStore.account.contactLastName}</Typography>
                        <Typography align='left'>{accountStore.account.billingEmail}</Typography>
                    </ContactInfoWrapper>
                    }
                </ContactWrapper>
           </RelativeForm>}
        </Card>
    )
}

export default BillingContact
