import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../context/AppContext'
import { useStores } from '../../stores/rootStore'
import { useState, useEffect } from 'react'
import { observer } from "mobx-react";

import BusinessInformationComponent from './BusinessInformation/BusinessInformation'
import ServicePlanDetailsComponent from './PlanDetails/PlanDetails'
import PlanLimitsComponent from './PlanLimits/PlanLimits'
import { Container, Content } from './StyledComponents'

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

const BusinessDetails = () => {
    let navigate = useNavigate()
    let stores = useStores()

    const { currentUser, loading, setLoading } = useAppContext()
    const [errorMsg, setErrorMsg] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })

    const getAccount = async () => {
      try {
        setLoading(true)

        const accountId = currentUser?.account?.id;
        if (accountId) {
          await stores.accountStore.getAccountAsync(accountId, 'settings,subscription.plan,subscription.promo_code')
        }

        setLoading(false)
      } catch (error) {
        setLoading(false)
        setErrorMsg('Something went wrong')
        setSnackbarProps((state) => ({ ...state, severity: 'error', open: true }))
      }
    }

    useEffect(() => {
      if (currentUser) {
        if (!currentUser.isAdmin) {
          navigate('/dashboard', { replace: true })
        }
        getAccount()
      }
    }, [currentUser])

    return (
        <Container>
            <Content>
                <div className='left-section'>
                  <BusinessInformationComponent store={stores.accountStore} isLoading={!stores.accountStore.isLoaded}/>
                </div>
                <div className='right-section'>
                    <ServicePlanDetailsComponent account={stores.accountStore.account} isLoading={!stores.accountStore.isLoaded}/>
                    <PlanLimitsComponent account={stores.accountStore.account} isLoading={!stores.accountStore.isLoaded}/>
                </div>
            </Content>
        </Container>
    )
}

export default observer(BusinessDetails)
