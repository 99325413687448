import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import Modal from '../../../components/Modal/Modal'
import Card from '../../../components/Card/Card'
import Button from '../../../components/Button/Button'
import { StyledTextField, Row, StyledLink, AgreementContent, StyledTypography, AgreementDialogTitle } from './styledComponents'
import { getContractAgreements } from '../../../services/accounts'
import { getUser } from '../../../services/users'
import { AccountRelationships } from "../../../stores/AccountStore";
import { User } from "../../../stores/UserStore";

import { currencyFormat } from "../../../utils/NumberFormatService"
import get from 'lodash/get'
import moment from 'moment'

interface IPlanDetails {
    account: any
    isLoading: boolean
}

const PlanDetails = ({account, isLoading}: IPlanDetails) => {
    const [agreements, setAgreements] = useState([])
    const [user, setUser] = useState({} as User)
    const [showAgreementsModal, setShowAgreementsModal] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)

    const getLimitsAndUsage = async () => {
        const { data } = await getContractAgreements()
        setAgreements(data)
        const response = await getUser(data[0].user_id)
        const user = new User(null, response.data)
        setUser(user)
    }

    const cancelSubscription = async () => {
        const subscription = {
            data: {
                id: account.subscription.id,
                type: 'subscriptions',
                attributes: {
                    kind: 'cancelled'
                }
            }
        }
        try {
            await account.update({}, { subscription: subscription } as AccountRelationships, 'settings,subscription.plan,subscription.promo_code')
        } catch (error: any) {

        }
    }

    useEffect(() => {
        getLimitsAndUsage()
    }, [])

    return (
        <>
            <Dialog
                maxWidth='md'
                fullWidth
                onClose={() => setShowAgreementsModal(showAgreementsModal => !showAgreementsModal)}
                aria-labelledby="customized-dialog-title"
                open={showAgreementsModal}
            >
                <AgreementDialogTitle>
                    <span>Endorsed on {moment(get(agreements[0], 'created_at')).format('MM/DD/YYYY HH:mm')}.</span>
                    <span>Agreed and Accepted by User {user.email} ({user.fullName})</span>
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowAgreementsModal(showAgreementsModal => !showAgreementsModal)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                </AgreementDialogTitle>
                <DialogContent>
                    <AgreementContent>
                        <div dangerouslySetInnerHTML={{ __html: get(agreements[0], 'contract_version.content') }} />
                    </AgreementContent>
                </DialogContent>
            </Dialog>

            {!isLoading && account.subscription.plan && <Modal
                open={showCancelModal}
                title="Confirm Subscription Cancellation"
                maxWidth='sm'
                fullWidth
                onClose={() => setShowCancelModal(showCancelModal => !showCancelModal)}
            >
                <StyledTypography>Are you sure that you want to cancel your subscription for {account.subscription.plan.name} service plan?</StyledTypography>
                <StyledTypography>Your Subscription for {account.subscription.plan.name} service plan expires on {account.subscription.expiresAt.format('MM/DD/YYYY')}. If you cancel your subscription now, your account will be deactivated on {account.subscription.expiresAt.format('MM/DD/YYYY')}, all phone numbers in your account will no longer be in service and you will no longer have any access to your account.</StyledTypography>
                <Button sx={{marginTop: '20px'}} type='button' onClick={cancelSubscription}>Cancel Subscription</Button>
            </Modal>}

            <Card title='Service Plan Details' titleAlign='left'>
                {(isLoading || !account.subscription.plan) ? <CircularProgress /> : <Grid container columnSpacing={5} >
                    <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                        <Row>
                            <label>Service Plan</label>
                            <StyledTextField
                                value={account.subscription.plan.name}
                                variant='outlined' size='small'
                                disabled
                            />
                        </Row>
                        <Row>
                            <label>Subscription Fee</label>
                            <StyledTextField
                                value={`${currencyFormat(account.subscriptionFee)}`}
                                variant='outlined' size='small'
                                disabled
                            />
                        </Row>
                        <Row>
                            <label>Effective Date</label>
                            <StyledTextField
                                value={account.subscription.createdAt.format('MM/DD/YYYY')}
                                variant='outlined' size='small'
                                disabled
                            />
                        </Row>
                        <Row>
                            <label>Billing Basis</label>
                            <StyledTextField
                                value="Monthly"
                                variant='outlined' size='small'
                                disabled
                            />
                        </Row>
                        <Row>
                            <label>&nbsp;</label>
                            <StyledLink onClick={() => setShowAgreementsModal(showAgreementsModal => !showAgreementsModal)}>View Agreement</StyledLink>
                        </Row>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                        <Row>
                            <label className="subscription">
                                Subscription
                                {!account.subscription.isCancelled && <StyledLink onClick={() => setShowCancelModal(showCancelModal => !showCancelModal) }>Cancel</StyledLink>}
                            </label>
                            <StyledTextField
                                value={account.subscription.state}
                                variant='outlined' size='small'
                                disabled
                            />
                        </Row>
                        <Row>
                            <label>Account Number</label>
                            <StyledTextField
                                value={account.idNumber}
                                variant='outlined' size='small'
                                disabled
                            />
                        </Row>
                        <Row>
                            <label>Service Plan Expiration Date</label>
                            <StyledTextField
                                value={account.subscription.expiresAt.format('MM/DD/YYYY')}
                                variant='outlined' size='small'
                                disabled
                            />
                        </Row>
                        { account.subscription.promoCode && <Row>
                            <label>Discount Code Applied</label>
                            <StyledTextField
                                value={account.subscription.promoCode?.id}
                                variant='outlined' size='small'
                                disabled
                            />
                        </Row>}
                    </Grid>
                </Grid>}
            </Card>
        </>
    )
}

export default PlanDetails
