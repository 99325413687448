import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { observer } from "mobx-react";
import { useForm, Controller } from 'react-hook-form'
import { User, DidAllocation } from "../../stores/UserStore";
import { updateDidAllocation } from '../../services/didAllocation'

import FormControl from '@mui/material/FormControl';
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'

import {
    ButtonRow,
    StyledTypography,
    StyledLink,
    Row
} from './StyledComponents'

import { UserSelector } from '../../components/UserSelector/UserSelector'

interface ITransferAPNumberProps {
    users: Array<User>
    showModal: boolean
    didAllocation: DidAllocation
    onClose: () => void
    afterTransfer: () => void
}
  
export type ITransferAPNumber = {
    userId: number | null
}

export const TransferAPNumberModal = observer(({ users, didAllocation, showModal, afterTransfer, onClose }: ITransferAPNumberProps) => {
    let navigate = useNavigate()
    const apNumberForTransfer = useMemo(() => didAllocation?.did?.phoneNumber, [didAllocation.id])
    const did = useMemo(() => didAllocation?.did, [didAllocation.id])

    const { watch, handleSubmit, control, setValue, formState: { errors, isValid } } = useForm<ITransferAPNumber>({
        mode: 'onChange',
        defaultValues: {
            userId: null
        }
    })

    const userId = watch('userId')

    const transferAPtoNewUser = (e: Event | React.SyntheticEvent<any, Event>) => {
        navigate('/users', { state: { userID: didAllocation.user.id, didAllocationID: didAllocation.id } })
    }

    const onSubmit = async (values: ITransferAPNumber) => {
        const selectedUser = users.find((u: User) => u.id === values.userId )

        if (didAllocation.id) await updateDidAllocation(didAllocation.id, {status: 'inactive'})
        if (selectedUser && did) await selectedUser.assignAutoPylotNumber(did.attributes.phone_number)
        afterTransfer()
    }

    const onSelectUser = (userId: number | null) => {
        setValue('userId', userId)
    }

    return (
        <Modal
            open={showModal}
            title="Transfer AutoPylot Number."
            maxWidth='sm'
            fullWidth
            onClose={onClose}
        >
            <StyledTypography
                variant="subtitle2"
            >
                Please select the User to whom you want to forward calls from {apNumberForTransfer} AutoPylot number.
            </StyledTypography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row style={{justifyContent: 'flex-start'}}>
                    <FormControl sx={{width: '50%'}}>
                        <Controller
                            name="userId"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <UserSelector users={users} onSelectUser={onSelectUser} errors={errors}/>
                            )}
                        />
                    </FormControl>
                    <StyledLink onClick={transferAPtoNewUser}>Transfer to new user</StyledLink>
                </Row>
                <ButtonRow>
                    <Button type='submit' disabled={!userId}>Save</Button>
                </ButtonRow>
            </form>
        </Modal>
    )
})