import styled from '@emotion/styled'
import Button, { ButtonProps } from '@mui/material/Button'

const StyledButton = styled(Button)`
    background-color: #EF681E;
    font-size: 12px;
    padding: 12px 37px;
    color: #fff;
    &.Mui-disabled {
        background-color: rgba(0, 0, 0, 0.12);
    }
    &:hover {
        background-color: #EF681F;
    }
`
const CustomButton = (props: ButtonProps) => {
    return (
        <StyledButton {...props}>{props.children}</StyledButton>
    )
}

export default CustomButton