import API from '../API/config'
import { getCurrentUser, getUser } from './users'
import { IBillingInformationForm } from '../views/BillingInformation/index'
import { Account, AccountRelationships, SettingAttributes } from '../stores/AccountStore'
import { IPayload } from '../types/storeTypes'

export interface QueryParams {
    [key: string]: string | number | undefined;
}

export const getAccount = (id: number, include?: string) => {
    if (!include) {
      include = 'subscription'
    }
    return API.get(`/accounts/${id}?include=${include}`)
}

export const getAccounts = () => {
    return API.get('/accounts?filter[autopylot]=true')
}

export const getCurrentAccount = async () => {
    const userData = getCurrentUser()
    const response = await getUser(userData.id)
    const accountResponse = await getAccount(response.data.data?.relationships?.account?.data?.id)

    return new Account(null, accountResponse.data)
}

export const getCreditCard = () => {
    return API.get('credit_card')
}

export const getUpcomingInvoice = () => {
    return API.get('upcoming_stripe_invoice')
}

export const getInvoices = (limit: number) => {
    return API.get(`stripe_invoices?page[size]=${limit}`)
}

export const getAccountSummary = () => {
    return API.get('v1/accounts/summary')
}

export const getContractAgreements = () => {
    return API.get('v1/contract_agreements?order[created_at]=desc')
}

export const createAccount = (payload: IPayload) => {
    return API.post('/accounts', payload)
}

export const updateAccount = async (id: number, payload: IPayload) => {
    return API.put(`/accounts/${id}`, payload, {headers: {'Content-Type': 'application/vnd.api+json'}})
}

export const updateSetting = async (id: number, attributes: SettingAttributes) => {
    const payload = {
        data: {
            id: id,
            type: 'settings',
            attributes: attributes
        }
    }
    return API.put(`/settings/${id}`, payload, {headers: {'Content-Type': 'application/vnd.api+json'}})
}

export const fetchSetting = async (id: number) => {
    const resp = await API.get(`/settings/${id}`);
    return resp.data as IPayload;
}

export class AccountService {
    get = async (params: QueryParams) => {
        const resp: IPayload = await API.get('/accounts?filter[autopylot]=true', { params: params})
        return resp.data;
    }
}
