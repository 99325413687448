import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle';

export const AgreementDialogTitle = styled(DialogTitle)`
    font-weight: 500;
    font-size: 0.9rem;
    span {
        display: block;
    }
`

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    letter-spacing: 0.28px;
    font-weight: normal;
    font-size: 12px;
`

export const StyledMenuItem = styled(MenuItem)`
    min-width: 60%;
    text-align: left;
    background-color: #F0F2F5;
`

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    & label {
        font-size: 0.8rem;
        letter-spacing: 0.28px;
        color: rgba(107, 106, 110, 55%);
        text-align: left;
        &.subscription {
            display: flex;
            justify-content: space-between;
            a {
                padding: 0;
            }
        }
    }
`

export const StyledAutocomplete = styled(Autocomplete)`
    min-width: 60%;
    & .MuiFormControl-root {
        width: 100%;
    }
`

export const StyledTextField = styled(TextField)`
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
    & div {
      color: #6B6A6E;
      font-size: 14px;
    }
    & input  {
      background: #F0F2F5 0% 0% no-repeat padding-box;
      border-radius: 4px;
      border-color: #F0F2F5;
    }
    & fieldset {
      border: none;
    }
`

export const StyledLink = styled(Link)`
    margin 40px 0;
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: 0.8rem;
    font-weight: normal;

    padding: 8.5px 14px;
    cursor: pointer;
`

export const AgreementContent = styled.div`
    font-size: 0.8rem;
    letter-spacing: 0.28px;
`