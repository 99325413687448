import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import capitalize from 'lodash/capitalize'
import { capitalizeInput } from '../../../utils/InputUtils'
import { isValidPhoneNumber } from 'react-phone-number-input'

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import HelpIcon from '@mui/icons-material/Help'

import { CustomSnackbarProps } from '../../../components/CustomSnackbar/CustomSnackbar'
import PopUp from '../../../components/PopUp/PopUp'
import Button from '../../../components/Button/Button'
import { PhoneNumberField } from '../../../components/PhoneNumberInput/PhoneNumberInput'
import AutoPylotAutocomplete from '../../../components/AutopylotAutoComplete/AutopylotAutoComplete'
import { useAppContext } from '../../../context/AppContext'
import { DidResponse, getTwilioNumbers } from '../../../services/common'
import { Row, ButtonRow, PopupTypography } from './StyledComponents'
import { debouncedFunction } from '../../../utils/Helpers'

import { IUser } from './AutopylotNumSetup'

export interface IValidError {
    code: string
    detail: string
    status: string
    title: string
}

interface IProps {
    setUpdateSuccess: (success: boolean) => void
    setSnackbarProps: React.Dispatch<React.SetStateAction<CustomSnackbarProps>>
    user?: any
}
const AutopylotNumSetupForm = ({ user, setUpdateSuccess, setSnackbarProps }: IProps) => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
    const [showAutocomplete, setShowAutocomplete] = useState(false)
    const [autocompleteOptions, setAutocompleteOptions] = useState<Array<DidResponse>>([])
    const [loadingNumbers, setLoadingNumbers] = useState(false)
    const { loading, setLoading } = useAppContext()

    const openPopUp = Boolean(anchorElement)

    const { handleSubmit, register, control, setValue, formState: { errors, isValid }, trigger } = useForm<IUser>({
        mode: 'onChange',
        defaultValues: {
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            email: user.email ?? '',
            outboundCallPhoneNumber: user.outboundCallPhoneNumber ?? '',
            autopylotNumber: user.didAllocation?.did?.phoneNumber ?? ''
        }
    })

    useEffect(()=>{
        trigger()
    }, [])

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget)
    };

    const handlePopoverClose = () => {
        setAnchorElement(null)
    }

    const onSubmitUser = async (values: IUser) => {
        try {
            const autopylotNumber = `+${values.autopylotNumber?.replace(/\D/g, '')}`
            const outboundCallPhoneNumber = values.outboundCallPhoneNumber.replace(/[()-\s]/g, '').replace(/_/g, '')
            setLoading(true)
            const payload = { ...values, autopylotNumber, outboundCallPhoneNumber }
            await user.update(payload)

            setLoading(false)
            setUpdateSuccess(true)
        } catch (error: any) {
            setLoading(false)
            if (error.response.status === 422) {
                error.response.data.errors.forEach((error: IValidError) => {
                    let parts = error.detail.split('-')

                    let field = parts[0];
                    let reason = parts[1];

                    setSnackbarProps(state => ({ ...state, message: `${capitalize(field.replace(/_/g, ' '))} ${reason}` }))
                });
            } else {
                setSnackbarProps(state => ({ ...state, message: `There was an error adding the new User` }))
            }
            setLoading(false)
            setSnackbarProps(state => ({ ...state, severity: 'error', open: true }))
        }
    }

    const getTwilioNumbersList = async (number: string) => {
        try {
            setShowAutocomplete(true);
            setLoadingNumbers(true)
            const response = await getTwilioNumbers(number)
            setAutocompleteOptions(response.data)
            setLoadingNumbers(false)
        } catch (error) {
            const message = 'Error retrieving Autopylot numbers'
            setSnackbarProps({ message, severity: 'error', open: true })
        }
    }

    const debouncedGetTwilioNumbers = debouncedFunction(getTwilioNumbersList)

    return (
        <form onSubmit={handleSubmit(onSubmitUser)}>
            <Row>
                <label>First Name</label>
                <TextField
                    {...register('firstName', { required: 'Required Field' })}
                    onKeyUp={(e) => { capitalizeInput(e, 'firstName', setValue) }}
                    variant='outlined' size='small'
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                />
            </Row>
            <Row>
                <label>Last Name</label>
                <TextField
                    {...register('lastName', { required: 'Required Field' })}
                    onKeyUp={(e) => { capitalizeInput(e, 'lastName', setValue) }}
                    variant='outlined' size='small'
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                />
            </Row>
            <Row>
                <label>Business Email</label>
                <TextField
                    {...register('email', { required: 'Required Field' })}
                    variant='outlined' size='small'
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                />
            </Row>
            <Row>
                <label>Mobile Number</label>
                <Controller
                    name="outboundCallPhoneNumber"
                    control={control}
                    rules={{ required: 'Required Field', validate: (value) => { return isValidPhoneNumber(`+1${value}`)||'Invalid Phone Number' } }}
                    render={({ field }) => (
                        <PhoneNumberField
                            {...register('outboundCallPhoneNumber', { required: 'Required Field', validate: (value) => { return isValidPhoneNumber(value.match(/^\+1*/) ? value : `+1${value}`)||'Invalid Phone Number' }  })}
                            name={field.name}
                            size='small'
                            error={!!errors?.outboundCallPhoneNumber}
                            helperText={errors?.outboundCallPhoneNumber?.message}
                        />
                    )}
                />
            </Row>
            <Row>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label>AutoPylot #</label>
                    <div
                        style={{ marginLeft: '5px' }}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <HelpIcon htmlColor='#42B1F2' fontSize='small' />
                    </div>
                    <PopUp
                        open={openPopUp}
                        anchorElement={anchorElement}
                        onClose={handlePopoverClose}
                    >
                        <PopupTypography sx={{ p: 1 }}>
                            Allows users to make and receive business calls and send and receive text messages, through AutoPylot Mobile app.
                            Users can easily separate their business and personal communication.
                            Such activities are automatically logged and organized in AutoPylot Console and Mobile app.
                        </PopupTypography>
                    </PopUp>
                </div>
                {user.didAllocation?.did?.phoneNumber ?
                    <TextField
                        {...register('autopylotNumber', { required: 'Required Field' })}
                        disabled
                        variant='outlined' size='small'
                        error={!!errors?.autopylotNumber}
                        helperText={errors?.autopylotNumber?.message}
                    /> :
                    <AutoPylotAutocomplete
                        placeholder={"Type Desired Area Code"}
                        id="autopylotnums-autocomplete"
                        register={register('autopylotNumber', {
                            required: 'Required Field', validate: value => { return isValidPhoneNumber(value||'') || "Invalid Phone Number" }
                        })}
                        onSelectOption={(val: any) => { setValue('autopylotNumber', val?.number?.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4'), {shouldValidate: true}); }}
                        open={showAutocomplete}
                        onClose={() => setShowAutocomplete(false)}
                        options={autocompleteOptions}
                        onChangeValue={debouncedGetTwilioNumbers}
                        loading={loadingNumbers}
                        error={!!errors?.autopylotNumber}
                        helperText={errors?.autopylotNumber?.message}
                    />
                }
            </Row>
            <ButtonRow>
                {loading ? <CircularProgress /> : <Button type='submit' disabled={!isValid} >Save</Button>}
            </ButtonRow>
        </form>
    )
}


export default AutopylotNumSetupForm