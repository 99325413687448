import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

export const OrderPaper = styled(Paper)`
    border-radius: 10px;
    border: 1px solid #707070;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    margin: 0 auto;

    min-width: 433px;
    width: 70%;
    box-shadow: none;

    font: normal normal normal 0.9em/35px Roboto;
    @media (max-width: 1024px) {
        min-width: 400px;
    }
`

export const AgreementPaper = styled(Paper)`
    text-align: initial;
    max-height: 500px;
    overflow: auto;
    padding: 0 10px;
    margin: 20px 0 30px;
    font-size: 0.8rem;
    color: #6B6A6E;
    background-color: #F0F2F5;
    & > div {
        font-size: 0.7rem;
        * {
            // display: inline-block;
            line-height: 1.5em;
        }
    }
`

export const ContentWrapper = styled.div`
    padding: 10px 10px 0 10px;
    height: 100%;
    & form {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    & hr {
        width: 100%;
    }
`

export const Title = styled.span`
    font-weight: normal;
    color: #6B6A6E;
    letter-spacing: 0.72px;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;

    & > div > svg {
        cursor: pointer;
        opacity: 0.3;
         &:hover {
             opacity: 0.8;
         }
    }
`

export const Container = styled.div`
    height: 100vh;
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    & label {
        margin-top: 5px;
        color: rgba(107, 106, 110, 0.5);
        font-size: 0.8rem;
        letter-spacing: 0.28px;
    }
`

export const ButtonRow = styled.div`
    margin-top: 50px;
    text-align: left;

    & p.Mui-error {
        text-align: left;
    }
    & > span > svg {
        color: #EF681E;
    }
    & label > .Mui-checked > svg {
        color: rgb(3, 117, 255);
    }
`

export const SubsriptionDetailsBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;

    & > div {
        text-align: left;
        &:first-child {
            width: 70%;
            padding-right: 20px;
        }
        &:last-child {
            width: 30%;
            text-align: center;
        }
    }
`

export const StyledIconButton = styled(IconButton)`
    border-radius: 0;
    padding: 0;
    svg {
        font-size: 1rem;
        transform: scale(1.5);
    }
`

export const StyledInputAdornment = styled(InputAdornment)`
    height: auto;
`

export const UsersNumberField = styled(TextField)`
    & input {
        width: 25px;
    }
    & div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0;
        align-items: initial;

        & .MuiInputAdornment-root {
            display: flex;
            flex-direction: column;
            margin: 0;
            height: inherit;
            min-height: 37px;
            padding: 3px 3px 3px 0;
        }
    }
`
