import { makeObservable, computed } from 'mobx';
import { Model, IModel, IAttributes, IPayload } from '../types/storeTypes'

export interface ContactAttributes extends IAttributes {
    fname: string;
    lname: string;
}

export interface IContact extends IModel {
    fullName: string;
};

// Domain object Call.
export class Contact extends Model {
    attributes: ContactAttributes
    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes

        makeObservable(this);
    }

    @computed
    get fullName() {
        if (this.attributes.fname || this.attributes.lname) {
          return `${this.attributes.fname} ${this.attributes.lname}`;
        } else {
          return 'Unknown';
        }
    }
}
