import {AudioPlayer} from "react-audio-play";
import {useSearchParams} from "react-router-dom";
import {Logo} from "../../components/PublicLayout/styledComponents";
import logoImg from "../../components/PublicLayout/logo.jpeg";

const CallAudio = () => {
  let [searchParams] = useSearchParams();
  const signedId = searchParams.get("signed_id");
  const filename = searchParams.get("filename");

  const audioFile = `${process.env.REACT_APP_KNOWMEBACKEND}v1/uploads/${signedId}/${filename}?disposition=inline`;

  const onError = (
    event: React.SyntheticEvent<HTMLAudioElement, Event>,
    errorMessage: string
  ) => {
    console.log(errorMessage);
  };

  return (
    <div className="App">
      <Logo
        onClick={() => {
          window.location.replace("https://www.autopylot.com");
        }}
      >
        <img src={logoImg} alt="logo" />
      </Logo>
      <AudioPlayer
        sliderColor="#EF681E"
        style={{margin: "100px auto 0 auto"}}
        onError={onError}
        src={audioFile}
      />
    </div>
  );
};

export default CallAudio;
