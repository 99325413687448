import styled from '@emotion/styled'

export const Container = styled.div`
    height: 100vh;
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    & label {
        margin-top: 5px;
        color: rgba(107, 106, 110, 0.5);
        font-size: 0.8rem;
        letter-spacing: 0.28px;
    }
`
