import ScreenTitle from "../../components/ScreenHeader/ScreenHeader";
import Table from "./Table";
import {Container /*, Row, ButtonRow*/} from "./StyledComponents";
import {useAppContext} from "../../context/AppContext";
import {CallStore} from "../../stores/CallStore";

export interface ICall {
  callAudio: string;
  createdAt: string;
  callLength: string;
  from: string;
  to: string;
  status: string;
}

const callStore = new CallStore();

const Calls = () => {
  const {loading, setLoading} = useAppContext();
  return (
    <>
      <Container>
        <div className="title-wrapper">
          <ScreenTitle title="Calls List" buttonLabel="" />
        </div>
        <div className="table-wrapper">
          <Table setLoading={setLoading} callStore={callStore} />
        </div>
      </Container>
    </>
  );
};

export default Calls;
