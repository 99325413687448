import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useAppContext } from '../../../context/AppContext'

import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CircleIcon from '@mui/icons-material/Circle';

import { loadStripe, StripeElementChangeEvent } from '@stripe/stripe-js';

import {
    CardNumberElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import {
    StripeTextFieldNumber,
    StripeTextFieldExpiry,
    StripeTextFieldCVC
} from '../../BillingInformation/commonTextFields';

import {
    CreditCardWrapper,
    CreditCardIconWrapper,
    CreditCardInfoWrapper,
    CardNumber,
    PencilIconWraper,
    PencilIcon,
    BillingDetailsContainer,
    Row,
    StyledTextField,
    StyledMenuItem
} from './styledComponents'

import { Account, AccountAttributes } from '../../../stores/AccountStore'
import { getCreditCard } from "../../../services/accounts"

import Card from '../../../components/Card/Card'
import Button from '../../../components/Button/Button'

import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import 'moment-timezone'


const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || '');

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

interface ItemOption {
    key: string
    label: string
}

export interface IBillingInformationForm {
    paymentMethod: string
    cardNumber: string
    expirationDate: string
    cvc: string
    billingType: string
    stripeToken: string
    holderName: string
    holderType: string
    routingNumber: string
    accountNumber: string
}

interface IPlanDetails {
    account: any
    isLoading: boolean
}

const PlanDetails = ({account, isLoading}: IPlanDetails) => {
    const [sourceLoaded, setSourceLoaded] = useState(false)
    const [showBilllingForm, setShowBillingForm] = useState(false)
    const [creditCard, setCreditCard] = useState({})
    const [expiresAt, setExpiresAt] = useState(moment())
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })
    const [errorMsg, setErrorMsg] = useState('')

    const getCreditCardInfo = async () => {
        const { data } = await getCreditCard()
        const expiresAt = new Date(get(data, 'data.attributes.exp_year'), get(data, 'data.attributes.exp_month'), 1)

        setExpiresAt(moment(expiresAt))
        setCreditCard(data)
        setSourceLoaded(true)
    }

    const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setErrorMsg(message)
        setSnackbarProps({ severity, open: true })
    }

    const onPencilClick = () => {
        setShowBillingForm(showBilllingForm => !showBilllingForm)
    }

    const saveBilingDeatils = async (values: IBillingInformationForm) => {
        try {
            const attributes = {
                id: account.id,
                stripe_token: values.stripeToken,
                billing_type: values.billingType
            } as AccountAttributes

            await account.update(attributes, {}, 'settings,subscription.plan,subscription.promo_code')
            await getCreditCardInfo()
            setSourceLoaded(true)
            setShowBillingForm(false)
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    useEffect(() => {
        if (!isLoading) {
            if (!account.manualBillingType) {
                getCreditCardInfo()
            } else {
                setSourceLoaded(true)
            }
        }
    }, [isLoading])

    const card = (
        <CreditCardWrapper>
            <CreditCardIconWrapper>
                <CreditCardIcon/>
            </CreditCardIconWrapper>
            <CreditCardInfoWrapper>
                <Typography align='left'>{get(creditCard, 'data.attributes.name')}</Typography>
                <CardNumber>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <span>&nbsp;</span>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <span>&nbsp;</span>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <span>&nbsp;</span>
                    <Typography align='left'>{get(creditCard, 'data.attributes.last4')}</Typography>
                </CardNumber>

                <Typography align='left'>Expires {expiresAt.format('MM/YYYY')}</Typography>
            </CreditCardInfoWrapper>
        </CreditCardWrapper>
    )

    const bankAccount = (
        <CreditCardWrapper>
            <CreditCardIconWrapper>
                <AccountBalanceIcon/>
            </CreditCardIconWrapper>
            <CreditCardInfoWrapper>
                <Typography align='left'>{get(creditCard, 'data.attributes.bank_name')}</Typography>
                <CardNumber>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <span>&nbsp;</span>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <span>&nbsp;</span>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <CircleIcon/>
                    <span>&nbsp;</span>
                    <Typography align='left'>{get(creditCard, 'data.attributes.last4')}</Typography>
                </CardNumber>
            </CreditCardInfoWrapper>
        </CreditCardWrapper>
    )

    const source = (
        get(creditCard, 'data.attributes.object') === 'card' ? card : bankAccount
    )

    return (
        <>
            <Card title='PAYMENT METHOD' titleAlign='left'>
                {!sourceLoaded ? <CircularProgress /> : 
                    <BillingDetailsContainer>
                        <PencilIconWraper onClick={(e) => onPencilClick()}>
                            <PencilIcon />
                        </PencilIconWraper>
                        {!showBilllingForm && <>
                            <Typography align='left'>Billing period</Typography>
                            <Typography align='left'>Monthly</Typography>
                            {!account.manualBillingType ? source : <Typography align='left'>Bill me - Send Invoice</Typography>}
                        </>}

                        {showBilllingForm && <Elements stripe={stripePromise}>
                            <BillingDetailsForm
                                onSubmitForm={saveBilingDeatils}
                                paymentMethod={account.manualBillingType ? 'bill_us' : (get(creditCard, 'data.attributes.object') === 'card' ? 'card' : 'ach')}
                                account={account}

                                setSnackbarProps={setSnackbarProps}
                                setErrorMsg={setErrorMsg}
                            />
                        </Elements>}
                    </BillingDetailsContainer>
                }
            </Card>
        </>
    )
}

interface IBillingDetailsForm {
    account: Account
    paymentMethod: 'bill_us' | 'ach' | 'card'
    onSubmitForm: (values: IBillingInformationForm) => void
    setSnackbarProps: (arg: CustomSnackbarProps) => void
    setErrorMsg: (msg: string) => void
}

const BillingDetailsForm = (props: IBillingDetailsForm) => {
    const stripe = useStripe();
    const elements = useElements();

    const { account, paymentMethod } = props
    const [state, setState] = useState({
        cardNumberComplete: false,
        expiredComplete: false,
        cvcComplete: false,
        cardNumberError: undefined,
        expiredError: undefined,
        cvcError: undefined
    });
    const billingMethods = [
        {
            key: 'card',
            label: 'Bill Card'
        },
        {
            key: 'bill_us',
            label: 'Bill us (Send invoice)'
        },
        // {
        //     key: 'ach',
        //     label: 'ACH'
        // }
    ]

    const { loading, setLoading } = useAppContext()

    const { register, handleSubmit, /*setError, setValue, */setValue, formState: { errors, isValid, isDirty }, watch, trigger } = useForm<IBillingInformationForm>({
        mode: 'onChange',
        defaultValues: {
            paymentMethod: paymentMethod,
            billingType: 'automatic',
            // cc
            cardNumber: '',
            cvc: '',
            expirationDate: '',
            // ach
            holderName: '',
            holderType: 'individual',
            routingNumber: '',
            accountNumber: '',
        }
    })
    const watchPaymentMethod = watch('paymentMethod', paymentMethod)
    useEffect(()=>{
        setValue('paymentMethod', watchPaymentMethod, {shouldValidate: true});
    }, [watchPaymentMethod])

    const onSubmitBilligInformation = async (values: IBillingInformationForm) => {
        try {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }
            setLoading(true)

            let payload = null;

            switch (values.paymentMethod) {
                case 'card':
                    const card = elements.getElement(CardNumberElement);

                    if (card == null) {
                        setLoading(false)
                        return;
                    }
                    payload = await stripe?.createToken(card)
                    values.billingType = 'automatic'
                    break;
                case 'ach':
                    const bankAccountData = {
                        country: 'us',
                        currency: 'usd',
                        routing_number: values.routingNumber,
                        account_number: values.accountNumber,
                        account_holder_name: values.holderName,
                        account_holder_type: values.holderType
                    }
                    payload = await stripe?.createToken('bank_account', bankAccountData)
                    values.billingType = 'automatic'
                    break;
                case 'bill_us':
                    values.billingType = 'manual'
                    break;
                default:
                    console.log(`Sorry, we are out of.`);
                    setLoading(false)
                    return
            }

            if (values.billingType === 'automatic') {
                if (payload?.error) {
                    props.setErrorMsg(payload.error?.message||'Something went wrog')
                    props.setSnackbarProps({ severity: 'error', open: true })
                    setLoading(false)
                    return
                } else {
                    values.stripeToken = payload?.token?.id || ''
                }
            }

            await props.onSubmitForm(values)

            setLoading(false)
        } catch (error: any) {
            props.setErrorMsg('Something went wrog')
            props.setSnackbarProps({ severity: 'error', open: true })
            setLoading(false)
        }
    }

    const logEvent = (name: string) => (event: any) => {
        console.log(`[${name}]`, event);
    };

    const onElementChange = (field: string, errorField: string) => (
        event: StripeElementChangeEvent
      ) => {
        setState({ ...state, [field]: event.complete, [errorField]: event.error?.message });
      };

    const { cardNumberComplete, cvcComplete, expiredComplete } = state;
    const { cardNumberError, expiredError, cvcError } = state;

    const cardForm = (
        <>
            <Row>
                <label>Card number</label>
                <StripeTextFieldNumber
                    error={Boolean(cardNumberError)}
                    labelErrorMessage={cardNumberError}
                    onChange={onElementChange("cardNumberComplete", "cardNumberError")}
                />
            </Row>
            <Row>
                <label>CCV</label>
                <StripeTextFieldCVC
                    error={Boolean(cvcError)}
                    labelErrorMessage={cvcError}
                    onChange={onElementChange("cvcComplete", "cvcError")}
                />
            </Row>
            <Row>
                <label>Expiration date</label>
                <StripeTextFieldExpiry
                    error={Boolean(expiredError)}
                    labelErrorMessage={expiredError}
                    onChange={onElementChange("expiredComplete", "expiredError")}
                />
            </Row>
        </>
    )

    const achForm = (
        <>
            <Row>
                <label>Name</label>
                <StyledTextField
                    {...register('holderName', {
                        validate: value => watchPaymentMethod !== 'ach' || !!value && watchPaymentMethod === 'ach' || 'Required Field'
                    })}
                    defaultValue={account.holderName}
                    variant='outlined' size='small'
                    error={!!errors?.holderName}
                    helperText={errors?.holderName?.message}
                />
            </Row>
            <Row>
                <label>Type</label>
                <StyledTextField
                    {...register('holderType', {
                        validate: value => watchPaymentMethod !== 'ach' || !!value && watchPaymentMethod === 'ach' || 'Required Field'
                    })}
                    defaultValue='individual'
                    select
                    variant='outlined' size='small'
                >
                    <StyledMenuItem key='individual' value='individual'>
                        Individual
                    </StyledMenuItem>
                    <StyledMenuItem key='company' value='company'>
                        Company
                    </StyledMenuItem>
                </StyledTextField>
            </Row>
            <Row>
                <label>Routing number</label>
                <StyledTextField
                    {...register('routingNumber', {
                        validate: (value) => {
                            return watchPaymentMethod !== 'ach' || !!value && watchPaymentMethod === 'ach' || 'Required Field'
                        }
                    })}
                    placeholder="110000000"
                    variant='outlined' size='small'
                    error={!!errors?.routingNumber}
                    helperText={errors?.routingNumber?.message}
                />
            </Row>
            <Row>
                <label>Account number</label>
                <StyledTextField
                    {...register('accountNumber', {
                        validate: value => watchPaymentMethod !== 'ach' || !!value && watchPaymentMethod === 'ach' || 'Required Field'
                    })}
                    placeholder="000123456789"
                    variant='outlined' size='small'
                    error={!!errors?.accountNumber}
                    helperText={errors?.accountNumber?.message}
                />
            </Row>
        </>
    )

    return (
        <form onSubmit={handleSubmit(onSubmitBilligInformation)}>
            <Grid container sx={{ padding: '40px 0 0 0' }}>
                <Grid item xs={12} md={12}>
                    <Row>
                        <label>Payment Method</label>
                        <StyledTextField
                            {...register('paymentMethod')}
                            select
                            defaultValue={paymentMethod}
                            variant='outlined' size='small'
                        >
                        {billingMethods.map((option: ItemOption) => (
                            <StyledMenuItem key={option.key} value={option.key} >
                                {option.label}
                            </StyledMenuItem>
                        ))}
                        </StyledTextField>
                    </Row>

                    {watchPaymentMethod === 'card' && cardForm}
                    {watchPaymentMethod === 'ach' && achForm}

                </Grid>
            </Grid>
            <Row>
                {loading ? <CircularProgress /> : <Button type='submit' disabled={!isDirty || !isValid || watchPaymentMethod === 'card' && (!cardNumberComplete || !cvcComplete || !expiredComplete)}>Save</Button>}
            </Row>
        </form>
    );
};

export default PlanDetails
