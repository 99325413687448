import React from "react";
import logoImg from "./logo.jpeg";
import {
  Logo,
  MainContainer,
  Container,
  StyledLink,
  Header,
  Footer,
  Copyright,
  FooterLinks,
} from "./styledComponents";

interface IPublicPage {
  children: React.ReactNode;
  hideFooter?: boolean;
}

const PublicLayout = ({children, hideFooter}: IPublicPage): JSX.Element => {
  return (
    <MainContainer>
      <Header>
        <Logo
          onClick={() => {
            window.location.replace("https://www.autopylot.com");
          }}
        >
          <img src={logoImg} alt="logo" />
        </Logo>
      </Header>
      <Container>
        {children}
        {!hideFooter && (
          <Footer>
            <Copyright>Copyright @ AutoPylot Technologies Corp. 2024</Copyright>
            <FooterLinks>
              <StyledLink
                href="https://www.autopylot.com/privacy"
                target={"_blank"}
              >
                Privacy Policy
              </StyledLink>
              <StyledLink
                href="https://www.autopylot.com/terms"
                target={"_blank"}
              >
                Terms of Service
              </StyledLink>
            </FooterLinks>
          </Footer>
        )}
      </Container>
    </MainContainer>
  );
};

export default PublicLayout;
