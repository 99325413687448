import * as React from "react"
import { useTheme } from '@mui/system'
import { InputBaseComponentProps } from '@mui/material/InputBase'

const StripeInput = React.forwardRef<any, InputBaseComponentProps>(
  function StripeInput(props, ref) {
    const { component: Component, options, ...other } = props;
    const theme = useTheme();
    const [mountNode, setMountNode] = React.useState<any | null>(null);

    React.useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus()
      }),
      [mountNode]
    );

    return (
      <Component
        onReady={setMountNode}
        options={{
          ...options,
          style: {
            base: {
              color: '#6B6A6E',
              fontSize: "14px",
              lineHeight: "1.4375em",
              fontFamily: 'roboto',
              letterSpacing: 'normal',
              "::placeholder": {
                color: '#6B6A6E'
              }
            },
            invalid: {
              color: '#6B6A6E'
            }
          }
        }}
        {...other}
      />
    );
  }
);

export default StripeInput;
