import styled from '@emotion/styled'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export const StyledLink = styled(Link)`
    margin 40px 0;
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: 0.8rem;
    font-weight: normal;

    cursor: pointer;
`

export const PopupTypography = styled(Typography)`
    padding: 10px 15px;
    display: block;
    background-color: rgb(52, 51, 58);
    color: rgb(210, 209, 215);
    font-weight: 400;
`

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    font: normal normal normal 14px/22px Roboto;
    letter-spacing: 0.28px;
`

export const ButtonRow = styled.div`
    margin-top: 50px;
    text-align: center;

    & > span > svg {
        color: #EF681E;
    }
`

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 10px;

    & label {
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #6B6A6E;
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-width: 1px;
        }
        &.Mui-focused {
            & fieldset {
                border-color: inherit;
                border-width: 1px;
            }
        }
    }
    & .MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-inputRoot {
        padding-right: 20px;
    }
`