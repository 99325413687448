import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { CustomTable as Table } from '../../components/Table/Table'

export const UsersTable = styled(Table)`
    th:first-child, td:first-child {
        padding-left: 0;
    }
`
export const Container = styled.div`
    min-height: 94vh;
    background-color: #F0F2F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    & > .title-wrapper, .table-wrapper {
        width: 90%;
        margin-bottom: 20px;
    }

    .table-wrapper {
        .centered-item {
            margin-right: 20px !important;
        }
        .multilines {
            display: flex;
        }
    }
`

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 10px;

    & label {
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #6B6A6E;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border-width: 1px;
        }
        &.Mui-focused {
            & fieldset {
                border-color: inherit;
                border-width: 1px;
            }
        }
    }
    & .MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-inputRoot {
        padding-right: 20px;
    }
`

export const PopupTypography = styled(Typography)`
    padding: 10px 15px;
    display: block;
    background-color: rgb(52, 51, 58);
    color: rgb(210, 209, 215);
    font-weight: 400;
`

export const ButtonRow = styled.div`
    margin-top: 50px;
    text-align: center;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    & label {
        color: rgba(107, 106, 110, 1);
        .Mui-checked {
            svg {
                color: rgb(3, 117, 255);
            }
        }
    }

    & > span > svg {
        color: #EF681E;
    }
`

export const DeleteModalBntRow = styled.div`
    display: flex ;
    justify-content: space-between;
    margin-top: 15px;
`

export const DeleteModalTitle = styled.label`
    font-size: 18px;
`

export const StyledFormControl = styled(FormControl)`
  text-align: left;
  background-color: #F0F2F5;
  border-radius: 4px;
  @media (max-width: 1280px) {
    width: 55%;
    min-width: auto;
    & input {
      font-size: 12px;
    }
  }
`
