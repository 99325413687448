import { useState } from 'react'
import { useForm } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '../../components/Button/Button'
import { demoSignup } from '../../services/demoSignup'
import { validateAreaCodeInput, capitalizeInput } from '../../utils/InputUtils'

import { useAppContext } from '../../context/AppContext'
import { MainContainer, Row, TitleWrapper, Title, StyledTextField, ButtonRow } from './styledComponents'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface DemoSignupFormData {
    first_name: string,
    last_name: string,
    email: string,
    area_code: string
}

export interface IDemoSignupForm {
    firstName: string,
    lastName: string,
    email: string,
    areaCode: string
}

export interface IValidError {
    id: string,
    code: string
    detail: string
    status: string
    title: string
    reason: string
}

const DemoSignup = () => {
    const { loading, setLoading } = useAppContext()
    const [status, setStatus] = useState('')

    const { setError, register, handleSubmit, setValue, formState, reset } = useForm<IDemoSignupForm>({
        defaultValues: { firstName: '', lastName: '', email: '', areaCode: '' }
    })

    const onSubmit = async (data: IDemoSignupForm) => {
        try {
            setLoading(true)
            await demoSignup({ first_name: data.firstName, last_name: data.lastName, email: data.email, area_code: data.areaCode })
            reset()
            setLoading(false)
            setStatus('Success')
        } catch (error: any) {
            if (error.response.status == 422) {
                error.response.data.errors.forEach((error: IValidError) => {
                    let parts = error.id.split('#')

                    let field = parts[0].trim() as 'firstName' | 'lastName' | 'email' | 'areaCode';
                    setError(field, {type: 'manual', message: error.detail})
                });
            } else {
                setStatus('Error')
            }
            setLoading(false)
        }
    }

    const form  = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <label>First Name</label>
                <StyledTextField
                    {...register('firstName', { required: 'Required Field' })}
                    inputProps={{
                        autoComplete: 'off',
                        form: {
                            autoComplete: 'off',
                        }
                    }}
                    onKeyUp={(e) => { capitalizeInput(e, 'firstName', setValue) }}
                    variant='outlined' size='small'
                    error={!!formState.errors?.firstName}
                    helperText={formState.errors?.firstName?.message}
                />
            </Row>
            <Row>
                <label>Last Name</label>
                <StyledTextField
                    {...register('lastName', { required: 'Required Field' })}
                    inputProps={{
                        autoComplete: 'off',
                        form: {
                            autoComplete: 'off',
                        }
                    }}
                    onKeyUp={(e) => { capitalizeInput(e, 'lastName', setValue) }}
                    variant='outlined' size='small'
                    error={!!formState.errors?.lastName}
                    helperText={formState.errors?.lastName?.message}
                />
            </Row>
            <Row>
                <label>Email Address</label>
                <StyledTextField
                    {...register('email', { required: 'Required Field' })}
                    inputProps={{
                        autoComplete: 'off',
                        form: {
                            autoComplete: 'off',
                        }
                    }}
                    variant='outlined' size='small'
                    error={!!formState.errors?.email}
                    helperText={formState.errors?.email?.message}
                />
            </Row>
            <Row>
                <label>Preferred Area Code</label>
                <StyledTextField
                    {...register('areaCode', { required: 'Required Field' })}
                    onKeyDown={(e) => { validateAreaCodeInput(e, 'areaCode', setValue) }}
                    variant='outlined' size='small'
                    error={!!formState.errors?.areaCode}
                    helperText={formState.errors?.areaCode?.message}
                />
            </Row>
    

            <ButtonRow style={{ marginBottom: '20px' }}>
                {loading ? <CircularProgress /> : <Button type="submit" variant='contained'>Submit</Button>}
            </ButtonRow>
        </form>
    )

    const successMessage = (
        <TitleWrapper>
            <CheckCircleOutlineIcon/>
            <Title>
                Your email has been added to the service. Download the app, enter your email address, and start trying the service
            </Title>
        </TitleWrapper>
    )

    const errorMessage = (
        <TitleWrapper>
            <ErrorOutlineIcon/>
            <Title>
                Server Error. Please try again later.
            </Title>
        </TitleWrapper>
    )

    return (
        <MainContainer>
            {form}
            {status === 'Success' ? successMessage : (status === 'Error' ? errorMessage : null)}
        </MainContainer>
    )
}

export default DemoSignup
