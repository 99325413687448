import API from '../API/config'
import { LoginFormData } from '../views/Login/index'

export const loginUser = (user: LoginFormData) => {
    return API.post('/v1/authentication_tokens', {user})
}

export const impersonateUser = (accountId: number) => {
    return API.post('/impersonate_token', {
        data: {
            type: "impersonate_tokens",
            attributes: {
                account_id: accountId
            }
        }
    })
}