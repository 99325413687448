import API from '../API/config'
import { IPayload } from '../types/storeTypes'

export const getNotes = () => {
    return API.get('/notes')
}

interface QueryParams {
  filter: string;
  from: string;
  to: string;
}

export class NoteService {
  get = async (urlParams: QueryParams) => {
    const options = {
      method: "GET",
    }
    const resp: IPayload = await API.get('/notes', { params: urlParams} )
    return resp.data;
  }
}
