import { ItemOption } from './types'

export const industries: ItemOption[] = [
  {key: 'agriculture_forestry_fishing_and_hunting', label: 'Agriculture, Forestry, Fishing and Hunting'},
  {key: 'mining_quarrying_and_oil_and_gas_extraction', label: 'Mining, Quarrying, and Oil and Gas Extraction'},
  {key: 'utilities', label: 'Utilities'},
  {key: 'construction', label: 'Construction'},
  {key: 'manufacturing', label: 'Manufacturing'},
  {key: 'wholesale_trade', label: 'Wholesale Trade'},
  {key: 'retail_trade', label: 'Retail Trade'},
  {key: 'transportation_and_warehousing', label: 'Transportation and Warehousing'},
  {key: 'information', label: 'Information'},
  {key: 'finance_and_insurance', label: 'Finance and Insurance'},
  {key: 'real_estate_and_rental_and_leasing', label: 'Real Estate and Rental and Leasing'},
  {key: 'professional_scientific_and_technical_services', label: 'Professional, Scientific, and Technical Services'},
  {key: 'management_of_companies_and_enterprises', label: 'Management of Companies and Enterprises'},
  {key: 'administrative_and_support_and_waste_management_and_remediation_services', label: 'Administrative and Support and Waste Management and Remediation Services'},
  {key: 'educational_services', label: 'Educational Services'},
  {key: 'health_care_and_social_assistance', label: 'Health Care and Social Assistance'},
  {key: 'arts_entertainment_and_recreation', label: 'Arts, Entertainment, and Recreation'},
  {key: 'accommodation_and_food_services', label: 'Accommodation and Food Services'},
  {key: 'other_services', label: 'Other Services'},
  {key: 'public_administration', label: 'Public Administration'}
]
