import styled from '@emotion/styled'

export const Container = styled.div`
    min-height: 94vh;
    background-color: #F0F2F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    & > .filters-wrapper {
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px 10px;
        text-align: left;
    }

    & > .filters-wrapper, .table-wrapper {
        width: 80%;
        margin-bottom: 20px;
    }

    .table-wrapper {
        .centered-item {
            margin-right: 20px !important;
        }
    }
`


export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 10px;

    & label {
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #6B6A6E;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border-width: 1px;
        }
        &.Mui-focused {
            & fieldset {
                border-color: inherit;
                border-width: 1px;
            }
        }
    }
    & .MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-inputRoot {
        padding-right: 20px;
    }
`

export const ButtonRow = styled.div`
    margin-top: 50px;
    text-align: center;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    & label {
        color: rgba(107, 106, 110, 1);
        .Mui-checked {
            svg {
                color: rgb(3, 117, 255);
            }
        }
    }
`