import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'

export const StyledMenuItem = styled(MenuItem)`
  min-width: 60%;
  text-align: left;
  background-color: #F0F2F5;
`

export const PencilIconWraper = styled(IconButton)`
    position: absolute;
    right: 0;
    top: -40px;
`

export const PencilIcon = styled(EditRoundedIcon)`
    font-size: 1rem;
    cursor: pointer;
`

export const CreditCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const CreditCardIconWrapper = styled.div`
    display: flex;
    margin-right: 1rem;
    svg {
        font-size: 4rem;
    }
`

export const CreditCardInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
        font-size: 0.3rem;
    }
`

export const CardNumber = styled.div`
    display: flex;
    align-items: center;

`

export const BillingDetailsContainer = styled.div`
    position: relative;
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    & label {
        margin-top: 5px;
        color: rgba(107, 106, 110, 0.5);
        font-size: 0.8rem;
        font-weight: normal;
        letter-spacing: 0.28px;
    }
`

export const StyledTextField = styled(TextField)`
    min-width: 65%;
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
    & div {
        color: #6B6A6E;
        font-size: 14px;
    }
    & input  {
        background: #F0F2F5 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border-color: #F0F2F5;
    }
    & fieldset {
        border: none;
    }
`