import axios from 'axios'
import { saveInLocal, getFromLocal, removeFromLocal } from '../utils/LocalStorageService'

const API = axios.create({
    baseURL: process.env.REACT_APP_KNOWMEBACKEND,
})


API.interceptors.request.use(
    config => {
        const authToken = getFromLocal('token')
        if (authToken) {
            config.headers = { 'Authorization': authToken }
        }

        // ADDING CONTENT TYPE IN THE HERE BECAUSE IT WASN'T SETTING CORRECTLY ON AXIOS.CREATE
        config.headers = { ...config.headers, 'Content-Type': 'application/vnd.api+json' }
        return config
    }, error => {
        return Promise.reject(error)
    })

API.interceptors.response.use((response) => {
    if (response.data.token) {
        saveInLocal('token', response.data.token)
    }
    return response
}, error => {
    if (error.response.status === 401) {
        removeFromLocal('token')
    }
    return Promise.reject(error)
})


export default API
