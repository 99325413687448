import styled from '@emotion/styled'

export const Container = styled.div`
    background-color: #F0F2F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 70px;

    & > .title-wrapper, .table-wrapper {
        width: 80%;
        margin-bottom: 20px;
    }
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & label {
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #6B6A6E;
        opacity: 0.3;
    }

    & input + fieldset {
      background: #F0F2F5 0% 0% no-repeat padding-box;
      border-radius: 4px;
      border-color: #F0F2F5;
    }
`

export const CloseFormButton = styled.div`
    > svg {
        cursor: pointer;
        opacity: 0.3;
         &:hover {
             opacity: 0.8;
         }
    }
`

export const ButtonRow = styled.div`
    margin-top: 50px;
    text-align: left;

    & > span > svg {
        color: #EF681E;
    }
`

export const Title = styled.div`
    letter-spacing: 0.4px;
    color: #6B6A6E;
`

export const Subtitle = styled.div`
    letter-spacing: 0.28px;
    color: #6B6A6E;
    font-size: 14px;
    opacity: 0.6;
`
