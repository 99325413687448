import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import Button from '../../components/Button/Button'

export const Container = styled.div`
    min-height: calc(100vh - 160px);
    width: 300px;
`

export const CenteredRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`

export const LoginButton = styled(Button)`
    color: #EF681E;
    background: #fff;
    border: 1px solid #EF681E;
    &:hover {
        color: #EF681E;
        background: rgb(239, 104, 30, 0.01);
        border: 1px solid #EF681E;
    }
`

export const StyledTextField = styled(TextField)`
    min-width: 65%;
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
    & div {
        color: #6B6A6E;
        font-size: 14px;
    }
    & input  {
        background: #F0F2F5 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border-color: #F0F2F5;
    }
    & fieldset {
        border: none;
    }
`
