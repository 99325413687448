import styled from '@emotion/styled'
import Popover from '@mui/material/Popover'

const Content = styled.div`
    width: 250px;
`

interface IPopUpProps {
    open: boolean
    anchorElement: Element | ((element: Element) => Element) | null | undefined
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    children: React.ReactNode
}

const PopUp = ({ open, anchorElement, onClose, children }: IPopUpProps) => {
    return (
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorElement}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={onClose}
            disableRestoreFocus
        >
            <Content>
                {children}
            </Content>
            {/* <Typography sx={{ p: 1, width: '250px' }}>Mauris volutpat arcu vel sodales facilisis. Ut consequat ipsum odio, eu fermentum dui efficitur.</Typography> */}
        </Popover>
    )
}

export default PopUp