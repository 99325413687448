import React from "react";
import Select from "@mui/material/Select";
import {SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  BarChart,
  CartesianGrid,
} from "recharts";

import {
  RechartsLegendWrapper,
  StyledResponsiveContainer,
  StyledFormControl,
  TabedGrid,
  TabData,
  TabLabel,
  ProgressLabel,
  CustomFiltersContainer,
} from "./styledComponents";
import {observer} from "mobx-react";
import {makeObservable, observable, action, configure, computed} from "mobx";

import {IDatePeriod, datePeriods} from "../../types/timeFrames";

import {User} from "../../stores/UserStore";

import {CallStore} from "../../stores/CallStore";
import {TextStore} from "../../stores/TextStore";
import {NoteStore} from "../../stores/NoteStore";

import moment from "moment";
import "moment-timezone";

configure({enforceActions: "observed"});

interface IChartItem {
  name: string;
  calls_in: number;
  calls_out: number;
  texts: number;
  notes: number;
}

export interface IFilter {
  [key: string]: string | number | undefined;
}

interface UserPerformanceProps {
  user: any;
  setLoading?: (loading: boolean) => void;
}

class UserPerformanceStore {
  user: User;
  inboundCallStore;
  outboundCallStore;
  textStore;
  noteStore;
  setLoading;
  constructor(props: UserPerformanceProps) {
    this.user = props.user;
    this.setLoading = props.setLoading;

    this.inboundCallStore = new CallStore();
    this.outboundCallStore = new CallStore();
    this.textStore = new TextStore();
    this.noteStore = new NoteStore();

    makeObservable(this);
  }

  @observable
  chartData = [] as Array<IChartItem>;
  @observable
  callsPercentDiff = 0;
  @observable
  callsCountPercentDiff = 0;
  @observable
  textsPercentDiff = 0;
  @observable
  notesPercentDiff = 0;
  @observable
  totalCallLength = 0;
  @observable
  totalCalls = 0;
  @observable
  totalTexts = 0;
  @observable
  totalNotes = 0;
  timerId: any;

  @action
  loadData = (from: moment.Moment, to: moment.Moment) => {
    this.clearInterval();
    this.fetchAndPrepareChartData(from, to);
    this.timerId = setInterval(() => {
      this.fetchAndPrepareChartData(from, to);
    }, 30000);
  };

  clearInterval = () => {
    clearInterval(this.timerId);
  };

  fetchAndPrepareChartData = async (from: moment.Moment, to: moment.Moment) => {
    // TODO: use loading for component because it blocks user form too
    // this.setLoading(true)
    await this.fetchChartData(from, to);
    this.prepareChartData(from, to);

    let diff = to.diff(from, "days") + 1;
    let prevoiusFrom = from.clone().subtract(diff, "days");
    let prevoiusTo = to.clone().subtract(diff, "days");
    await this.fetchChartData(prevoiusFrom, prevoiusTo);

    this.preparePercentValues();
    // this.setLoading(false)
  };

  @action
  preparePercentValues = () => {
    let previousPeriodCalls =
      this.inboundCallStore.totalCallLength +
      this.outboundCallStore.totalCallLength;
    this.callsPercentDiff = this.calcPercentDiff(
      this.totalCallLength,
      previousPeriodCalls
    );

    let previousPeriodCallsCount =
      this.inboundCallStore.totalCalls + this.outboundCallStore.totalCalls;
    this.callsCountPercentDiff = this.calcPercentDiff(
      this.totalCalls,
      previousPeriodCallsCount
    );

    let previousPeriodTexts = this.textStore.totalTexts;
    this.textsPercentDiff = this.calcPercentDiff(
      this.totalTexts,
      previousPeriodTexts
    );

    let previousPeriodNotes = this.noteStore.totalNotes;
    this.notesPercentDiff = this.calcPercentDiff(
      this.totalNotes,
      previousPeriodNotes
    );
  };

  @action
  prepareChartData = (from: moment.Moment, to: moment.Moment) => {
    this.totalCallLength =
      Math.round(
        100 *
          (this.inboundCallStore.totalCallLength +
            this.outboundCallStore.totalCallLength)
      ) / 100.0;
    this.totalCalls =
      this.inboundCallStore.totalCalls + this.outboundCallStore.totalCalls;
    this.totalTexts = this.textStore.totalTexts;
    this.totalNotes = this.noteStore.totalNotes;

    let date = from.clone();
    let data = [] as Array<IChartItem>;

    while (date.isSameOrBefore(to)) {
      data.push({
        name: date.format("MM/DD"),
        calls_in:
          this.inboundCallStore.groupedCountByDate[date.format("YYYY-MM-DD")] ||
          0,
        calls_out:
          this.outboundCallStore.groupedCountByDate[
            date.format("YYYY-MM-DD")
          ] || 0,
        texts:
          this.textStore.groupedCountByDate[date.format("YYYY-MM-DD")] || 0,
        notes:
          this.noteStore.groupedCountByDate[date.format("YYYY-MM-DD")] || 0,
      });
      date.add(1, "day");
    }

    this.chartData = data;
  };

  fetchChartData = async (from: moment.Moment, to: moment.Moment) => {
    let callsFilters = {
      "filter[from_date]": from.startOf("day").format(),
      "filter[to_date]": to.endOf("day").format(),
      "filter[user_id]": this.user.id,
    } as IFilter;

    callsFilters["filter[call_type]"] = "inbound";
    await this.inboundCallStore.getAllCallsAsync(callsFilters);

    callsFilters["filter[call_type]"] = "outbound";
    await this.outboundCallStore.getAllCallsAsync(callsFilters);

    let textsFilters = {
      "filter[from_date]": from.startOf("day").format(),
      "filter[to_date]": to.endOf("day").format(),
      "filter[user_id]": this.user.id,
    } as IFilter;
    await this.textStore.getAllTextsAsync(textsFilters);

    let notesFilters = {
      "filter[from_date]": from.startOf("day").format(),
      "filter[to_date]": to.endOf("day").format(),
      "filter[user_id]": this.user.id,
    } as IFilter;
    await this.noteStore.getAllNotesAsync(notesFilters);
  };

  calcPercentDiff = (current: number, previous: number) => {
    let percent = 0;
    let diff = current - previous;
    if (current > previous) {
      percent = (diff * 100) / current;
    } else if (current < previous) {
      percent = (diff * 100) / previous;
    }
    return percent;
  };

  @computed
  get reversedChartData() {
    return this.chartData.slice().reverse();
  }
}

interface State {
  currentTab: string;
  period: string;
  to: moment.Moment;
  from: moment.Moment;
}

@observer
export default class UserPerformance extends React.Component<
  UserPerformanceProps,
  State
> {
  store;
  constructor(props: UserPerformanceProps) {
    super(props);
    this.store = new UserPerformanceStore(props);
    let selectedPeriod = datePeriods.find((period: IDatePeriod) => {
      return period.key === "last_7_days";
    });
    if (selectedPeriod) {
      this.state = {
        currentTab: "all",
        period: "last_7_days",
        from: selectedPeriod.from(),
        to: selectedPeriod.to(),
      };
    }
  }

  componentWillMount() {
    const {from, to} = this.state;
    this.store.loadData(from.clone(), to.clone());
  }

  componentWillUnmount() {
    this.store.clearInterval();
  }

  handleChange = (event: SelectChangeEvent) => {
    const period = event.target.value;
    if (period === "custom") {
      this.setState({
        period: period,
      });
    } else {
      let selectedPeriod = datePeriods.find((datePeriod: IDatePeriod) => {
        return datePeriod.key === period;
      });

      if (!selectedPeriod) {
        return;
      }

      this.setState({
        period: period,
        from: selectedPeriod.from(),
        to: selectedPeriod.to(),
      });
      this.store.loadData(
        selectedPeriod.from().clone(),
        selectedPeriod.to().clone()
      );
    }
  };

  handleOnDatePickerChange =
    (value: string) => (date: moment.Moment | null) => {
      this.setState({[value]: date} as any);
    };

  filterByCustomDates = () => {
    this.store.loadData(this.state.from.clone(), this.state.to.clone());
  };

  renderTooltip = (props: any) => {
    if (props.active) {
      const arr = props.payload.filter(
        (item: any) => item?.value && item?.value > 0
      );

      if (arr.length) {
        const items = arr.map((item: any, i: number) => (
          <li
            className="recharts-tooltip-item"
            key={`tooltip-item-${i}`}
            style={{
              display: "block",
              paddingTop: 4,
              paddingBottom: 4,
              color: item.color || "#000",
            }}
          >
            <span className="recharts-tooltip-item-name">{item.name}</span>
            <span className="recharts-tooltip-item-separator"> : </span>
            <span className="recharts-tooltip-item-value">{item.value}</span>
          </li>
        ));

        const listStyle = {
          padding: "0",
          margin: "0",
        };
        const tooltipStyle = {
          backgroundColor: "#fff",
          border: "1px solid rgb(204, 204, 204)",
          padding: "10px",
          margin: "0",
        };

        return (
          <div className="recharts-default-tooltip" style={tooltipStyle}>
            <p
              className="recharts-tooltip-label"
              style={{padding: "0", margin: "0"}}
            >
              {props.label}
            </p>
            <ul className="recharts-tooltip-item-list" style={listStyle}>
              {items}
            </ul>
          </div>
        );
      }
    }

    return <></>;
  };

  renderLegend = (props: any) => {
    const {payload} = props;

    return (
      <>
        {this.state.period === "custom" && (
          <div>
            <CustomFiltersContainer>
              <div className="custom-period-fields">
                <div className="date-filter-label">
                  <Typography>From</Typography>
                </div>
                <DesktopDatePicker
                  value={this.state.from}
                  onChange={this.handleOnDatePickerChange("from")}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={moment()}
                />
              </div>
              <div>
                <div className="date-filter-label">
                  <Typography>To</Typography>
                </div>
                <DesktopDatePicker
                  value={this.state.to}
                  onChange={this.handleOnDatePickerChange("to")}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={moment()}
                />
              </div>
              <IconButton onClick={this.filterByCustomDates}>
                <SearchIcon />
              </IconButton>
            </CustomFiltersContainer>
            <Divider />
          </div>
        )}
        <div className="time-period-dropdown">
          <StyledFormControl size="small">
            <Select value={this.state.period} onChange={this.handleChange}>
              {datePeriods.map((period: IDatePeriod) => {
                return (
                  <MenuItem key={period.key} value={period.key}>
                    {period.label}
                  </MenuItem>
                );
              })}
            </Select>
          </StyledFormControl>

          <RechartsLegendWrapper>
            {payload.map((entry: any, index: number) => (
              <li
                className="recharts-legend-item"
                key={`item-${index}`}
                style={{display: "inline"}}
              >
                <svg
                  className="recharts-surface"
                  width="10"
                  height="10"
                  viewBox="0 0 32 32"
                  version="1.1"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "4px",
                  }}
                >
                  <rect
                    x="0"
                    width="32"
                    height="32"
                    rx="10"
                    fill={entry.color}
                  />
                </svg>
                <span
                  className="recharts-legend-item-text"
                  style={{color: entry.color}}
                >
                  {entry.value}
                </span>
              </li>
            ))}
          </RechartsLegendWrapper>
        </div>
      </>
    );
  };

  selectTab = (tab: "all" | "calls" | "minutes" | "texts" | "notes") => {
    if (tab !== "minutes") {
      this.setState({currentTab: tab});
    }
  };

  isBarVisible = (tab: "all" | "calls" | "minutes" | "texts" | "notes") => {
    return this.state.currentTab === "all" || this.state.currentTab === tab;
  };

  render() {
    let daysCount = this.store.chartData.length;

    let barsHeight = 55;
    if (["minutes", "texts", "notes"].includes(this.state.currentTab)) {
      barsHeight = 55;
    }
    if (daysCount === 1) {
      barsHeight = 100;
    }

    let paddings = 40;
    let dateFilterHeight = 60;
    if (this.state.period === "custom") {
      dateFilterHeight += 115;
    }
    let aXisHeight = 30;

    let height = `${
      paddings + dateFilterHeight + aXisHeight + daysCount * barsHeight
    }px`;

    return (
      <Grid
        container
        sx={{marginTop: "30px"}}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={2} sx={{padding: "0 20px 0 0"}}>
          <TabedGrid
            item
            xs={12}
            className={this.state.currentTab === "all" ? "active all" : "all"}
            onClick={() => {
              this.selectTab("all");
            }}
          >
            <TabLabel>All Data</TabLabel>
          </TabedGrid>

          <TabedGrid
            item
            xs={12}
            className={this.state.currentTab === "calls" ? "active" : ""}
            onClick={() => {
              this.selectTab("calls");
            }}
          >
            <TabData variant="h2">{this.store.totalCalls}</TabData>
            <TabLabel>
              Calls
              <ProgressLabel
                className={
                  this.store.callsPercentDiff > 0
                    ? "increased"
                    : this.store.callsPercentDiff < 0
                    ? "decreased"
                    : ""
                }
              >
                {this.store.callsCountPercentDiff > 0 ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}{" "}
                {Math.round(Math.abs(this.store.callsCountPercentDiff))}%
              </ProgressLabel>
            </TabLabel>
          </TabedGrid>

          <TabedGrid
            item
            xs={12}
            sx={{cursor: "default !important"}}
            className={this.state.currentTab === "minutes" ? "active" : ""}
            onClick={() => {
              this.selectTab("minutes");
            }}
          >
            <TabData variant="h2">{this.store.totalCallLength}</TabData>
            <TabLabel>
              Talk time
              <ProgressLabel
                className={
                  this.store.callsPercentDiff > 0
                    ? "increased"
                    : this.store.callsPercentDiff < 0
                    ? "decreased"
                    : ""
                }
              >
                {this.store.callsPercentDiff > 0 ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}{" "}
                {Math.round(Math.abs(this.store.callsPercentDiff))}%
              </ProgressLabel>
            </TabLabel>
          </TabedGrid>

          <TabedGrid
            item
            xs={12}
            className={this.state.currentTab === "texts" ? "active" : ""}
            onClick={() => {
              this.selectTab("texts");
            }}
          >
            <TabData variant="h2">{this.store.totalTexts}</TabData>
            <TabLabel>
              Texts
              <ProgressLabel
                className={
                  this.store.textsPercentDiff > 0
                    ? "increased"
                    : this.store.textsPercentDiff < 0
                    ? "decreased"
                    : ""
                }
              >
                {this.store.textsPercentDiff > 0 ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}{" "}
                {Math.round(Math.abs(this.store.textsPercentDiff))}%
              </ProgressLabel>
            </TabLabel>
          </TabedGrid>

          <TabedGrid
            item
            xs={12}
            className={this.state.currentTab === "notes" ? "active" : ""}
            onClick={() => {
              this.selectTab("notes");
            }}
          >
            <TabData variant="h2">{this.store.totalNotes}</TabData>
            <TabLabel>
              Notes
              <ProgressLabel
                className={
                  this.store.notesPercentDiff > 0
                    ? "increased"
                    : this.store.notesPercentDiff < 0
                    ? "decreased"
                    : ""
                }
              >
                {this.store.notesPercentDiff > 0 ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}{" "}
                {Math.round(Math.abs(this.store.notesPercentDiff))}%
              </ProgressLabel>
            </TabLabel>
          </TabedGrid>
        </Grid>

        <Grid
          item
          xs={10}
          sx={{
            textAlign: "left",
            backgroundColor: "#fff",
            boxShadow: "0px 3px 22px #00000012",
            borderRadius: "10px",
            padding: "20px 40px",
            height: height, //this.state.period === 'custom' ? 'auto' : height
          }}
        >
          <StyledResponsiveContainer width="100%" height="100%">
            <BarChart
              layout="vertical"
              data={this.store.reversedChartData}
              barSize={10}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid
                horizontal={false}
                strokeDasharray="4 2"
                color="#6B6A6E"
              />
              <XAxis
                type="number"
                stroke="#6B6A6E"
                tickLine={false}
                tick={{fontSize: 14}}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                dataKey="name"
                type="category"
                scale="band"
              />
              <Tooltip
                cursor={{fill: "transparent"}}
                content={this.renderTooltip}
              />
              <Legend
                verticalAlign="top"
                align="right"
                iconType="square"
                iconSize={10}
                content={this.renderLegend}
              />
              {this.isBarVisible("calls") && (
                <Bar
                  name="Calls Out"
                  dataKey="calls_out"
                  stackId="a"
                  fill="#61006f"
                  radius={[3, 3, 3, 3]}
                />
              )}
              {this.isBarVisible("calls") && (
                <Bar
                  name="Calls In"
                  dataKey="calls_in"
                  stackId="a"
                  fill="#42b1f2"
                  radius={[3, 3, 3, 3]}
                />
              )}
              {this.isBarVisible("notes") && (
                <Bar
                  name="Notes"
                  dataKey="notes"
                  stackId="a"
                  fill="#FED361"
                  radius={[3, 3, 3, 3]}
                />
              )}
              {this.isBarVisible("texts") && (
                <Bar
                  name="Texts"
                  dataKey="texts"
                  stackId="a"
                  fill="#bbef1e"
                  radius={[3, 3, 3, 3]}
                />
              )}
            </BarChart>
          </StyledResponsiveContainer>
        </Grid>
      </Grid>
    );
  }
}
