import { makeObservable, runInAction, observable, computed } from 'mobx';
import { DeviceService } from '../services/devices'
import { Model, IModel, IMeta, IAttributes, IPayload } from '../types/storeTypes'
import moment from 'moment';

export class DeviceStore {
    deviceService: any;
    constructor(){
        this.deviceService = new DeviceService();
        makeObservable(this);
    }
    @observable
    devices: Array<Device> = []
    @observable
    meta = {} as IMeta;
    @observable
    status = "initial";
    searchQuery = "";

    getDevicesAsync = async (params: any) => {
        try {
           // const urlParams = new URLSearchParams(Object.entries(params));
           const data = await this.deviceService.get(params);

           runInAction(() => {
               this.devices = []

               this.meta.pageCount = data.meta.page_count;
               this.meta.recordCount = data.meta.record_count;

               data.data.forEach((json: any) => {
                   let payload = {data: json, included: data.included as IModel[]} as IPayload;

                   let text = new Device(this, payload)

                   this.devices.push(text)
               })
               this.status = "loaded";
               console.log(this.status);
           });
        } catch (error) {
           runInAction(() => {
               this.status = "error";
           });
        }
    };

    updateDeviceAsync = async (id: number, attrs: any) => {
        try {
            const payload = {
                data: {
                    id: id,
                    type: 'devices',
                    attributes: attrs
                },
            }
            await this.deviceService.update(id, payload);

            runInAction(() => {
               this.status = "loaded";
            });
       } catch (error) {
            runInAction(() => {
                this.status = "error";
            });
            throw error
       }
    }

    destroyDeviceAsync = async (id: number) => {
        try {
            const data = await this.deviceService.destroy(id);

            runInAction(() => {
               this.status = "loaded";
            });
       } catch (error) {
            runInAction(() => {
                this.status = "error";
            });
            throw error
       }
    };

    @computed
    get isLoaded() {
        return this.status === 'loaded'
    }
}


export interface DeviceAttributes extends IAttributes {
    created_at: string;
    updated_at: string;
    name: string;
}

export interface IDevice extends IModel {};

// Domain object Device.
export class Device extends Model {
    attributes: DeviceAttributes

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
    }

    @computed
    get createdAt() {
        return this.attributes.created_at;
    }

    @computed
    get updatedAt() {
        return moment(this.attributes.updated_at);
    }

    @computed
    get status() {
        return this.attributes.status;
    }

    @computed
    get uuid() {
        return this.attributes.uuid;
    }

    @computed
    get model() {
        return this.attributes.model;
    }

    @computed
    get platform() {
        return this.attributes.platform;
    }
}
