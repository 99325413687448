import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import { useForm } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'

import { useAppContext } from '../../context/AppContext'
import { User, UserAttributes } from "../../stores/UserStore";
import { getError } from '../../utils/ErrorUtils'
import Snackbar from '../../components/CustomSnackbar/CustomSnackbar'

import { Container, Row } from './styledComponents'
import { ButtonRow } from '../Users/StyledComponents'
import { StyledTextField } from '../Home/styledComponents'


export interface ICreatePasswordForm {
    password: string
    passwordConfirmation: string
    resetPasswordToken: string
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

const CreatePassword = (): JSX.Element => {
    const { currentUser, loading, setLoading, refreshCurrentUser } = useAppContext()

    let navigate = useNavigate()
    const [showModal, setShowModal] = useState(true)
    const [message, setMessage] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })


    const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm<ICreatePasswordForm>({
        mode: 'onChange',
        defaultValues: { password: '', passwordConfirmation: '' }
    })

    const watchPassword = watch("password", "");

    const closeModal = () => {
        navigate('/')
    }

    const onSubmitCreatePassword = async (values: ICreatePasswordForm) => {
        if (!currentUser) return
        try {
            setLoading(true)

            currentUser.assignAttributes({
                password: values.password,
                password_confirmation: values.passwordConfirmation,
            } as UserAttributes )

            await currentUser.store.updateUserAsync(currentUser.id, currentUser.payload)
            await refreshCurrentUser()

            navigate('/', { replace: true })

            setLoading(false)
        } catch (error: any) {
            const message = getError(error)
            setMessage(message)
            setLoading(false)
            setSnackbarProps({ severity: 'error', open: true })
        }
    }

    const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
        setSnackbarProps(state => ({ ...state, open: false }))
    }

    return (
        <Container>
            <Modal
                open={showModal}
                title="CREATE PASSWORD"
                maxWidth='xs'
                fullWidth
                onClose={closeModal}
            >
                <form id="create-password" onSubmit={handleSubmit(onSubmitCreatePassword)}>
                    <Row>
                        <label>New Password</label>
                        <StyledTextField
                            {...register('password', { required: 'Required Field' })}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                }
                            }}
                            variant='outlined' size='small' type='password'
                            error={!!errors?.password}
                            helperText={errors?.password?.message}
                        />
                    </Row>
                    <Row>
                        <label>Re-enter Password</label>
                        <StyledTextField
                            {...register('passwordConfirmation', {
                                validate: value => value === watchPassword || "The passwords do not match"
                            })}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                }
                            }}
                            variant='outlined' size='small' type='password'
                            error={!!errors?.passwordConfirmation}
                            helperText={errors?.passwordConfirmation?.message}
                        />
                    </Row>
                    <ButtonRow>
                        {loading ? <CircularProgress /> : <Button type='submit' disabled={!isValid} >Submit</Button>}
                    </ButtonRow>
                </form>
            </Modal>
            <Snackbar open={snackbarProps.open} message={message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />
        </Container>
    )
}

export default CreatePassword
