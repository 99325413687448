import styled from '@emotion/styled'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

export const StyledLink = styled(Link)`
    margin 40px 0;
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-weight: normal;
`

export const MainContainer = styled(Container)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
`

export const ContentWrapper = styled.div`
    padding: 10px 10px 0 10px;
    height: 100%;
    & form {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    & hr {
        width: 100%;
    }
`

export const Title = styled.span`
    color: #6B6A6E;
    letter-spacing: 0.72px;
    font-size: 0.8rem;
  }
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 0em;
    flex-direction: row;
    align-items: center;
    & svg {
        margin-right: 5px;
    }
`

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    font: normal normal normal 14px/22px Roboto;
    letter-spacing: 0.28px;
    font-weight: 400;
    text-align: left;
    &.bold {
        font-weight: 500;
    }
`

export const Row = styled.div`
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    text-align: initial;
    justify-content: space-between;
    align-items: center;
    min-width: 400px;

    &.remember-me {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & label {
            color: rgba(107, 106, 110, 1);
            .Mui-checked {
                svg {
                    color: rgb(3, 117, 255);
                }
            }
        }
    }

    & label {
        color: rgba(107, 106, 110, 0.5);
        /* font: normal normal normal 0.95em/35px Roboto; */
        letter-spacing: 0.28px;
    }
    & span {
        font-size: 0.8rem;
    }

    & input:-webkit-autofill {
        -webkit-background-clip: text;
    }
`

export const ButtonRow = styled.div`
    margin-top: 15px;
    text-align: center;

    & button {
        width: 100%;
    }
    & > span > svg {
        color: #EF681E;
    }
`

export const SubmitButton = styled(Button)`
   padding: 10px 60px;
   width: 100%;
`

export const StyledTextField = styled(TextField)`
  min-width: 65%;
  text-align: left;
  background-color: #F0F2F5;
  border-radius: 4px;
  & div {
    color: #6B6A6E;
    font-size: 0.8rem;
  }
  & input  {
    background: #F0F2F5 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border-color: #F0F2F5;
  }
  & fieldset {
    border: none;
  }
`
