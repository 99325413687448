import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ResponsiveContainer } from "recharts";


export const Container = styled.div`
    background-color: #F0F2F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 70px;

    & > .title-wrapper, .table-wrapper {
        width: 80%;
        margin-bottom: 20px;
    }
`

export const RechartsLegendWrapper = styled.ul`
     list-style-type: none;
     display: inline;
`

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
     & .recharts-legend-wrapper {
         display: flex;
         justify-content: space-between;
         & ul {
             & li {
                  margin-right: 10px;
             }
         }
     }
     .recharts-layer.recharts-cartesian-axis-tick {
        cursor: pointer;
     }
`

export const StyledFormControl = styled(FormControl)`
    min-width: 200px;
    color: #6B6A6E;
    font-size: 14px;
    & > div {
        background-color: #F0F2F5;
        fieldset {
            border: none;
        }
    }

`

export const TabedGrid = styled(Grid)`
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 3px 22px #00000012;
    border-radius: 10px;
    padding: 20px 40px;
    margin-top: 20px;
    cursor: pointer;
    &.active {
      border: 2px solid #760E82
    }
    &.all {
      margin-top: 0;
    }
`

export const TabData = styled(Typography)`
    font-size: 48px;
    letter-spacing: 1.44px;
    color: #6B6A6E;
    text-transform: uppercase;
`

export const TabLabel = styled(Typography)`
    font-size: 14px;
    letter-spacing: 0.84px;
    color: #6B6A6E;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const ProgressLabel = styled.span`
    font-size: 12px;
    letter-spacing: 0.84px;
    color: #6B6A6E;
    text-transform: uppercase;
    display: flex;
    & svg {
        font-size: 16px;
    }
    &.increased {
        color: #1EEF6B;
    }
    &.decreased {
        color: #EF681E;
    }
`

export const CustomFiltersContainer = styled.div`
    display: flex;
    align-items: flex-end;
    width: 50%;    
    gap: 15px;
    margin-bottom: 20px;

    & > div {
        .date-filter-label{
            letter-spacing: 0.28px;
            color: #6B6A6E;
            font-size: 14px;
            opacity: 0.6
        }
    }
`