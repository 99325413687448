import styled from '@emotion/styled'

interface StyledProps {
    fullWidth?: boolean
    width?: string
}

export const Container = styled.div`
    background-color: #F0F2F5;
    display: flex;
    justify-content: space-between;
    padding: 10px 70px 10px 120px;
    position: absolute;
    right: 0;
    width: ${({fullWidth, width}:StyledProps) => fullWidth ? '100%' : width};
    font-size: 0.8rem;
    letter-spacing: 0.28px;
`

export const StyledLink = styled.a`
    margin-right: 20px;
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: inherit;
    font-weight: normal;
    text-decoration: none;
`