import API from '../API/config'

export type DidResponse = {
    number: string
}
export type TwilioNumbersResponse = {
    data: Array<DidResponse>
}

export const getTwilioNumbers = (number: string): Promise<TwilioNumbersResponse> => {
    console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    let live = !process.env.NODE_ENV || process.env.NODE_ENV !== 'development';
    return API.get(`/v1/dids?type=local&filter=unassigned&query=${number}&show_as=numbers&live=${live}`)
}

