import React from "react";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import {visuallyHidden} from "@mui/utils";

import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import {useTheme} from "@mui/material/styles";

interface ITablePaginationActions {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: any;
}

function TablePaginationActions(props: ITablePaginationActions) {
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{flexShrink: 0, ml: 2.5}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page + 1 >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page + 1 >= Math.ceil(count / rowsPerPage)}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export interface IHeader {
  title: string;
  orderBy?: string;
  order?: "asc" | "desc";
  alignment?: "left" | "right" | "center" | undefined;
}

interface ITableProps {
  headers: IHeader[];
  tableContent: JSX.Element[];
  orderBy?: string;
  order?: "asc" | "desc";
  sortHandler?: (orderBy: string, order: "asc" | "desc") => void;
  paginateProps?: {
    page: number;
    perPage: number;
    totalCount: number;
    paginateHandler: (number: number, perPage: number) => void;
  };
}

const StyledTable = styled(Table)`
  border-radius: 10px;
  background-color: #fff;

  & .header {
    font-size: 13px;
    color: #6b6a6e;
    svg {
      font-size: 15px;
    }
    .Mui-active {
      color: #4086ff;
    }
  }

  & tbody > tr {
    font-size: 14px;
    cursor: pointer;
    & td: {
      color: #6b6a6e;
    }
    &:hover {
      background-color: #bbef1e;
    }
  }
`;

export const CustomTable = ({
  headers,
  tableContent,
  orderBy,
  order,
  sortHandler,
  paginateProps,
}: ITableProps) => {
  const isSortableTable = orderBy && order && sortHandler;
  const displayPagination =
    paginateProps?.totalCount && paginateProps?.perPage
      ? paginateProps.totalCount > paginateProps.perPage
      : false;

  const handleChangePage = (event: any, newPage: number) => {
    if (paginateProps?.paginateHandler && paginateProps?.perPage) {
      paginateProps.paginateHandler(newPage, paginateProps.perPage);
    }
  };

  const handleChangeRowsPerPage = (event: any) => {
    if (!!paginateProps?.paginateHandler) {
      paginateProps.paginateHandler(
        paginateProps.page,
        parseInt(event.target.value, 10)
      );
    }
  };

  const onSortClick = (orderBy: string | undefined, order: "asc" | "desc") => {
    if (sortHandler && orderBy) {
      sortHandler(orderBy, order);
    }
  };
  const renderTableHeaders = headers.map((header, i) => (
    <React.Fragment key={`table-head${i}`}>
      {isSortableTable && !!header.orderBy && orderBy ? (
        <TableCell
          align={header.alignment}
          className="header"
          sortDirection={orderBy === header.orderBy ? order : false}
        >
          <TableSortLabel
            active={orderBy === header.orderBy}
            direction={orderBy === header.orderBy ? order : "asc"}
            onClick={() => onSortClick(header.orderBy, order)}
          >
            {header.title}
            {orderBy === header.orderBy ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ) : (
        <TableCell className="header">{header.title}</TableCell>
      )}
    </React.Fragment>
  ));

  return (
    <StyledTable>
      <TableHead>
        <TableRow>{renderTableHeaders}</TableRow>
      </TableHead>
      <TableBody className="tbody">{tableContent}</TableBody>
      <TableFooter>
        <TableRow>
          {displayPagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25]}
              colSpan={headers.length}
              count={paginateProps?.totalCount || 0}
              rowsPerPage={paginateProps?.perPage || 0}
              page={paginateProps?.page || 0}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          )}
        </TableRow>
      </TableFooter>
    </StyledTable>
  );
};
