import API from '../API/config'
import { IPayload } from '../types/storeTypes'

export interface QueryParams {
  [key: string]: string | undefined;
}

export const getRoles = () => {
    return API.get('/roles')
}

export class RoleService {
  get = async (urlParams?: QueryParams) => {
    const options = {
      method: "GET",
    }
    const resp: IPayload = await API.get('/roles', { params: urlParams} )
    return resp.data;
  }
}
