import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'

export const PopupTypography = styled(Typography)`
    padding: 10px 15px;
    display: block;
    background-color: rgb(52, 51, 58);
    color: rgb(210, 209, 215);
    font-weight: 400;
`

export const BillingPaper = styled(Paper)`
    border-radius: 10px;
    border: 1px solid #707070;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    margin: 0 auto;

    min-width: 433px;
    width: 100%;
    box-shadow: none;

    font: normal normal normal 0.9em/35px Roboto;
    @media (max-width: 1024px) {
        min-width: 400px;
    }
`

export const ContentWrapper = styled.div`
    padding: 10px 10px 0 10px;
    height: 100%;
    & form {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    & hr {
        width: 100%;
    }
`

export const Title = styled.span`
    font-weight: normal;
    color: #6B6A6E;
    letter-spacing: 0.72px;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;

    & > div > svg {
        cursor: pointer;
        opacity: 0.3;
         &:hover {
             opacity: 0.8;
         }
    }
`

export const Container = styled.div`
    height: 100vh;
`

export const StyledLabel = styled.label`
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #6B6A6E;
`

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    font: normal normal normal 14px/22px Roboto;
    letter-spacing: 0.28px;
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    & label, & label.Mui-focused {
        margin-top: 5px;
        color: rgba(107, 106, 110, 0.5);
        font-size: 0.8rem;
        font-weight: normal;
        letter-spacing: 0.28px;
        .help-box {
            margin-left: 5px;
            display: inline-flex;
        }
    }
`

export const StyledSelect = styled(Select)`
    text-align: left;
    min-width: 65%;

    &.MuiOutlinedInput-root {
        em {
            letter-spacing: 0.28px;
            opacity: 0.4;
            text-align: left;
            font-style: normal;
        }

        > div {
            color: #6B6A6E;
            font-size: 14px;
        }

        & fieldset {
            border-width: 0px;
        }
        &.Mui-focused {
            & fieldset {
                border-width: 0px;
            }
        }
    }
`

export const StyledMenuItem = styled(MenuItem)`
  min-width: 60%;
  text-align: left;
  background-color: #F0F2F5;
`

export const StyledAutocomplete = styled(Autocomplete)`
    min-width: 65%;
`

export const StyledFormControl = styled(FormControl)`
  min-width: 65%;
  text-align: left;
  background-color: #F0F2F5;
  border-radius: 4px;
  .StripeElement {
    font-family: roboto;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: #6B6A6E;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;


    padding: 8.5px 14px;

    font-family: roboto;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: #6B6A6E;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
  }
`
