import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'

import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'

import Card from '../../../components/Card/Card'
import Button from '../../../components/Button/Button'
import { PhoneNumberField } from '../../../components/PhoneNumberInput/PhoneNumberInput'
import Modal from '../../../components/Modal/Modal'

import { useAppContext } from '../../../context/AppContext'
import { IAccount, AccountAttributes, AccountRelationships } from "../../../stores/AccountStore";

import { businessTypes } from "../../../types/businessTypes"
import { ItemOption } from '../../../types/types'
import { industries } from '../../../types/businesIndustries'
import { registrationIdentifiers } from '../../../types/registrationIdentifiers'
import { countries, usaStates, canadianProvinces } from '../../../types/countriesAndStates'

import { observer } from "mobx-react";
import { makeObservable, runInAction, observable, computed, action } from 'mobx';
import capitalize from 'lodash/capitalize'
import { capitalizeInput } from '../../../utils/InputUtils'

import { StyledTextField, StyledSelect, Row, StyledMenuItem, StyledAutocomplete, ButtonRow, RelativeForm, PencilIconWraper, PencilIcon } from '../BusinessInformation/styledComponents'
import { Container } from '../StyledComponents'

import { Content, StyledTypography, SectionName, StyledLink } from './styledComponents'


interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

export interface IBusinessInformationForm {
    name: string
    contactFirstName: string
    contactLastName: string
    contactEmail: string
    contactTitle: string
    billingEmail: string
    addressMainStreet: string
    addressSecondaryStreet: string
    addressCity: string
    addressState: string
    addressZip: string
    addressCountry: string
    phoneNumber: string
    websiteUrl: string
    businessType: string
    industry: string
    businessIdNumber: string
    businessPhoneNumber: string
    businessEmail: string
    registrationIdentifier: string
    socialMediaUrl: string
}

class BusinessSetupStore {
    @observable
    account = {} as IAccount
    @observable
    isLoaded = false

    constructor() {
        makeObservable(this);
    }

    saveAccount = async (attributes: AccountAttributes) => {
        runInAction(() => {
            this.isLoaded = false
        })
        await this.account.update(attributes, {} as AccountRelationships)
        runInAction(() => {
            this.account = this.account.store?.account
            this.isLoaded = true
        })
    }

    @action
    setAccount = (account: IAccount) => {
        this.account = account
        this.isLoaded = true
    }

    @action
    setCountry = (country: string) => {
        this.account.attributes.address_country = country
    }

    @computed
    get states() {
        return this.account.addressCountry === "us" ? usaStates : canadianProvinces;
    }

    @computed
    get defaultValues() {
        if (!this.isLoaded) {
            return {}
        }

        return {
            name: this.account.name||'',
            contactFirstName: this.account.contactFirstName||'',
            contactLastName: this.account.contactLastName||'',
            contactEmail: this.account.contactEmail||'',
            contactTitle: this.account.contactTitle||'',
            billingEmail: this.account.billingEmail||'',
            phoneNumber: this.account.phoneNumberFormatted||'',
            businessPhoneNumber: this.account.businessPhoneNumberFormatted||'',
            addressMainStreet: this.account.addressMainStreet||'',
            addressSecondaryStreet: this.account.addressSecondaryStreet||'',
            addressCity: this.account.addressCity||'',
            addressState: this.account.addressState||'',
            addressZip: this.account.addressZip||'',
            addressCountry: this.account.addressCountry||'',
            websiteUrl: this.account.websiteUrl||'',
            businessType: this.account.businessType||'',
            industry: '',
            businessEmail: this.account.businessEmail||'',
            businessIdNumber: this.account.businessIdNumber||'',
            registrationIdentifier: this.account.registrationIdentifier||'',
            socialMediaUrl: this.account.socialMediaUrl||''
        }
    }
}


const BusinessSetup = () => {
    let navigate = useNavigate()
    const [store] = useState<BusinessSetupStore>(new BusinessSetupStore())
    const { currentUser, refreshCurrentUser } = useAppContext()
    const [_snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
    const [_message, setMessage] = useState('')
    const [showModal, setShowModal] = useState(false)

    const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setMessage(message)
        setSnackbarProps({ severity, open: true })
    }

    const closeModal = () => {
        setShowModal(show => !show)
        navigate('/dashboard')
    }

    const onSubmitOrganizationDeatils = async (values: IBusinessInformationForm) => {
        try {
            const attributes = {
                id: store.account.id,
                name: values.name,
                contact_first_name: values.contactFirstName,
                contact_last_name: values.contactLastName,
                contact_email: values.contactEmail,
                contact_title: values.contactTitle,
                billing_email: values.billingEmail,
                address_main_street: values.addressMainStreet,
                address_secondary_street: values.addressSecondaryStreet,
                address_city: values.addressCity,
                address_state: values.addressState,
                address_zip: values.addressZip,
                address_country: values.addressCountry,
                phone_number: values.phoneNumber.replace(/[()-\s]/g, '').replace(/_/g, ''),
                business_phone_number: values.businessPhoneNumber.replace(/[()-\s]/g, '').replace(/_/g, ''),
                business_type: values.businessType,
                industry: values.industry||store.account.industry,
                business_email: values.businessEmail,
                registration_identifier: values.registrationIdentifier,
                business_id_number: values.businessIdNumber,
                social_media_url: values.socialMediaUrl,
                verification_status: 'submitted'
            } as AccountAttributes
            await store.saveAccount(attributes)
            await refreshCurrentUser()
            setShowModal(true)
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    useEffect(() => {
        if (currentUser) {
            if (currentUser?.isAdmin) {
                if (currentUser.account) store.setAccount(currentUser.account)
            } else {
                navigate('/dashboard', { replace: true })
            }
        }
    }, [currentUser?.isAdmin])

    const successModal = (
        <Modal
            open={showModal}
            title="Business Information was submitted"
            maxWidth='xs'
            fullWidth
            onClose={closeModal}
        >
            <StyledTypography
                variant="subtitle2"
                sx={{
                    borderTop: '1px solid rgba(107, 106, 110, 0.3)',
                    paddingTop: '15px'
                }}
            >
                We'll update your business verification status as we get a response. Please select your AutoPylot number on the following page.
            </StyledTypography>
            <Button type='button' onClick={closeModal}>Ok</Button>
        </Modal>
    )

    return (
        <Container>
            {successModal}
            <Content>
                <div className='left-section'>
                    <Card title='Business information' titleAlign='left'>
                        {!store.isLoaded ? <CircularProgress /> : <BusinessSetupFrom businessStore={store} onSubmit={onSubmitOrganizationDeatils}/>}
                    </Card>
                </div>
                <div className='right-section'>
                    <Card title='Tell us more about your business' titleAlign='left'>
                        <>
                            <StyledTypography align='left'>
                                Thank you for signing up for AutoPylot! You are almost ready to start using it. There are a few more pieces of information we need about your company.
                            </StyledTypography>
                            <StyledTypography align='left'>
                                FCC and the telecom industry are trying hard to curb spam calls and text messages. To accomplish this goal, there is a need to validate our customer’s business identity.
                            </StyledTypography>
                            <StyledTypography align='left'>
                                The FCC has published new rules 
                                (<StyledLink href='https://www.fcc.gov/call-authentication' target={'_blank'}>STIR/SHAKEN</StyledLink>) 
                                to ensure businesses receiving phone numbers are verified.
                            </StyledTypography>
                            <StyledTypography align='left'>
                                When a business is verified, the phone numbers provisioned in that business's AutoPylot account are trusted and, as such, would significantly reduce the possibility that calls or texts to and from such numbers would be labeled as potential spam.
                            </StyledTypography>
                            <StyledTypography align='left'>
                                Your accounting or finance department can provide your company’s Employer Identification Number (EIN) or Dun & Bradstreet Number (DUNS), along with industry and company type. But if you don’t have the information handy, you can complete the sign-up process and add the information later in the AutoPylot Portal under Business Details.
                            </StyledTypography>
                            <StyledTypography align='left' fontWeight={500}>
                                We strongly recommend you enter any information you know, related to Business Type, Industry, Registration ID and Business ID, now and to update, as soon as possible, any missing information!
                            </StyledTypography>
                            <StyledTypography align='left'>
                                Thank you.
                            </StyledTypography>
                            <StyledTypography align='left'>
                                Your AutoPylot team
                            </StyledTypography>
                        </>
                    </Card>
                </div>
            </Content>
        </Container>
    )
}

export default observer(BusinessSetup)

interface IBusinessSetupFrom {
    businessStore: BusinessSetupStore
    onSubmit: (values: IBusinessInformationForm) => void
}

const BusinessSetupFrom = observer(({businessStore, onSubmit}: IBusinessSetupFrom) => {
    const state = businessStore.states.find((item: ItemOption) => item.label === businessStore.account.addressState)
    const [businessIdPlaceholder, setBusinessIdPlaceholder] = useState('EIN or DUNS number')
    const { trigger, register, handleSubmit, setValue, getValues, formState: { errors, isValid }, control, reset } = useForm<IBusinessInformationForm>({defaultValues: businessStore.defaultValues, mode: 'onChange'})

    const onPencilClick = () => {

    }

    useEffect(() => {
        trigger();
    }, []);


    return (
        <RelativeForm onSubmit={handleSubmit(onSubmit)}>
            <PencilIconWraper onClick={(e) => onPencilClick()}>
                <PencilIcon />
            </PencilIconWraper>

            <Grid container sx={{padding: '15px', border: '1px solid rgba(107, 106, 110, 0.4)'}}>
                <Grid item xs={12} md={6} sx={{ padding: '0 20px 0 0' }}>
                    <Row>
                        <label>Business Legal Name</label>
                        <StyledTextField
                            {...register('name', { required: 'Required' })}
                            onKeyUp={(e) => { capitalizeInput(e, 'name', setValue) }}
                            variant='outlined' size='small'
                            error={!!errors?.name}
                            helperText={errors?.name?.message}
                        />
                    </Row>
                    <Row>
                        <label>Business Type</label>
                        <FormControl>
                            <Controller
                                name="businessType"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <StyledTextField
                                        {...register('businessType')}
                                        select
                                        defaultValue={businessStore.account.businessType}
                                        variant='outlined' size='small'
                                        error={!getValues("businessType")}
                                    >
                                    {businessTypes.map((option: ItemOption) => (
                                        <StyledMenuItem key={option.key} value={option.key}>
                                            {option.label}
                                        </StyledMenuItem>
                                    ))}
                                    </StyledTextField>
                                )}
                            />
                            {!!errors?.businessType && <FormHelperText error>{!!errors?.businessType?.message ? errors?.businessType?.message : 'Required'}</FormHelperText>}
                        </FormControl>
                    </Row>
                    <Row>
                        <label>Registration Identifier</label>
                        <FormControl>
                            <Controller
                                name="registrationIdentifier"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <StyledSelect
                                        {...register('registrationIdentifier')}
                                        defaultValue={businessStore.account.registrationIdentifier||''}
                                        displayEmpty
                                        variant='outlined' size='small'
                                        error={!getValues('registrationIdentifier')}
                                        onChange={(event) => {
                                            const value = event.target.value
                                            const selected = registrationIdentifiers.find((item: ItemOption) => {
                                                return item.key === value;
                                            })
                                            setBusinessIdPlaceholder(selected?.label ?? 'EIN or DUNS number')
                                            onChange(event);
                                        }}
                                        renderValue={(value: any) => {
                                            if (!value || value.length === 0) {
                                                return <em>Select Identifier</em>;
                                            }

                                            const selected = registrationIdentifiers.find((item: ItemOption) => {
                                                return item.key === value;
                                            })

                                            return selected?.label ?? value;
                                        }}
                                    >
                                        {registrationIdentifiers.map((option: ItemOption) => (
                                            <StyledMenuItem key={option.key} value={option.key}>
                                                {option.label}
                                            </StyledMenuItem>
                                        ))}
                                    </StyledSelect>
                                )}
                            />
                            {!!errors?.registrationIdentifier && <FormHelperText error>{!!errors?.registrationIdentifier?.message ? errors?.registrationIdentifier?.message : 'Required'}</FormHelperText>}
                        </FormControl>
                    </Row>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: '0 10px 0 10px' }}>
                    <Row>
                        <label>Region of Operation</label>
                        <Controller
                            name="addressCountry"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <StyledSelect
                                    {...register('addressCountry')}
                                    defaultValue={businessStore.account.addressCountry}
                                    variant='outlined' size='small'
                                    error={!!errors?.addressCountry}
                                    renderValue={(value: any) => {
                                        const selected = countries.find((item: ItemOption) => {
                                            return item.key === value;
                                        })

                                        return selected?.label ?? value;
                                    }}
                                >
                                {countries.map((option: ItemOption) => (
                                    <StyledMenuItem key={option.key} value={option.key}>
                                        {option.label}
                                    </StyledMenuItem>
                                ))}
                                </StyledSelect>
                            )}
                        />
                    </Row>
                    <Row>
                        <label>Industry</label>
                        <FormControl>
                            <Controller
                                name="industry"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <StyledSelect
                                        {...register('industry')}
                                        variant='outlined' size='small'
                                        error={!getValues("industry")}
                                        renderValue={(value: any) => {
                                            if (value.length === 0) {
                                                return <em>Select Industry</em>;
                                            }

                                            const selected = industries.find((item: ItemOption) => {
                                                return item.key === value;
                                            })

                                            return selected?.label ?? 'Select Industry';
                                        }}
                                    >
                                    {industries.map((option: ItemOption) => (
                                        <StyledMenuItem key={option.key} value={option.key}>
                                            {option.label}
                                        </StyledMenuItem>
                                    ))}
                                    </StyledSelect>
                                )}
                            />
                            {!!errors?.industry && <FormHelperText error>{!!errors?.industry?.message ? errors?.industry?.message : 'Required'}</FormHelperText>}
                        </FormControl>
                    </Row>
                    <Row>
                        <label>Business ID</label>
                        <StyledTextField
                            {...register('businessIdNumber')}
                            placeholder={businessIdPlaceholder}
                            variant='outlined' size='small'
                            error={!getValues("businessIdNumber")}
                        />
                    </Row>
                </Grid>
                <Typography color={'#ff5630'}>Important - please read the text to the right and fill in data in 4 empty boxes above!</Typography>
            </Grid>

            <SectionName align="left">Business Address</SectionName>
            <Grid container sx={{padding: '15px', border: '1px solid rgba(107, 106, 110, 0.4)'}}>
                <Grid item xs={12} md={6} sx={{ padding: '0 20px 0 0' }}>
                    <Row>
                        <label>Address 1</label>
                        <StyledTextField
                            {...register('addressMainStreet', { required: 'Required' })}
                            onKeyUp={(e) => { capitalizeInput(e, 'addressMainStreet', setValue) }}
                            variant='outlined' size='small'
                            error={!!errors?.addressMainStreet}
                            helperText={errors?.addressMainStreet?.message}
                        />
                    </Row>
                    <Row>
                        <label>Address 2</label>
                        <StyledTextField
                            {...register('addressSecondaryStreet')}
                            onKeyUp={(e) => { capitalizeInput(e, 'addressSecondaryStreet', setValue) }}
                            variant='outlined' size='small'
                            error={!!errors?.addressSecondaryStreet}
                            helperText={errors?.addressSecondaryStreet?.message}
                        />
                    </Row>
                    <Row>
                        <label>City</label>
                        <StyledTextField
                            {...register('addressCity', { required: 'Required' })}
                            onKeyUp={(e) => { capitalizeInput(e, 'addressCity', setValue) }}
                            variant='outlined' size='small'
                            error={!!errors?.addressCity}
                            helperText={errors?.addressCity?.message}
                        />
                    </Row>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: '0 10px 0 10px' }}>
                    <Row>
                        <label>State</label>
                        <Controller
                            name="addressState"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <StyledAutocomplete
                                    options={businessStore.states}
                                    autoHighlight
                                    getOptionLabel={(option: ItemOption | any) => option.label }
                                    noOptionsText='Select Country'
                                    defaultValue={state}
                                    onChange={(event: React.SyntheticEvent<Element, Event>) => onChange(event)}
                                    renderInput={(params) => (
                                        <StyledTextField
                                            {...params}
                                            {...register('addressState', { required: 'Required' })}
                                            name="addressState"
                                            autoComplete="off"
                                            variant='outlined' size='small'
                                            error={!!errors?.addressState}
                                            helperText={errors?.addressState?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Row>
                    <Row>
                        <label>Zip</label>
                        <StyledTextField
                            {...register('addressZip', { required: 'Required' })}
                            variant='outlined' size='small'
                            error={!!errors?.addressZip}
                            helperText={errors?.addressZip?.message}
                        />
                    </Row>
                    <Row>
                        <label>Country</label>
                        <Controller
                            name="addressCountry"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <StyledSelect
                                    {...register('addressCountry')}
                                    defaultValue={businessStore.account.addressCountry}
                                    onChange={(e) => businessStore.setCountry(e.target.value as string)}
                                    variant='outlined' size='small'
                                    error={!!errors?.addressCountry}
                                    renderValue={(value: any) => {
                                        if (value.length === 0) {
                                            return <em>Select Country</em>;
                                        }

                                        const selected = countries.find((item: ItemOption) => {
                                            return item.key === value;
                                        })

                                        return selected?.label ?? 'Select Country';
                                    }}
                                >
                                {countries.map((option: ItemOption) => (
                                    <StyledMenuItem key={option.key} value={option.key}>
                                        {option.label}
                                    </StyledMenuItem>
                                ))}
                                </StyledSelect>
                            )}
                        />
                    </Row>
                </Grid>
            </Grid>

            <SectionName align="left">Business Contact</SectionName>
            <Grid container sx={{padding: '15px', border: '1px solid rgba(107, 106, 110, 0.4)'}}>
                <Grid item xs={12} md={6} sx={{ padding: '0 20px 0 0' }}>
                    <Row>
                        <label>First Name</label>
                        <StyledTextField
                            {...register('contactFirstName', { required: 'Required' })}
                            onKeyUp={(e) => { capitalizeInput(e, 'contactFirstName', setValue) }}
                            variant='outlined' size='small'
                            error={!!errors?.contactFirstName}
                            helperText={errors?.contactFirstName?.message}
                        />
                    </Row>
                    <Row>
                        <label>Last Name</label>
                        <StyledTextField
                            {...register('contactLastName', { required: 'Required' })}
                            onKeyUp={(e) => { capitalizeInput(e, 'contactLastName', setValue) }}
                            variant='outlined' size='small'
                            error={!!errors?.contactLastName}
                            helperText={errors?.contactLastName?.message}
                        />
                    </Row>
                    <Row>
                        <label>Title</label>
                        <StyledTextField
                            {...register('contactTitle', { required: 'Required' })}
                            onKeyUp={(e) => { capitalizeInput(e, 'contactTitle', setValue) }}
                            variant='outlined' size='small'
                            error={!!errors?.contactTitle}
                            helperText={errors?.contactTitle?.message}
                        />
                    </Row>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: '0 10px 0 10px' }}>
                    <Row>
                        <label>E-mail</label>
                        <StyledTextField
                            {...register('contactEmail', { required: 'Required' })}
                            variant='outlined' size='small'
                            error={!!errors?.contactEmail}
                            helperText={errors?.contactEmail?.message}
                        />
                    </Row>
                    <Row>
                        <label>Contact Number</label>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            rules={{ required: 'Required', validate: (value) => {  return isValidPhoneNumber(`+1${value}`)||'Invalid Phone Number' } }}
                            render={({ field }) => (
                                <PhoneNumberField
                                    {...register('phoneNumber', { required: 'Required', validate: (value) => { return isValidPhoneNumber(value.match(/^\+1*/) ? value : `+1${value}`)||'Invalid Phone Number' }  })}
                                    name={field.name}
                                    size="small"
                                    sx={{minWidth: '60%', ['.MuiOutlinedInput-root input']: {backgroundColor: '#fff'}, ['.MuiOutlinedInput-root fieldset']: {borderWidth: '1px'}, ['.MuiOutlinedInput-root.Mui-focused fieldset']: {borderWidth: '1px', borderColor: '#54ABD9'} }}
                                    error={!!errors?.phoneNumber}
                                    helperText={errors?.phoneNumber?.message}
                                />
                            )}
                        />
                    </Row>
                    <Row>
                        <label>Business Phone Number</label>
                        <Controller
                            name="businessPhoneNumber"
                            control={control}
                            rules={{ required: 'Required', validate: (value) => {  return isValidPhoneNumber(`+1${value}`)||'Invalid Phone Number' } }}
                            render={({ field: { name, onChange } }) => (
                                <PhoneNumberField
                                    {...register('businessPhoneNumber', { required: 'Required', validate: (value) => {  return isValidPhoneNumber(`+1${value}`)||'Invalid Phone Number' }  })}
                                    sx={{minWidth: '60%', ['.MuiOutlinedInput-root input']: {backgroundColor: '#fff'}, ['.MuiOutlinedInput-root fieldset']: {borderWidth: '1px'}, ['.MuiOutlinedInput-root.Mui-focused fieldset']: {borderWidth: '1px', borderColor: '#54ABD9'} }}
                                    size='small'
                                    error={!!errors?.businessPhoneNumber}
                                    helperText={errors?.businessPhoneNumber?.message}
                                />
                            )}
                        />
                    </Row>
                </Grid>
            </Grid>
            <ButtonRow>
                <Button type='submit' disabled={!isValid}>Save</Button>
            </ButtonRow>
        </RelativeForm>
    )
})