import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { SnackbarProps } from '@mui/material/Snackbar'

export interface CustomSnackbarProps extends SnackbarProps {
    severity: 'success' | 'error' | 'info' | 'warning' | undefined
    message: string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = (props: CustomSnackbarProps): JSX.Element => {
    const { open, severity, message, autoHideDuration, onClose } = props
    return (
        <Snackbar {...{ open, autoHideDuration, onClose }}>
            <Alert {...{ severity }}>{message}</Alert>
        </Snackbar>
    )
}

export default CustomSnackbar