import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    font: normal normal normal 14px/22px Roboto;
    letter-spacing: 0.28px;
`

export const ButtonRow = styled.div`
    margin-top: 20px;
    text-align: center;

    & > span > svg {
        color: #EF681E;
    }
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    & label {
        margin-top: 5px;
        color: rgba(107, 106, 110, 0.5);
        font-size: 0.8rem;
        letter-spacing: 0.28px;
    }
`
