import { makeObservable, runInAction, observable, computed } from 'mobx';
import { PromoCodeService, QueryParams, createPromoCode, updatePromoCode } from '../services/promoCodes'
import { Model, IModel, IMeta, IAttributes, IPayload, HasOneRelationship } from '../types/storeTypes'
import { Plan } from './AccountStore'

import moment from 'moment'

export class PromoCodeStore {
    promoCodeService: any;
    constructor(){
        this.promoCodeService = new PromoCodeService();
        makeObservable(this);
    }
    @observable
    promoCodes: Array<PromoCode> = []
    @observable
    meta = {} as IMeta;
    @observable
    status = "initial";
    searchQuery = "";

    getPromoCodesAsync = async (params: QueryParams) => {
        try {
           // const urlParams = new URLSearchParams(Object.entries(params));
           const data = await this.promoCodeService.get(params)

           runInAction(() => {
               this.promoCodes = []

               this.meta.pageCount = data.meta.page_count;
               this.meta.recordCount = data.meta.record_count;

               data.data.forEach((json: any) => {
                   let payload = {data: json, included: data.included as IModel[]} as IPayload;

                   let text = new PromoCode(this, payload)

                   this.promoCodes.push(text)
               })
               this.status = "loaded";
               console.log(this.status);
           });
        } catch (error) {
           runInAction(() => {
               this.status = "error";
           });
        }
    };

    createPromoCodeAsync = async (payload: IPayload) => {
        try {
             const accountResponse = await createPromoCode(payload)

             runInAction(() => {
                new PromoCode(this, accountResponse.data)
                this.status = "loaded";
             });
        } catch (error) {
             runInAction(() => {
                 this.status = "error";
             });
             throw error
        }
    }

    updatePromoCodeAsync = async (id: number, payload: IPayload) => {
        try {
             const accountResponse = await updatePromoCode(id, payload)

             runInAction(() => {
                new PromoCode(this, accountResponse.data)
                this.status = "loaded";
             });
        } catch (error) {
             runInAction(() => {
                 this.status = "error";
             });
             throw error
        }
    }

    @computed
    get isLoaded() {
        return this.status === 'loaded'
    }
}


export interface PromoCodeAttributes extends IAttributes {
    start_date: string;
    end_date: string;
    percent_off: string;
    name: string;
    coupon_id: string;
}

export interface PromoCodeRelationships {
    plan: HasOneRelationship;
}

export interface IPromoCode extends IModel {};

// Domain object PromoCode.
export class PromoCode extends Model {
    attributes: PromoCodeAttributes
    relationships: PromoCodeRelationships

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
        this.relationships = payload.data.relationships
    }

    @computed
    get startDate() {
        return moment(this.attributes.start_date);
    }

    @computed
    get endDate() {
        return moment(this.attributes.end_date);
    }

    @computed
    get name() {
        return this.attributes.name;
    }

    @computed
    get percentOff() {
        return parseInt(this.attributes.percent_off, 10);
    }

    @computed
    get couponId() {
        return this.attributes.coupon_id;
    }

    @computed
    get plan() {
        if (!this.relationships.plan?.data) {
          return null
        }

        let planId = this.relationships.plan.data.id;
        let planData = this.included.find((item: any) => {
            return item.type === 'plans' && item.id === planId;
        });
        const payload = {data: planData, included: this.included} as IPayload;

        if (planData) {
            let plan = new Plan(null, payload)
            return plan;
        } else {
            return null;
        }
    }
}
