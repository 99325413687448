import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export const StyledLink = styled(Link)`
    margin 40px 0;
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-weight: normal;
`

export const CongratulationsPaper = styled(Paper)`
    border-radius: 10px;
    border: 1px solid #707070;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    margin: 0 auto;

    min-width: 433px;
    width: 100%;
    box-shadow: none;

    font: normal normal normal 0.9em/35px Roboto;
    @media (max-width: 1024px) {
        min-width: 400px;
    }
`

export const ContentWrapper = styled.div`
    padding: 10px 10px 0 10px;
    height: 100%;
    & form {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    & hr {
        width: 100%;
    }
`

export const Title = styled.span`
    color: #6B6A6E;
    letter-spacing: 0.72px;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
`

export const StyledTypography = styled(Typography)`
    color: rgba(107, 106, 110, 1);
    margin-bottom: 20px;
    font: normal normal normal 14px/22px Roboto;
    letter-spacing: 0.28px;
    font-weight: 400;
    text-align: left;
    &.bold {
        font-weight: 500;
    }
`