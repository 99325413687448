import API from '../API/config'
import { IPayload } from '../types/storeTypes'

// import { IText } from '../views/Texts/Texts'

export const getTexts = () => {
    return API.get('/short_messages?include=contact')
}

interface QueryParams {
  filter: string;
  from: string;
  to: string;
}

export class TextService {
  get = async (urlParams: QueryParams) => {
    const options = {
      method: "GET",
    }
    const resp: IPayload = await API.get('/short_messages', { params: urlParams} )
    return resp.data;
  }
}
