import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import Link from '@mui/material/Link'

export const StyledLink = styled(Link)`
    margin 40px 0;
    color: #4086FF;
    text-decoration-color: #4086FF;
    letter-spacing: 0.24px;
    font-size: 0.8rem;
    font-weight: normal;

    padding: 8.5px 14px;
    cursor: pointer;
`

export const StyledMenuItem = styled(MenuItem)`
    min-width: 60%;
    text-align: left;
`

export const StyledAutocomplete = styled(Autocomplete)`
    min-width: 65%;
`

export const StyledSelect = styled(Select)`
    text-align: left;
    min-width: 65%;
    background-color: #fff;

    &.MuiOutlinedInput-root {
        em {
            letter-spacing: 0.28px;
            opacity: 0.4;
            text-align: left;
            font-style: normal;
        }

        > div {
            color: #6B6A6E;
            font-size: 14px;
        }

        & fieldset {
            border-width: 1px;
        }
        &.Mui-focused {
            & fieldset {
                border-color: #54ABD9;
                border-width: 1px;
            }
        }
    }
`

export const StyledFormControl = styled(FormControl)`
    min-width: 65%;
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
`

export const PencilIconWraper = styled(IconButton)`
    position: absolute;
    right: 0;
    top: -40px;
`

export const PencilIcon = styled(EditRoundedIcon)`
    font-size: 1rem;
    cursor: pointer;
`

export const ContactWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const ContactIconWrapper = styled.div`
    display: flex;
    margin-right: 1rem;
    svg {
        font-size: 4rem;
    }
`

export const ContactInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;

    svg {
        font-size: 0.3rem;
    }
`

export const FormFields = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
        width: 47%;
    }
`
interface StyledProps {
    align?: 'left' | 'center' | 'right'
}

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    text-align: ${({align}: StyledProps) => align ? align : 'center'};

    & label {
        font-size: 0.8rem;
        letter-spacing: 0.28px;
        color: rgba(107, 106, 110, 55%);
        text-align: left;
    }
`

export const RelativeForm = styled.form`
    position: relative;
`

export const StyledTextField = styled(TextField)`
    text-align: left;
    background-color: #F0F2F5;
    border-radius: 4px;
    & div {
      color: #6B6A6E;
      font-size: 14px;
      background-color: #fff;
    }
    & input  {
      background-color: #fff;
      border-radius: 4px;
      border-color: #F0F2F5;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border-width: 1px;
        }
        &.Mui-focused {
            & fieldset {
                border-color: #54ABD9;
                border-width: 1px;
            }
        }
    }
`