import { Component, useState } from "react";

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CachedSharpIcon from '@mui/icons-material/CachedSharp';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'

import { AccountStore, Account, SubscriptionRelationships } from '../../stores/AccountStore'
import { AppContext } from '../../context/AppContext'
import { impersonateUser } from '../../services/login'
import { getFromLocal, saveInLocal } from '../../utils/LocalStorageService'

import { CustomTable as Table, IHeader } from '../../components/Table/Table'
import { QueryParams } from '../../services/accounts'
import { makeObservable, observable, action, configure, computed, runInAction } from 'mobx';
import { observer } from "mobx-react";
import withRouter from "../../HOC/WithRouter/WithRouter";
import { ItemOption } from '../../types/types'

import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { Container, Row, ButtonRow } from './styledComponents'
import Snackbar, { CustomSnackbarProps } from '../../components/CustomSnackbar/CustomSnackbar'
import { useForm } from 'react-hook-form'
import { capitalizeInput } from '../../utils/InputUtils'
import { useAppContext } from '../../context/AppContext'
import { IPayload } from '../../types/storeTypes'
import { getAutoPylotPlans } from '../../services/signup'


export interface INewAccountForm {
    email: string
    contactFirstName: string
    contactLastName: string
}

interface NewInternalAccountFormProps {
    accountStore: AccountStore
    onSubmit: () => void
}

const NewInternalAccountForm = ({accountStore, onSubmit}: NewInternalAccountFormProps): JSX.Element => {
    const { loading, setLoading } = useAppContext()
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, message: '', severity: "success" })

    const { register, handleSubmit, setValue, formState: { errors, isValid } } = useForm<INewAccountForm>({
        mode: 'onChange',
        defaultValues: {
            email: '',
            contactFirstName: '',
            contactLastName: ''
        }
    })

    const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
        setSnackbarProps(state => ({ ...state, open: false }))
    }

    const onSubmitNewAccount = async (fields: INewAccountForm) => {
        try {
            const { data } = await getAutoPylotPlans()
            const autoPylotPlan = data.data[0]
            setLoading(true)
            const attributes = {
                contact_first_name: fields.contactFirstName,
                contact_last_name: fields.contactLastName,
                contact_email: fields.email,
                internal: true,
                connected_crm: 'dynamics'
            }
            const subscriptionRelationships = {} as SubscriptionRelationships
            subscriptionRelationships.plan = {
                data: {
                    id: autoPylotPlan?.id,
                    type: 'plans'
                }
            }

            const subscription = {
                data: {
                    type: 'subscriptions',
                    relationships: subscriptionRelationships
                }
            }
            const relationships = {
                users: {
                    data: [
                        {
                            type: 'users',
                            attributes: {
                                first_name: fields.contactFirstName,
                                last_name: fields.contactLastName,
                                email: fields.email
                            }
                        }
                    ]
                },
                subscription: subscription
            }

            const payload = {
                data: {
                    type: 'accounts',
                    attributes: attributes,
                    relationships: relationships
                }
            } as IPayload

            const account = new Account(accountStore, payload)
            await account.save()
            onSubmit()
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            setLoading(false)
            setSnackbarProps({ severity: 'error', message: 'Something went wrong' , open: true})
        }
    }

    return (
        <>
            <Snackbar open={snackbarProps.open} message={snackbarProps.message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />

            <form id="addaccount-form" onSubmit={handleSubmit(onSubmitNewAccount)}>
                <Row>
                    <label>First Name</label>
                    <TextField
                        {...register('contactFirstName', { required: 'Required Field' })}
                        onKeyUp={(e) => { capitalizeInput(e, 'contactFirstName', setValue) }}
                        variant='outlined' size='small'
                        error={!!errors?.contactFirstName}
                        helperText={errors?.contactFirstName?.message}
                    />
                </Row>
                <Row>
                    <label>Last Name</label>
                    <TextField
                        {...register('contactLastName', { required: 'Required Field' })}
                        onKeyUp={(e) => { capitalizeInput(e, 'contactLastName', setValue) }}
                        variant='outlined' size='small'
                        error={!!errors?.contactLastName}
                        helperText={errors?.contactLastName?.message}
                    />
                </Row>
                <Row>
                    <label>Email</label>
                    <TextField
                        {...register('email', { required: 'Required Field' })}
                        variant='outlined' size='small'
                        error={!!errors?.email}
                        helperText={errors?.email?.message}
                    />
                </Row>
                <ButtonRow>
                    {loading
                        ? <CircularProgress />
                        :
                        <Button type='submit' disabled={!isValid}>Submit</Button>
                    }
                </ButtonRow>
            </form>
        </>
    )
}

interface FilterBySubscriptionStatusProps {
    onChange: (value: string) => void
}

const FilterBySubscriptionStatus = (props: FilterBySubscriptionStatusProps): JSX.Element => {
    const { onChange } = props
    const defaultSelect = {key: 'none', label: 'Filter by Subscription'}
    const [selectedOption, setSelectedOption] = useState<ItemOption>(defaultSelect)
    const statuses = [
        {
            key: 'trial',
            label: 'Free Trial'
        },
        {
            key: 'paid',
            label: 'Paid'
        },
        {
            key: 'cancelled_paid',
            label: 'Cancelled-Paid'
        },
        {
            key: 'cancelled',
            label: 'Cancelled'
        }
    ]

    const handleChange = (event: any) => {
        const value = event.target.value
        const selected = statuses.find((item: ItemOption) => {
            return item.key === value;
        })
        setSelectedOption(selected ? selected : defaultSelect)
        onChange(value)
    }

    return (
        <FormControl>
            <Select
                value={selectedOption.key}
                variant='outlined' size='small'
                onChange={handleChange}
                renderValue={(value: any) => {
                    if (value.length === 0) {
                        return <em>Filter by Subscription</em>;
                    }

                    const selected = statuses.find((item: ItemOption) => {
                        return item.key === value;
                    })

                    return selected?.label ?? 'Filter by Subscription';
                }}
            >
                <MenuItem key='none' value="none">
                    <em>Filter by Subscription</em>
                </MenuItem>
                {
                    statuses.map((status: any) => {
                        return (
                            <MenuItem key={status.key} value={status.key}>{status.label}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}

interface OrderProps {
    orderBy: string
    order: 'asc' | 'desc'
}

interface PaginateProps {
    page: number
    perPage: number
}

interface FilterProps {
    [key: string]: string | undefined;
}

class AccountsStore {
    @observable
    accounts = [] as Array<Account>
    accountStore
    setLoading: (loading: boolean) => void
    @observable
    orderProps = {orderBy: 'name', order: 'asc'} as OrderProps
    @observable
    paginateProps = {page: 0, perPage: 20} as PaginateProps
    @observable
    filterProps = {'kind': undefined, 'status': undefined} as FilterProps
    tableHeaders = [
        {
            title: 'Client',
            orderBy: 'name',
            order: 'asc',
            alignment: 'left'
        },
        {
            title: 'Contact Email',
            orderBy: 'contact_email',
            alignment: 'left'
        },
        {
            title: 'Plan',
            alignment: 'left'
        },
        {
            title: 'Subscription',
            // orderBy: 'subscription.status',
            // order: 'asc',
            alignment: 'left'
        },
        {
            title: 'Age',
            alignment: 'left'
        },
        {
            title: 'Effective Date',
            alignment: 'left'
        },
        {
            title: 'Expiration',
            alignment: 'left'
        },
        {
            title: 'Percent Off',
            alignment: 'left'
        },
        {
            title: 'Actions'
        }
    ] as Array<IHeader>

    constructor() {
        this.accountStore = new AccountStore();
        this.setLoading = () => { }
        makeObservable(this);
    }

    loadData = async () => {
        this.setLoading(true)
        await this.accountStore.getAccountsAsync(this.params)
        runInAction(() => {
            this.accounts = this.accountStore.accounts
        })
        this.setLoading(false)
    }

    @action
    sortAccounts = (orderBy: string, order: 'asc' | 'desc') => {
        this.orderProps.orderBy = orderBy
        if (order === 'asc') {
            this.orderProps.order = 'desc'
        } else {
            this.orderProps.order = 'asc'
        }
        this.loadData()
    }

    @action
    paginateHandler = (number: number, size: number) => {
        this.paginateProps.page = number
        this.paginateProps.perPage = size
        this.loadData()
    }


    @action
    filterAccounts = (filter: QueryParams) => {
        console.log(filter)
        this.filterProps.kind = filter.kind?.toString()
        this.filterProps.status = filter.status?.toString()
        this.loadData()
    }

    @computed
    get params() {
        const sort = this.orderProps.order === 'asc' ? this.orderProps.orderBy : `-${this.orderProps.orderBy}`
        return {
            include: 'subscription.plan,subscription.promo_code',
            'filter[subscriptions.kind]': this.filterProps.kind,
            'filter[subscriptions.status]': this.filterProps.status,
            'page[number]': this.paginateProps.page + 1,
            'page[size]': this.paginateProps.perPage,
            sort: sort 
        } as QueryParams
    }

    @computed
    get filteredAccounts() {
        return this.accounts;
    }
}

@observer class Accounts extends Component<any, any> {
    static contextType = AppContext
    store
    constructor(props: any) {
        super(props)
        this.store = new AccountsStore();
        this.state = {
            showAccountModal: false
        }
    }

    componentDidMount() {
        const { setLoading } = this.context
        this.store.setLoading = setLoading
        this.store.loadData()
    }

    impersonateAccount = async (accountId: number | undefined) => {
        if (accountId) {
            const { setLoading } = this.context
            setLoading(true)
            const response = await impersonateUser(accountId)
            const masterToken = getFromLocal('token')
            saveInLocal('master_token', masterToken)
            saveInLocal('token', response.data.data.attributes.token)
            setLoading(false)
            this.props.router.navigate('/dashboard', { replace: true })
        }
    }

    openAddAccountModal = () => {
        this.setState({ showAccountModal: true })
    }

    closeAccountModal = () => {
        this.setState({ showAccountModal: false })
    }

    handleSubscriptionKindChange = (key: string) => {
        switch(key) {
            case 'trial':
                console.log('kind=trial&status=trial')
                this.store.filterAccounts({kind: 'trial'})
                break
            case 'paid':
                console.log('kind=paid&status=active')
                this.store.filterAccounts({kind: 'paid', status: 'active'})
                break
            case 'cancelled_paid':
                this.store.filterAccounts({kind: 'cancelled', status: 'active'})
                console.log('kind=cancelled&status=active')
                break
            case 'cancelled':
                console.log('kind=cancelled&status=inactive')
                this.store.filterAccounts({kind: 'cancelled', status: 'inactive'})
                break
            default:
                this.store.filterAccounts({})
                console.log('all')
        }
    }

    render() {
        const tableContent = this.store.filteredAccounts.map((account: Account, i) => {
            return (
                <TableRow key={i}>
                    <TableCell >
                        <Typography>{account.name}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{account.contactEmail}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{account.subscription?.plan?.name}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{account.subscription?.state}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{account.age}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{account.subscription?.createdAt?.format('MM/DD/YYYY')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{account.subscription?.expiresAt?.format('MM/DD/YYYY')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{account.subscription?.promoCode?.percentOff ? `${account.subscription?.promoCode?.percentOff} %` : ''}</Typography>
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => this.impersonateAccount(account.id)}>
                            <CachedSharpIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            )
        })

        return (
            <Container>
                <Modal
                    open={this.state.showAccountModal}
                    title="add internal account"
                    maxWidth='xs'
                    fullWidth
                    onClose={this.closeAccountModal}
                >
                    <NewInternalAccountForm accountStore={this.store.accountStore} onSubmit={this.closeAccountModal}/>
                </Modal>
                <div className='filters-wrapper'>
                    <FilterBySubscriptionStatus onChange={this.handleSubscriptionKindChange}/>
                    <Button variant='contained' onClick={this.openAddAccountModal} >Add Internal Account</Button>
                </div>
                <div className='table-wrapper'>
                    <Table 
                        orderBy={this.store.orderProps.orderBy} 
                        order={this.store.orderProps.order} 
                        sortHandler={this.store.sortAccounts} 
                        headers={this.store.tableHeaders} 
                        tableContent={tableContent}
                        paginateProps={{
                            page: this.store.paginateProps.page,
                            perPage: this.store.paginateProps.perPage,
                            totalCount: this.store.accountStore.meta.recordCount,
                            paginateHandler: this.store.paginateHandler
                        }}
                    />
                </div>
            </Container>
        )
    }
}

export default withRouter(Accounts)