import Login from "../views/Login";
import Home from "../views/Home";
import CreatePassword from "../views/CreatePassword";
import ChangePassword from "../views/ChangePassword";
import Congratulations from "../views/Congratulations";
import OrderSummary from "../views/OrderSummary";
import BillingInformation from "../views/BillingInformation";
import ContactAdmin from "../views/ContactAdmin";
import BusinessDetails from "../views/BusinessDetails";
import BusinessSetup from "../views/BusinessDetails/Setup";
import SignUp from "../views/SignUp";
import ForgotPassword from "../views/ForgotPassword";

import Agreement from "../views/Agreement";
import Audio from "../views/Audio";

import Accounts from "../views/Accounts";
import PromoCodes from "../views/PromoCodes";
import Dashboard from "../views/Dashboard";
import Users from "../views/Users/Users";
import Calls from "../views/Calls/Calls";
import Profile from "../views/Profile/Profile";
import Billing from "../views/Billing";
import AutopylotNumSetup from "../views/Profile/AutopylotNumSetup/AutopylotNumSetup";
import Settings from "../views/Settings/Settings";
import DemoSignup from "../views/DemoSignup";

import type {RouteObject} from "react-router-dom";

export interface IRouteSettings extends RouteObject {
  protected?: boolean;
  public?: boolean;
  useSidebar?: boolean;
}

const routes: IRouteSettings[] = [
  {
    path: "/accounts",
    element: <Accounts />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/discount-codes",
    element: <PromoCodes />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/",
    element: <Home />,
    public: true,
  },
  {
    path: "/login",
    element: <Login />,
    public: true,
  },
  {
    path: "/contact-admin",
    element: <ContactAdmin />,
    public: true,
  },
  {
    path: "/signup",
    element: <SignUp />,
    public: true,
  },
  {
    path: "/agreement",
    element: <Agreement />,
    public: true,
  },
  {
    path: "/audio",
    element: <Audio />,
    public: true,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    public: true,
  },
  {
    path: "/create-password",
    element: <CreatePassword />,
    protected: true,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/congratulations",
    element: <Congratulations />,
    protected: true,
  },
  {
    path: "/order-summary",
    element: <OrderSummary />,
    protected: true,
  },
  {
    path: "/billing-information",
    element: <BillingInformation />,
    protected: true,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/users",
    element: <Users />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/calls",
    element: <Calls />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/profile",
    element: <Profile />,
    protected: true,
    useSidebar: true,
    // We need to declare children routes because react router v6 does not support optional dynamic paths
    // like /profile/:userId? so we need to declare the route as a child that will render the same component
    // this way we avoid using urlParams to have a cleaner URL
    // children: [
    //     {
    //         path: ':userId',
    //     },
    // ]
  },
  {
    path: "/profile/:userId",
    element: <Profile />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/business-details",
    element: <BusinessDetails />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/business/setup",
    element: <BusinessSetup />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/billing",
    element: <Billing />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/settings",
    element: <Settings />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/profile/setup",
    element: <AutopylotNumSetup />,
    protected: true,
    useSidebar: true,
  },
  {
    path: "/demo/signup",
    element: <DemoSignup />,
    public: true,
    protected: false,
  },
  {
    path: "*",
    element: <div>PAGE NOT FOUND</div>,
  },
];

export default routes;
