import {makeObservable, runInAction, observable, computed} from "mobx";
import {NoteService} from "../services/notes";
import {
  Model,
  IModel,
  IMeta,
  IAttributes,
  IPayload,
  HasOneRelationship,
} from "../types/storeTypes";

import moment from "moment";

export class NoteStore {
  noteService: any;
  constructor() {
    this.noteService = new NoteService();
    makeObservable(this);
  }
  @observable
  notes: Array<Note> = [];
  @observable
  meta = {} as IMeta;
  status = "initial";
  searchQuery = "";

  getAllNotesAsync = async (params: any) => {
    params["page[size]"] = 50;
    await this.getNotesAsync(params);
    if (this.meta.pageCount > 1) {
      let currentPage = 1;
      while (currentPage < this.meta.pageCount) {
        currentPage++;
        params["page[number]"] = currentPage;
        await this.getNotesAsync(params, true);
      }
    }
  };

  getNotesAsync = async (params: any, keepPreviousItems?: boolean) => {
    try {
      const data = await this.noteService.get(params);

      runInAction(() => {
        if (!keepPreviousItems) {
          this.notes = [];
        }

        this.meta.pageCount = data.meta.page_count;
        this.meta.recordCount = data.meta.record_count;

        data.data.forEach((json: any) => {
          let payload = {
            data: json,
            included: data.included as IModel[],
          } as IPayload;

          let text = new Note(this, payload);

          this.notes.push(text);
        });
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
  };

  @computed
  get totalNotes() {
    return this.notes.length;
  }

  @computed
  get groupedCountByDate() {
    return this.notes.reduce((hash: any, note) => {
      let day = moment(note.createdAt).format("YYYY-MM-DD");

      if (!hash[day]) {
        hash[day] = 0;
      }

      hash[day] += 1;

      return hash;
    }, {});
  }
}

export interface NoteAttributes extends IAttributes {
  created_at: string;
  updated_at: string;
  content: string;
}

export interface INote extends IModel {}

// Domain object Note.
export class Note extends Model {
  attributes: NoteAttributes;

  constructor(store: any, payload: IPayload) {
    super(store, payload);
    this.attributes = payload.data.attributes;
  }

  @computed
  get createdAt() {
    return this.attributes.created_at;
  }

  @computed
  get updatedAt() {
    return this.attributes.updated_at;
  }

  @computed
  get content() {
    return this.attributes.content;
  }
}
