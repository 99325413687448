import { observer } from "mobx-react";
import { useForm, Controller } from 'react-hook-form'
import { User } from "../../stores/UserStore";
import { updateDidAllocation } from '../../services/didAllocation'

import FormControl from '@mui/material/FormControl';
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import { UserSelector } from '../../components/UserSelector/UserSelector'

import {
    ButtonRow,
    StyledTypography
} from './StyledComponents'


interface IDisableMobileAppProps {
    users: Array<User>
    user: User,
    showModal: boolean
    onClose: () => void
    afterDisable: () => void
}

export type IDisableMobileApp = {
    userId: number | null
}

export const DisableMobileAppModal = observer(({ users, user, showModal, onClose, afterDisable }: IDisableMobileAppProps) => {
    const { watch, setValue, handleSubmit, control, formState: { errors } } = useForm<IDisableMobileApp>({
        mode: 'onChange',
        defaultValues: {
            userId: null
        }
    })
    const userId = watch('userId')
    const onDisableMobileApp = async (values: IDisableMobileApp) => {
        const selectedUser = users.find((u: User) => u.id === values.userId )
        user.didAllocations.forEach(async (didAllocation: any) => {
          const did = didAllocation.did
          await updateDidAllocation(didAllocation.id, {status: 'inactive'})
  
          if (selectedUser) await selectedUser.assignAutoPylotNumber(did.attributes.phone_number)
  
        })
        await user.update({status: 'inactive'})
        afterDisable()
    }

    const onSelectUser = (userId: number | null) => {
        setValue('userId', userId)
    }

    return (
        <Modal
            open={showModal}
            title="Disable Mobile Appication"
            maxWidth='sm'
            fullWidth
            onClose={onClose}
        >
            <StyledTypography
                variant="subtitle2"
            >
                Please select the User to whom you want to forward calls from this User's AutoPylot number.
            </StyledTypography>

            <form onSubmit={handleSubmit(onDisableMobileApp)}>
                <FormControl sx={{width: '50%'}}>
                    <Controller
                        name="userId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <UserSelector users={users} onSelectUser={onSelectUser} errors={errors}/>
                        )}
                    />
                </FormControl>
                <ButtonRow>
                    <Button type='submit' disabled={!userId}>Save</Button>
                </ButtonRow>
            </form>
        </Modal>
    )
})