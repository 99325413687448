import {Navigate, useMatch} from "react-router-dom";

const Public = ({
  isLoggedIn,
  children,
}: {
  isLoggedIn: boolean;
  children: any;
}) => {
  const matchAudio = useMatch("/audio");
  if (matchAudio || !isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/dashboard" replace />;
  }
};

export default Public;
