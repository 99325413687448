import React, {useEffect, useState, useMemo} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {isValidPhoneNumber} from "react-phone-number-input";
import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {useForm, Controller} from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HelpIcon from "@mui/icons-material/Help";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import Button from "../../components/Button/Button";
import ScreenTitle from "../../components/ScreenHeader/ScreenHeader";
import {CustomTable as Table, IHeader} from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";
import Snackbar from "../../components/CustomSnackbar/CustomSnackbar";
import PopUp from "../../components/PopUp/PopUp";
import {PhoneNumberField} from "../../components/PhoneNumberInput/PhoneNumberInput";
import {User, DidAllocation} from "../../stores/UserStore";
import {updateDidAllocation} from "../../services/didAllocation";

import {useAppContext} from "../../context/AppContext";
import {addUser, QueryParams} from "../../services/users";
import {getCreditCard} from "../../services/accounts";
import {getGroups} from "../../services/groups";
import {DidResponse, getTwilioNumbers} from "../../services/common";
import {getError} from "../../utils/ErrorUtils";
import {
  Container,
  Row,
  ButtonRow,
  StyledFormControl,
  UsersTable,
  PopupTypography,
} from "./StyledComponents";
import AutoPylotAutocomplete from "../../components/AutopylotAutoComplete/AutopylotAutoComplete";
import {useStores} from "../../stores/rootStore";
import {currencyFormat} from "../../utils/NumberFormatService";
import {debouncedFunction} from "../../utils/Helpers";
import {capitalizeInput} from "../../utils/InputUtils";

const PER_PAGE = 20;

interface PaginateProps {
  page: number;
  perPage: number;
}

export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  userName?: string;
  status?: string;
  mobileNumber: string;
  autopylotNumber?: string;
  groups?: string;
  role?: string;
}

const tableHeaders = [
  {
    title: "Name",
    orderBy: "first_name",
    order: "asc",
    alignment: "left",
  },
  {
    title: "Role",
    orderBy: "roles.name",
    alignment: "left",
  },
  {
    title: "AP Number",
    orderBy: "ap_numbers",
    alignment: "left",
  },
  {
    title: "Mobile App Status",
    orderBy: "mobile_app_status",
    alignment: "left",
  },
  {
    title: "Console Status",
    orderBy: "console_status",
    alignment: "left",
  },
  // {
  //     title: 'CRM Status',
  //     orderBy: 'crm_status',
  //     // order: 'asc',
  //     alignment: 'left'
  // },
  // {
  //     title: 'last activity',
  //     orderBy: 'last_activity_date',
  //     order: 'asc',
  //     alignment: 'left'
  // },
  {
    title: "Actions",
  },
] as Array<IHeader>;

interface CustomSnackbarProps {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
}

interface OrderProps {
  orderBy: string;
  order: "asc" | "desc";
}

interface MultiApProps {
  didAllocations: Array<DidAllocation>;
}

interface CustomizedState {
  userID: number;
  didAllocationID: number;
}

const MultiAp = ({didAllocations}: MultiApProps) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [popUpText, setPopUpText] = useState("This is your AP #.");
  const openPopUp = Boolean(anchorElement);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    i: number
  ) => {
    setPopUpText(
      i === 0
        ? "This is your AutoPylot number"
        : "This AP number was transferred to you from another user."
    );
    setAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      {didAllocations.map((didAllocation: any, i: number) => (
        <Typography key={didAllocation.id} className="centered-item multilines">
          {didAllocation?.did?.phoneNumber}
          <div
            style={{marginLeft: "5px"}}
            onMouseEnter={(e) => handlePopoverOpen(e, i)}
            onMouseLeave={handlePopoverClose}
          >
            <HelpIcon htmlColor="#42B1F2" fontSize="small" />
          </div>
        </Typography>
      ))}
      <PopUp
        open={openPopUp}
        anchorElement={anchorElement}
        onClose={handlePopoverClose}
      >
        <Typography sx={{p: 1}}>{popUpText}</Typography>
      </PopUp>
    </>
  );
};

const Users = () => {
  const [users, setUsers] = useState([] as Array<User>);
  // it is for case when admin transfer AP number to new user
  const [user, setUser] = useState({} as User);
  const location = useLocation();
  const state = location.state as CustomizedState;
  const didAllocationID = state?.didAllocationID;
  const userID = state?.userID;

  const [searchValue, setSearchValue] = useState("");
  const [query, setQuery] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(!!didAllocationID);
  const [message, setMessage] = useState("");
  const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({
    open: false,
    severity: "success",
  });
  const [orderProps, setOrderProps] = useState<OrderProps>({
    orderBy: "first_name",
    order: "asc",
  });
  const [paginateProps, setPaginationProps] = useState<PaginateProps>({
    page: 0,
    perPage: PER_PAGE,
  });
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [autocompleteOptions, setAutoCompleteOptions] = useState<
    Array<DidResponse>
  >([]);
  const [selectedAutocompleteOption, setSelectedAutocompleteOption] = useState({
    number: "",
  });
  const [groups, setGroups] = useState([]);
  const [loadingNumbers, setLoadingNumbers] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [showUserFeesModal, setShowUserFeesModal] = useState(false);
  const [upgradesFee, setUpgradesFee] = useState(0);
  const [withSaveAndNext, setWithSaveAndNext] = useState(false);
  const [creditCard, setCreditCard] = useState({});

  const usersParams = useMemo<QueryParams>(() => {
    if (query.length) {
      return {
        include: "group,roles,did_allocations.did",
        sort: `${orderProps.order === "desc" ? "-" : ""}${orderProps.orderBy}`,
        "page[number]": paginateProps.page + 1,
        "page[size]": paginateProps.perPage,
        "filter[query]": query,
      };
    } else {
      return {
        include: "group,roles,did_allocations.did",
        sort: `${orderProps.order === "desc" ? "-" : ""}${orderProps.orderBy}`,
        "page[number]": paginateProps.page + 1,
        "page[size]": paginateProps.perPage,
      };
    }
  }, [paginateProps, orderProps, query]);

  const navigate = useNavigate();
  const {loading, setLoading, currentUser, account} = useAppContext();
  const {rolesStore, userStore} = useStores();
  const openPopUp = Boolean(anchorElement);

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    status: "",
    mobileNumber: "",
    autopylotNumber: "",
    groups: "",
    role: "",
  };

  useEffect(() => {
    getUsersList();
  }, [usersParams]);

  const searchUser = () => {
    setQuery(searchValue);
    setPaginationProps({page: 0, perPage: PER_PAGE});
  };

  const paginateHandler = (number: number, size: number) => {
    setPaginationProps({page: number, perPage: size});
  };

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
    control,
    getValues,
  } = useForm<IUser>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const getUsersList = async () => {
    try {
      setLoading(true);
      await userStore.getUsersAsync(usersParams);

      setUsers(userStore.users);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessage("Something went wrong");
      setSnackbarProps({severity: "error", open: true});
    }
  };

  const getTwilioNumbersList = async (number: string) => {
    try {
      setShowAutocomplete(true);
      setLoadingNumbers(true);
      const response = await getTwilioNumbers(number);
      setAutoCompleteOptions(response.data);
      setLoadingNumbers(false);
    } catch (error) {
      setMessage("Error retrieving Autopylot numbers");
      setSnackbarProps({severity: "error", open: true});
    }
  };

  const getGroupsList = async () => {
    try {
      const {data} = await getGroups();
      setGroups(data.data);
    } catch (error) {
      setMessage("Error retrieving groups list");
      setSnackbarProps({severity: "error", open: true});
    }
  };

  const getRolesList = async () => {
    try {
      await rolesStore.getRolesAsync();
    } catch (error) {
      setMessage("Error retrieving groups list");
      setSnackbarProps({severity: "error", open: true});
    }
  };

  const getDidAllocationForTransfer = async (
    userID: number,
    didAllocationID?: number
  ) => {
    if (!userID || !didAllocationID) {
      return;
    }

    try {
      await userStore.getUserAsync(userID);
      setUser(userStore.user);
      const didAllocation = userStore.user.transferredDidAllocations.find(
        (didAllocation: DidAllocation) => didAllocation.id === didAllocationID
      );
      setValue("autopylotNumber", didAllocation?.did?.phoneNumber || "");
      setSelectedAutocompleteOption({
        number: didAllocation?.did?.phoneNumber || "",
      });
    } catch (error) {
      setMessage("Error retrieving ap number for transfer");
      setSnackbarProps({severity: "error", open: true});
    }
  };

  const debouncedGetTwilioNumbers = debouncedFunction(getTwilioNumbersList);

  const cleanUp = () => {
    setUsers([]);
    setAutoCompleteOptions([]);
  };

  useEffect(() => {
    const getInitialData = async () => {
      getGroupsList();
      getRolesList();
      getDidAllocationForTransfer(userID, didAllocationID);
    };

    if (currentUser) {
      getInitialData();
    }

    return () => {
      cleanUp();
    };
  }, [currentUser]);

  const openAddUserModal = () => {
    setShowAddUserModal(true);
    setWithSaveAndNext(false);
    setAutoCompleteOptions([]);
    setSelectedAutocompleteOption({number: ""});
    reset();
  };
  const closeAddUserModal = () => {
    setShowAddUserModal(false);
    if (upgradesFee > 0) {
      setShowUserFeesModal(true);
    }
  };

  const gotoUserProfile = (id: number | undefined) => {
    navigate(`/profile/${id}`, {replace: true});
  };

  const onSubmitUser = async (values: IUser) => {
    try {
      setLoading(true);
      const autopylotNumber = values.autopylotNumber
        ? `+${values.autopylotNumber.replace(/\D/g, "")}`
        : "";
      const mobileNumber = values.mobileNumber
        ? values.mobileNumber.replace(/[()-\s]/g, "").replace(/_/g, "")
        : "";

      // when adding user with transfered AP number
      if (user && user.id) {
        const didAllocation = user.transferredDidAllocations.find(
          (didAllocation: DidAllocation) => didAllocation.id === didAllocationID
        );
        if (didAllocation?.id)
          await updateDidAllocation(didAllocation.id, {status: "inactive"});
      }

      const {data} = await addUser({
        ...values,
        autopylotNumber: autopylotNumber,
        mobileNumber: mobileNumber,
      });

      // if user is paid
      if (data.data?.meta?.upgrades_fee) {
        setUpgradesFee(upgradesFee + data.data.meta.upgrades_fee);
        if (!account?.manualBillingType && !creditCard) {
          const resp = await getCreditCard();
          setCreditCard(resp.data);
        }

        if (!withSaveAndNext) {
          setShowUserFeesModal(true);
        }
      }

      if (!withSaveAndNext) {
        setShowAddUserModal(false);
      }

      setSelectedAutocompleteOption({number: ""});
      reset();
      getUsersList();
      setLoading(false);
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    const errorMsg = getError(error);
    setMessage(errorMsg);
    setLoading(false);
    setSnackbarProps({severity: "error", open: true});
  };

  const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
    setSnackbarProps((state) => ({...state, open: false}));
  };

  const sortUsers = (orderBy: string, order: "asc" | "desc") => {
    setOrderProps({orderBy: orderBy, order: order});
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };

  const closeUsersFeeModal = () => {
    setShowUserFeesModal(false);
    setWithSaveAndNext(false);
    setUpgradesFee(0);
  };

  const userForm = (
    <form id="adduser-form" onSubmit={handleSubmit(onSubmitUser)}>
      <Row>
        <label>First Name</label>
        <TextField
          {...register("firstName", {required: "Required Field"})}
          onKeyUp={(e) => {
            capitalizeInput(e, "firstName", setValue);
          }}
          variant="outlined"
          size="small"
          error={!!formState.errors?.firstName}
          helperText={formState.errors?.firstName?.message}
        />
      </Row>
      <Row>
        <label>Last Name</label>
        <TextField
          {...register("lastName", {required: "Required Field"})}
          onKeyUp={(e) => {
            capitalizeInput(e, "lastName", setValue);
          }}
          variant="outlined"
          size="small"
          error={!!formState.errors?.lastName}
          helperText={formState.errors?.lastName?.message}
        />
      </Row>
      <Row>
        <label>Role</label>
        <StyledFormControl>
          <Controller
            name="role"
            control={control}
            render={() => (
              <Select
                {...register("role", {required: "Required Field"})}
                size="small"
                displayEmpty
                variant="outlined"
                inputProps={{"aria-label": "Without label"}}
                value={getValues("role")}
              >
                <MenuItem disabled value="">
                  Select Role
                </MenuItem>
                {rolesStore.roles.map((role) => (
                  <MenuItem key={`${role.id}-${role.name}`} value={role.id}>
                    {roleLabels[role.name]}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {!!formState.errors?.role && (
            <FormHelperText error style={{backgroundColor: "fff"}}>
              {!!formState.errors?.role?.message
                ? formState.errors?.role?.message
                : "Required Field"}
            </FormHelperText>
          )}
        </StyledFormControl>
      </Row>
      <Row>
        <label>Business Email</label>
        <TextField
          {...register("email", {required: "Required Field"})}
          variant="outlined"
          size="small"
          error={!!formState.errors?.email}
          helperText={formState.errors?.email?.message}
        />
      </Row>
      <Row>
        <div style={{display: "flex", alignItems: "center"}}>
          <label>AutoPylot #</label>
          <div
            style={{marginLeft: "5px"}}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <HelpIcon htmlColor="#42B1F2" fontSize="small" />
          </div>
          <PopUp
            open={openPopUp}
            anchorElement={anchorElement}
            onClose={handlePopoverClose}
          >
            <PopupTypography sx={{p: 1}}>
              Allows users to make and receive business calls and send and
              receive text messages, through AutoPylot Mobile app. Users can
              easily separate their business and personal communication. Such
              activities are automatically logged and organized in AutoPylot
              Console and Mobile app.
            </PopupTypography>
          </PopUp>
        </div>
        <Controller
          name="autopylotNumber"
          control={control}
          render={() => (
            <AutoPylotAutocomplete
              id="autopylotnums-autocomplete"
              placeholder={"Type Desired Area Code"}
              register={register("autopylotNumber")}
              open={showAutocomplete}
              onClose={() => setShowAutocomplete(false)}
              options={autocompleteOptions}
              value={selectedAutocompleteOption}
              onSelectOption={setSelectedAutocompleteOption}
              onChangeValue={debouncedGetTwilioNumbers}
              loading={loadingNumbers}
              error={!!formState.errors?.autopylotNumber}
              helperText={formState.errors?.autopylotNumber?.message}
            />
          )}
        />
      </Row>
      <Row>
        <label>Mobile Number</label>
        <StyledFormControl>
          <Controller
            name="mobileNumber"
            control={control}
            rules={{
              validate: (value) => {
                if (value) {
                  return isValidPhoneNumber(value) || "Invalid Phone Number";
                } else {
                  return true;
                }
              },
            }}
            render={({field}) => (
              <PhoneNumberField
                {...register("mobileNumber", {
                  validate: (value) => {
                    if (value) {
                      return (
                        isValidPhoneNumber(`+1${value}`) ||
                        "Invalid Phone Number"
                      );
                    } else {
                      return true;
                    }
                  },
                })}
                name={field.name}
                size="small"
                sx={{minWidth: "65%"}}
                error={!!formState.errors?.mobileNumber}
                helperText={formState.errors?.mobileNumber?.message}
              />
            )}
          />
        </StyledFormControl>
      </Row>
      <Row>
        <label>Group(s)</label>
        <Controller
          name="groups"
          control={control}
          render={() => (
            <Select
              {...register("groups")}
              size="small"
              displayEmpty
              variant="outlined"
              inputProps={{"aria-label": "Without label"}}
              value={getValues("groups")}
            >
              <MenuItem disabled value="">
                Select Group
              </MenuItem>
              {groups.map((group) => (
                <MenuItem
                  key={`group-${get(group, "id")}`}
                  value={get(group, "id")}
                >
                  {get(group, "attributes.name", "")}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Row>
      <ButtonRow>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setWithSaveAndNext(e.target.checked)}
                  checked={withSaveAndNext}
                />
              }
              label="Add more users"
            />
            <Button type="submit" disabled={!formState.isValid}>
              {account?.isInternal ? "Submit" : "Send Invite"}
            </Button>
          </>
        )}
      </ButtonRow>
    </form>
  );

  //TODO: move to utils
  const roleLabels: any = {
    admin: "AP Admin",
    sales_director: "Sales Director",
    manager: "Sales Manager",
    sales_person: "Sales Person",
  };

  const tableContent = users.map((user: User, i) => {
    let roleName = user.role?.name ? roleLabels[user.role?.name] : "";

    return (
      <TableRow key={i}>
        <TableCell onClick={() => gotoUserProfile(user.id)}>
          <Typography>{user.fullName}</Typography>
        </TableCell>
        <TableCell align="left" onClick={() => gotoUserProfile(user.id)}>
          <Typography className="centered-item">{roleName}</Typography>
        </TableCell>
        <TableCell align="left" onClick={() => gotoUserProfile(user.id)}>
          {user.orderedDidAllocations.length > 1 ? (
            <MultiAp didAllocations={user.orderedDidAllocations} />
          ) : (
            <Typography className="centered-item">
              {user.didAllocation?.did?.phoneNumber}
            </Typography>
          )}
          {/* <Typography className='centered-item'>{user.didAllocation?.did?.phoneNumber}</Typography> */}
        </TableCell>
        <TableCell align="left" onClick={() => gotoUserProfile(user.id)}>
          <Typography className="centered-item">
            {user.mobileAppStatusFormatted}
          </Typography>
        </TableCell>
        <TableCell align="left" onClick={() => gotoUserProfile(user.id)}>
          <Typography className="centered-item">
            {capitalize(user.consoleStatus)}
          </Typography>
        </TableCell>
        {/* <TableCell align='left' onClick={() => gotoUserProfile(user.id)}>
                    <Typography className='centered-item'>{capitalize(user.crmStatus)}</Typography>
                </TableCell> */}
        {/* <TableCell align='left' onClick={() => gotoUserProfile(user.id)}>
                    <Typography className='centered-item'>{user.lastActiveFormatted}</Typography>
                </TableCell> */}
        <TableCell align="left" onClick={() => gotoUserProfile(user.id)}>
          <IconButton onClick={() => gotoUserProfile(user.id)}>
            <EditRoundedIcon sx={{fontSize: "1.2rem"}} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Container>
        <div className="title-wrapper">
          <ScreenTitle
            title="Manage Users"
            buttonLabel="Add User"
            onClickButton={openAddUserModal}
            filter={
              <div
                style={{
                  background: "#F0F2F5 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  paddingLeft: "10px",
                  marginLeft: "50px",
                }}
              >
                <InputBase
                  placeholder="Search Users"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && searchUser();
                  }}
                  inputProps={{"aria-label": "search users"}}
                />
                <IconButton
                  type="button"
                  onClick={() => {
                    searchUser();
                  }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </div>
            }
          />
        </div>
        <div className="table-wrapper">
          <UsersTable
            orderBy={orderProps.orderBy}
            order={orderProps.order}
            sortHandler={sortUsers}
            headers={tableHeaders}
            tableContent={tableContent}
            paginateProps={{
              page: paginateProps.page,
              perPage: paginateProps.perPage,
              totalCount: userStore.meta.recordCount,
              paginateHandler: paginateHandler,
            }}
          />
        </div>
      </Container>
      <Modal
        open={showAddUserModal}
        title="add new user"
        maxWidth="xs"
        fullWidth
        onClose={closeAddUserModal}
      >
        {userForm}
      </Modal>
      <Modal
        title="Upgrades"
        open={showUserFeesModal}
        onClose={closeUsersFeeModal}
      >
        <Typography>
          {account?.manualBillingType
            ? `${currencyFormat(
                upgradesFee / 100.0
              )}/Month will be added to your invoice`
            : `${currencyFormat(
                upgradesFee / 100.0
              )}/Month will be charged to your credit card ending with ${get(
                creditCard,
                "data.attributes.last4"
              )}, which was used during your signup`}
        </Typography>

        <Button
          sx={{marginTop: "10px"}}
          onClick={closeUsersFeeModal}
          type="submit"
        >
          Ok
        </Button>
      </Modal>
      <Snackbar
        open={snackbarProps.open}
        message={message}
        severity={snackbarProps.severity}
        autoHideDuration={6000}
        onClose={handleClose}
      />
    </>
  );
};

export default Users;
