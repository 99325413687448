import { makeObservable, runInAction, observable, computed } from 'mobx';
import { RoleService, QueryParams } from '../services/roles'
import { Model, IModel, IMeta, IAttributes, IPayload, HasOneRelationship } from '../types/storeTypes'
import { Contact } from './ContactStore'

export class RoleStore {
    roleService: any;
    constructor(){
        this.roleService = new RoleService();
        makeObservable(this);
    }
    @observable
    roles: Array<Role> = []
    @observable
    meta = {} as IMeta;
    @observable
    status = "initial";
    searchQuery = "";

    getRolesAsync = async (params?: any) => {
        try {
           // const urlParams = new URLSearchParams(Object.entries(params));
           const data = await this.roleService.get(params)

           runInAction(() => {
               this.roles = []

               this.meta.pageCount = data.meta.page_count;
               this.meta.recordCount = data.meta.record_count;


               data.data.forEach((json: any) => {
                   let payload = {data: json, included: data.included as IModel[]} as IPayload;

                   let text = new Role(this, payload)

                   this.roles.push(text)
               })
               this.status = "loaded";
           });
        } catch (error) {
           runInAction(() => {
               this.status = "error";
           });
        }
    }
    @computed
    get isLoaded() {
        return this.status === 'loaded'
    }
}


export interface RoleAttributes extends IAttributes {
    created_at: string;
    name: string;
}

export interface IRole extends IModel {};

// Domain object Role.
export class Role extends Model {
    attributes: RoleAttributes

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
    }

    @computed
    get name() {
        return this.attributes.name;
    }
}
