import { Component } from "react";
import Select from '@mui/material/Select';
import { SelectChangeEvent } from "@mui/material";
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import SearchIcon from '@mui/icons-material/Search';
import { UserStore, User, UserAttributes } from "../../stores/UserStore";
import { IDatePeriod, datePeriods } from "../../types/timeFrames";
import { makeObservable, observable, action, configure, computed, runInAction } from 'mobx';
import { observer } from "mobx-react";
import { AppContext } from '../../context/AppContext'
import withRouter from "../../HOC/WithRouter/WithRouter";

import { max, orderBy } from 'lodash';
import moment from 'moment';
import 'moment-timezone'

import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  BarChart,
  CartesianGrid
} from "recharts";

import {
  Container,
  RechartsLegendWrapper,
  StyledResponsiveContainer,
  StyledFormControl,
  TabedGrid,
  TabData,
  TabLabel,
  ProgressLabel,
  CustomFiltersContainer
} from './styledComponents'

configure({ enforceActions: 'observed' });


interface IChartItem {
  id: number | undefined,
  title: string,
  name: string,
  email: string,
  calls_in: number,
  calls_out: number,
  texts: number,
  notes: number
}


export interface QueryParams {
  [key: string]: string | number | boolean | undefined;
}

const REFRESH_IN_SECONDS = 30;

class DashboardStore {
  @observable
  currentTab = 'all'
  @observable
  usersActivities = [] as Array<User>
  @observable
  userStoreForCurrentPeriod
  @observable
  userStoreForPreviousPeriod
  userWithActivitiesStore
  timerId: any
  setLoading: (loading: boolean) => void
  constructor() {
    this.userStoreForCurrentPeriod = new UserStore();
    this.userStoreForPreviousPeriod = new UserStore();
    this.userWithActivitiesStore = new UserStore();
    this.setLoading = () => { }
    makeObservable(this);
  }

  loadData = async (timeframe: string, fromDate?: moment.Moment, toDate?: moment.Moment) => {
    if (timeframe === 'custom') {
      if (fromDate && toDate) {
        this.clearInterval()
        this.setLoading(true)

        this.fetchWithCustomDates(fromDate, toDate)
        this.timerId = setInterval(()=>{
          this.fetchWithCustomDates(fromDate, toDate)
        }, REFRESH_IN_SECONDS * 1000)

        this.setLoading(false)
      }
    } else {
      let selectedPeriod = datePeriods.find((period: IDatePeriod) => { return period.key === timeframe; })

      if (!selectedPeriod) {
        return
      }

      this.clearInterval()
      this.setLoading(true)
      let fromDate = selectedPeriod?.from()
      let toDate = selectedPeriod?.to()
      this.fetchAndPrepareDate(fromDate, toDate)
      this.timerId = setInterval(()=>{
        this.fetchAndPrepareDate(fromDate, toDate)
      }, REFRESH_IN_SECONDS * 1000)

      this.setLoading(false)
    }
  }

  clearInterval = () => {
    console.log('clearInterval', this.timerId)
    clearInterval(this.timerId)
  }

  fetchAndPrepareDate = async (fromDate: moment.Moment, toDate: moment.Moment) => {
    let mergedUsersActivities = await this.fetchActivities(this.userStoreForCurrentPeriod, fromDate, toDate)

    runInAction(() => { this.usersActivities = mergedUsersActivities })
    let diff = toDate.diff(fromDate, 'days') + 1;
    await this.fetchActivities(this.userStoreForPreviousPeriod, fromDate.clone().subtract(diff, 'days'), toDate.clone().subtract(diff, 'days'))
  }

  fetchWithCustomDates = async (from: moment.Moment, to: moment.Moment) => {
    let mergedUsersActivities = await this.fetchActivities(this.userStoreForCurrentPeriod, from, to)
    runInAction(() => { this.usersActivities = mergedUsersActivities })
  }

  @action
  fetchActivities = async (store: UserStore, fromDate: moment.Moment, toDate: moment.Moment) => {
    let usersFilters = {
      'page[size]': 50,
      'page[number]': 1,
    } as any;
    await store.getUsersAsync(usersFilters)
    let usersActivities = store.users;

    usersFilters = {
      'filter[with_inbound_calls]': true,
      'filter[calls.from_date]': fromDate.startOf('day').format(),
      'filter[calls.to_date]': toDate.endOf('day').format(),
      'page[number]': 1,
      'page[size]': 50,
    }
    await this.userWithActivitiesStore.getUsersAsync(usersFilters)
    this.userWithActivitiesStore.users.forEach((userWithInboundCalls: User) => {
      let attrs = { inbound_calls_count: userWithInboundCalls.inboundCallsCount } as UserAttributes
      let user = store.find(userWithInboundCalls.id)
      if (user) {
        user.assignAttributes(attrs)
      }
    });

    usersFilters = {
      'filter[with_outbound_calls]': true,
      'filter[calls.from_date]': fromDate.format(),
      'filter[calls.to_date]': toDate.format(),
      'page[number]': 1,
      'page[size]': 50,
    }
    await this.userWithActivitiesStore.getUsersAsync(usersFilters)
    this.userWithActivitiesStore.users.forEach((userWithOutboundCalls: User) => {
      let attrs = { outbound_calls_count: userWithOutboundCalls.outboundCallsCount } as UserAttributes
      let user = store.find(userWithOutboundCalls.id)
      if (user) {
        user.assignAttributes(attrs)
      }
    });

    usersFilters = {
      'filter[with_short_messages]': true,
      'filter[short_messages.from_date]': fromDate.format(),
      'filter[short_messages.to_date]': toDate.format(),
      'page[number]': 1,
      'page[size]': 50,
    }

    await this.userWithActivitiesStore.getUsersAsync(usersFilters)
    this.userWithActivitiesStore.users.forEach((userWithTexts: User) => {
      let attrs = { short_messages_count: userWithTexts.shortMessagesCount } as UserAttributes
      let user = store.find(userWithTexts.id)
      if (user) {
        user.assignAttributes(attrs)
      }
    });

    usersFilters = {
      'filter[with_notes]': true,
      'filter[notes.from_date]': fromDate.format(),
      'filter[notes.to_date]': toDate.format(),
      'page[number]': 1,
      'page[size]': 50,
    }

    await this.userWithActivitiesStore.getUsersAsync(usersFilters)
    this.userWithActivitiesStore.users.forEach((userWithNotes: User) => {
      let attrs = { notes_count: userWithNotes.notesCount } as UserAttributes
      let user = store.find(userWithNotes.id)
      if (user) {
        user.assignAttributes(attrs)
      }
    });

    return usersActivities
  }

  @action
  setCurrentTab = (tab: 'all' | 'calls' | 'texts' | 'notes' | 'active_users' | 'inactive_users') => {
    this.currentTab = tab
  }

  @computed
  get chartData() {
    let data = [] as Array<IChartItem>

    for (let i = 0; i < this.usersActivities.length; i++) {
      let user = this.usersActivities[i] as User
      if (this.currentTab === 'active_users' && user.hasNoActivities) {
        continue;
      }

      if (this.currentTab === 'inactive_users' && user.hasActivities) {
        continue;
      }

      // when there is user with the same full name
      const index = data.findIndex((i) => i.name === user.fullName)
      if (index > -1) {
          data[index].title = `${data[index].name} (${data[index].email})`
      }

      let item = {
        title: index > -1 ? `${user.fullName} (${user.email})` : user.fullName,
        name: user.fullName,
        email: user.email,
        id: user.id,
        calls_in: user.inboundCallsCount,
        calls_out: user.outboundCallsCount,
        texts: user.shortMessagesCount,
        notes: user.notesCount
      } as IChartItem
      data.push(item)
    }

    return orderBy(data, [(x) =>{
      return x.calls_in+x.calls_out+x.texts+x.notes;
    }, 'name'], ['desc', 'asc']);
  }

  @computed
  get totalCalls() {
    return this.userStoreForCurrentPeriod.totalCalls
  }

  @computed
  get totalTexts() {
    return this.userStoreForCurrentPeriod.totalTexts
  }

  @computed
  get totalNotes() {
    return this.userStoreForCurrentPeriod.totalNotes
  }

  @computed
  get totalUsers() {
    return this.userStoreForCurrentPeriod.totalUsers
  }

  @computed
  get totalUsersWithActivities() {
    return this.userStoreForCurrentPeriod.usersWithActivities.length
  }

  @computed
  get totalUsersWithoutActivities() {
    return this.userStoreForCurrentPeriod.usersWithoutActivities.length
  }

  @computed
  get previousTotalUsersWithActivities() {
    return this.userStoreForPreviousPeriod.usersWithActivities.length
  }

  @computed
  get previousTotalUsersWithoutActivities() {
    return this.userStoreForPreviousPeriod.usersWithActivities.length
  }

  @computed
  get previousTotalCalls() {
    return this.userStoreForPreviousPeriod.totalCalls
  }

  @computed
  get previousTotalTexts() {
    return this.userStoreForPreviousPeriod.totalTexts
  }

  @computed
  get previousTotalNotes() {
    return this.userStoreForPreviousPeriod.totalNotes
  }

  @computed
  get activePercentDiff() {
    return this.calcPercentDiff(this.totalUsersWithActivities, this.previousTotalUsersWithActivities)
  }

  @computed
  get inactivePercentDiff() {
    return this.calcPercentDiff(this.totalUsersWithoutActivities, this.previousTotalUsersWithoutActivities)
  }

  @computed
  get callsPercentDiff() {
    return this.calcPercentDiff(this.totalCalls, this.previousTotalCalls)
  }

  @computed
  get textsPercentDiff() {
    return this.calcPercentDiff(this.totalTexts, this.previousTotalTexts)
  }

  @computed
  get notesPercentDiff() {
    return this.calcPercentDiff(this.totalNotes, this.previousTotalNotes)
  }

  calcPercentDiff = (current: number, previous: number) => {
    let percent = 0
    let diff = current - previous
    if (current > previous) {
      percent = (diff * 100) / current
    } else if (current < previous) {
      percent = (diff * 100) / previous
    }
    return percent;
  }
}

interface State {
  period: string
  to: moment.Moment
  from: moment.Moment
}

@observer class Dashboard extends Component<any, State> {
  static contextType = AppContext
  currentTab = 'all'
  store
  constructor(props: any) {
    super(props)
    this.store = new DashboardStore();
    this.state = {
      period: 'last_7_days',
      from: moment(),
      to: moment()
    }
  }

  componentDidMount() {
    const { setLoading } = this.context
    this.store.setLoading = setLoading

    this.store.loadData(this.state.period)
  }

  componentWillUnmount() {
    this.store.clearInterval()
  }

  handleChange = (event: SelectChangeEvent) => {
    // this.period = event.target.value
    const period = event.target.value
    this.setState({ period })
    this.store.loadData(period)
  }

  // handleCustomFrom = () => {
  //   const { from, to } = this.state
  //   this.store.fetchWithCustomDates(from, to)
  // }

  filtersSelect = () => (
    <StyledFormControl size='small'>
      <Select
        value={this.state.period}
        onChange={this.handleChange}
      >
        {datePeriods
          .map((period: IDatePeriod) => {
            return (
              <MenuItem key={period.key} value={period.key}>{period.label}</MenuItem>
            )
          })
        }
      </Select>
    </StyledFormControl>
  )

  renderTooltip = (props: any) => {
    if (props.active && props.payload) {
      const arr = props.payload.filter((item: any)=> item?.value && item?.value > 0)

      if (arr.length) {
        const items = arr.map((item: any, i: number) => (
          <li className="recharts-tooltip-item" key={`tooltip-item-${i}`} style={{display: 'block', paddingTop: 4, paddingBottom: 4, color: item.color || '#000'}}>
            <span className="recharts-tooltip-item-name">{item.name}</span>
            <span className="recharts-tooltip-item-separator"> : </span>
            <span className="recharts-tooltip-item-value">{item.value}</span>
          </li>
        ))

        const listStyle = {
          padding: '0',
          margin: '0'
        }
        const tooltipStyle = {
          backgroundColor: '#fff',
          border: '1px solid rgb(204, 204, 204)',
          padding: '10px',
          margin: '0'
        }

        return (
          <div className="recharts-default-tooltip" style={tooltipStyle}>
            <p className="recharts-tooltip-label" style={{padding: '0', margin: '0'}}>{props.label}</p>
            <ul className="recharts-tooltip-item-list" style={listStyle}>
              {items}
            </ul>
          </div>
        )
      }
    }

    return (
      <></>
    )
  }

  renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <>
        {this.filtersSelect()}
        <RechartsLegendWrapper>
          {
            payload.map((entry: any, index: number) => (
              <li className="recharts-legend-item" key={`item-${index}`} style={{ display: 'inline' }}>
                <svg className="recharts-surface" width="10" height="10" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
                  <rect x="0" width="32" height="32" rx="10" fill={entry.color} />
                </svg>
                <span className="recharts-legend-item-text" style={{ color: entry.color }}>{entry.value}</span>
              </li>
            ))
          }
        </RechartsLegendWrapper>
      </>
    );
  }

  selectTab = (tab: 'all' | 'calls' | 'texts' | 'notes' | 'active_users' | 'inactive_users') => {
      this.currentTab = tab
      this.store.setCurrentTab(tab)
  }

  isBarVisible = (tab: 'all' | 'calls' | 'texts' | 'notes' | 'active_users' | 'inactive_users') => {
    return ['all', 'active_users', 'inactive_users', tab].includes(this.currentTab)
  }


  gotoUser = (props: any) => {
    const user = this.store.chartData[props.index]
    this.props.router.navigate(`/profile/${user.id}`)
  }

  handleOnDatePickerChange = (value: string) => (date: moment.Moment | null) => {
    // this.store.setDate(value, date)
    this.setState({ [value]: date } as any)
  }

  filterByCustomDates = () => {
    const { from, to } = this.state
    this.store.loadData('custom', from, to);
  }


  render() {
    const data = this.store.chartData;
    if (data.length === 0) {
      return <></>
    }
    let yaxisWidth = 150
    let userNameLengths = data.map((x) => {
      if (x.name !== x.title) {
        return max([x.name.length, x.email.length + 2])
      } else {
        return x.name.length
      }
    })
    const maxLength = max(userNameLengths)
    if (maxLength) {
      yaxisWidth = maxLength*7.5
    }

    let barsHeight = 80

    let usersCount = data.length||1
    let paddings = 40
    let dateFilterHeight = 60
    let aXisHeight = 30

    let height = `${paddings + dateFilterHeight + aXisHeight + usersCount * barsHeight}px`

    return (
      <>
        <Container>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
          >
            <Grid
              container sx={{ marginTop: '30px' }} justifyContent="space-between" alignItems="flex-start"
            >
              <Grid item xs={2} sx={{ padding: '0 20px 0 0' }}>
                <TabedGrid item xs={12} className={this.store.currentTab === 'all' ? 'active all' : 'all'} onClick={() => { this.selectTab('all') }}>
                  <TabData variant="h2">
                    {this.store.totalUsers}
                  </TabData>
                  <TabLabel>
                    {this.store.totalUsers === 1 ? 'User' : 'Users'}
                  </TabLabel>
                </TabedGrid>

                <TabedGrid item xs={12} className={this.store.currentTab === 'active_users' ? 'active' : ''} onClick={() => { this.selectTab('active_users') }}>
                  <TabData variant="h2">
                    {this.store.totalUsersWithActivities}
                  </TabData>
                  <TabLabel>
                    Active
                    <ProgressLabel className={this.store.activePercentDiff > 0 ? 'increased' : (this.store.activePercentDiff < 0 ? 'decreased' : '')}>
                      {this.store.activePercentDiff > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {Math.round(Math.abs(this.store.activePercentDiff))}%
                    </ProgressLabel>
                  </TabLabel>
                </TabedGrid>

                <TabedGrid item xs={12} className={this.store.currentTab === 'inactive_users' ? 'active' : ''} onClick={() => { this.selectTab('inactive_users') }}>
                  <TabData variant="h2">
                    {this.store.totalUsersWithoutActivities}
                  </TabData>
                  <TabLabel>
                    Inactive
                    <ProgressLabel className={this.store.inactivePercentDiff > 0 ? 'increased' : (this.store.inactivePercentDiff < 0 ? 'decreased' : '')}>
                      {this.store.inactivePercentDiff > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {Math.round(Math.abs(this.store.inactivePercentDiff))}%
                    </ProgressLabel>
                  </TabLabel>
                </TabedGrid>

                <TabedGrid item xs={12} className={this.store.currentTab === 'calls' ? 'active' : ''} onClick={() => { this.selectTab('calls') }}>
                  <TabData variant="h2">
                    {this.store.totalCalls}
                  </TabData>
                  <TabLabel>
                    Calls
                    <ProgressLabel className={this.store.callsPercentDiff > 0 ? 'increased' : (this.store.callsPercentDiff < 0 ? 'decreased' : '')}>
                      {this.store.callsPercentDiff > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {Math.round(Math.abs(this.store.callsPercentDiff))}%
                    </ProgressLabel>
                  </TabLabel>
                </TabedGrid>

                <TabedGrid item xs={12} className={this.store.currentTab === 'texts' ? 'active' : ''} onClick={() => { this.selectTab('texts') }}>
                  <TabData variant="h2">
                    {this.store.totalTexts}
                  </TabData>
                  <TabLabel>
                    Texts
                    <ProgressLabel className={this.store.textsPercentDiff > 0 ? 'increased' : (this.store.textsPercentDiff < 0 ? 'decreased' : '')}>
                      {this.store.textsPercentDiff > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {Math.round(Math.abs(this.store.textsPercentDiff))}%
                    </ProgressLabel>
                  </TabLabel>
                </TabedGrid>

                <TabedGrid item xs={12} className={this.store.currentTab === 'notes' ? 'active' : ''} onClick={() => { this.selectTab('notes') }}>
                  <TabData variant="h2">
                    {this.store.totalNotes}
                  </TabData>
                  <TabLabel>
                    Notes
                    <ProgressLabel className={this.store.notesPercentDiff > 0 ? 'increased' : (this.store.notesPercentDiff < 0 ? 'decreased' : '')}>
                      {this.store.notesPercentDiff > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {Math.round(Math.abs(this.store.notesPercentDiff))}%
                    </ProgressLabel>
                  </TabLabel>
                </TabedGrid>
              </Grid>

              <Grid item xs={10} sx={{
                textAlign: 'left',
                backgroundColor: '#fff',
                boxShadow: '0px 3px 22px #00000012',
                borderRadius: '10px',
                padding: '20px 40px',
                height: this.state.period === 'custom' ? 'auto' : height
              }}>
                {
                  this.state.period === 'custom' && <div>
                    <CustomFiltersContainer>
                      <div>
                        <div className="date-filter-label"><Typography>From</Typography></div>
                        <DesktopDatePicker
                          value={this.state.from}
                          onChange={this.handleOnDatePickerChange('from')}
                          renderInput={(params) => <TextField {...params} />}
                          maxDate={moment()}
                        />
                      </div>
                      <div>
                        <div className="date-filter-label"><Typography>To</Typography></div>
                        <DesktopDatePicker
                          value={this.state.to}
                          onChange={this.handleOnDatePickerChange('to')}
                          renderInput={(params) => <TextField {...params} />}
                          maxDate={moment()}
                        />
                      </div>
                      {/* <Button onClick={this.filterByCustomDates} sx={{ width: 80, height: '40px' }}>go</Button> */}
                      <IconButton onClick={this.filterByCustomDates}>
                        <SearchIcon />
                      </IconButton>
                    </CustomFiltersContainer>
                    <Divider />
                  </div>
                }
                {/* <StyledFormControl size='small'>
                  <Select
                    value={this.state.period}
                    onChange={this.handleChange}
                  >
                    {datePeriods
                      .map((period: IDatePeriod) => {
                        return (
                          <MenuItem key={period.key} value={period.key}>{period.label}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </StyledFormControl> */}
                <StyledResponsiveContainer width="100%" height="100%">
                  <BarChart
                    layout="vertical"
                    data={data}
                    barSize={10}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20
                    }}
                  >
                    <CartesianGrid horizontal={false} strokeDasharray="4 2" color="#6B6A6E" />
                    <XAxis type="number" stroke="#6B6A6E" tickLine={false} tick={{ fontSize: 14 }} />
                    <YAxis width={yaxisWidth} axisLine={false} tickLine={false} onClick={this.gotoUser} dataKey="title" type="category" scale="band" />
                    <Tooltip cursor={{ fill: 'transparent' }}  content={this.renderTooltip}/>
                    <Legend verticalAlign="top" align='right' iconType='square' iconSize={10} content={this.renderLegend} />
                    {/* #61006f c2b280 */}
                    {this.isBarVisible('calls') && <Bar name="Calls Out" dataKey="calls_out" stackId="a" fill="#61006f" radius={[2, 2, 2, 2]} />} 
                    {this.isBarVisible('calls') && <Bar name="Calls In" dataKey="calls_in" stackId="a" fill="#42b1f2" radius={[2, 2, 2, 2]} />}
                    {this.isBarVisible('notes') && <Bar name="Notes" dataKey="notes" stackId="b" fill="#FED361" radius={[2, 2, 2, 2]} />}
                    {this.isBarVisible('texts') && <Bar name="Texts" dataKey="texts" stackId="c" fill="#bbef1e" radius={[2, 2, 2, 2]} />}
                  </BarChart>
                </StyledResponsiveContainer>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withRouter(Dashboard)
