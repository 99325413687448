import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import Card from '../../../components/Card/Card'
import Grid from '@mui/material/Grid'
import { getAccountSummary } from '../../../services/accounts'
import { Row, StyledTextField } from './styledComponents'


interface IPlanLimits {
    account: any
    isLoading: boolean
}

interface ISummary {
    used_messages: number
    used_minutes: number
    used_users: number
    used_dids: number
}

const PlanLimits = ({account, isLoading}: IPlanLimits) => {
    const [summary, setSummary] = useState({} as ISummary)

    const getLimitsAndUsage = async () => {
        const { data } = await getAccountSummary()
        setSummary(data)
    }

    useEffect(() => {
        getLimitsAndUsage()
    }, [account])

    return (
        <Card title='Current Month Usage' titleAlign='left'>
            {isLoading ? <CircularProgress /> : <Grid container columnSpacing={5} >
                <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                    <Row>
                        <label>Included Users</label>
                        <StyledTextField
                            value={parseInt(account.additionalUsersNumber?.rawValue||'0', 10)}
                            variant='outlined' size='small'
                            disabled
                        />
                    </Row>
                    <Row>
                        <label>Included AutoPylot Numbers</label>
                        <StyledTextField
                            value={parseInt(account.additionalUsersNumber?.rawValue||'0', 10)}
                            variant='outlined' size='small'
                            disabled
                        />
                    </Row>
                    <Row>
                        <label>Monthly Minutes Used</label>
                        <StyledTextField
                            value={summary.used_minutes}
                            variant='outlined' size='small'
                            disabled
                        />
                    </Row>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                    <Row>
                        <label>Listed Users</label>
                        <StyledTextField
                            value={summary.used_users}
                            variant='outlined' size='small'
                            disabled
                        />
                    </Row>
                    <Row>
                        <label>Listed AutoPylot Numbers</label>
                        <StyledTextField
                            value={summary.used_dids}
                            variant='outlined' size='small'
                            disabled
                        />
                    </Row>
                    <Row>
                        <label>Monthly Text Message</label>
                        <StyledTextField
                            value={summary.used_messages}
                            variant='outlined' size='small'
                            disabled
                        />
                    </Row>
                </Grid>
            </Grid>}
        </Card>
    )
}

export default PlanLimits
