import React, { useState } from 'react'

import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import CircularProgress from '@mui/material/CircularProgress'
import CircleIcon from '@mui/icons-material/Circle';

import { useAppContext } from '../../context/AppContext'
import { useAuth } from '../../hooks/authHook'
import { resetPassword } from '../../services/signup'
import Snackbar from '../../components/CustomSnackbar/CustomSnackbar'
import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'

import PublicLayout from '../../components/PublicLayout/PublicLayout'

import capitalize from 'lodash/capitalize'

import {
    StyledTextField,
    Row,
    ButtonRow,
    ForgotPasswordButton,
    StyledLink,
    StyledTypography,
    ForgotPasswordPaper,
    ContentWrapper,
    FooterRow
} from './styled'

export interface ForgotPasswordFormData {
    email: string
}

export interface IForgotPasswordForm {
    email: string
}

interface CustomizedState {
    userEmail: string
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

const ForgotPassword = () => {
    let navigate = useNavigate()
    const { loading, setLoading } = useAppContext()
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [message, setMessage] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })
    const location = useLocation()
    const state = location.state as CustomizedState
    const userEmail = state?.userEmail
    const { isAuthenticated } = useAuth()

    const { register, handleSubmit, formState } = useForm<ForgotPasswordFormData>({
        mode: 'onChange',
        defaultValues: { email: userEmail }
    })

    const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
        setSnackbarProps(state => ({ ...state, open: false }))
    }

    const closeSuccessModal = () => {
        setShowSuccessModal(show => !show)
        navigate('/login')
    }

    const onSubmitUser = async (data: IForgotPasswordForm) => {
        try {
            setLoading(true)
            await resetPassword(data.email)

            setLoading(false)
            setShowSuccessModal(true)
        } catch (error: any) {
            console.log(error.response.data.errors);
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                console.log(key, value);

                let reason = value[0];

                setMessage(`${capitalize(key.replace(/_/g, ' '))} ${reason}`)
            });

            setLoading(false)
            setSnackbarProps({ severity: 'error', open: true })
        }
    }

    if (isAuthenticated) {
        return <Navigate to={'/dashboard'} />
    }

    const resetInstruction = (
        <Modal
            open={showSuccessModal}
            title="Reset password insctuction is sent"
            maxWidth='xs'
            fullWidth
            onClose={closeSuccessModal}
        >
            <StyledTypography
                variant="subtitle2"
                sx={{
                    borderTop: '1px solid rgba(107, 106, 110, 0.3)',
                    paddingTop: '15px',
                    marginBottom: '20px'
                }}
            >
                A link has been sent to the email provided. Please follow the link to change your password.
            </StyledTypography>
            <Button type='button' onClick={closeSuccessModal}>Ok</Button>
        </Modal>
    )

    return (
        <PublicLayout hideFooter={true}>
            {resetInstruction}
            <ForgotPasswordPaper elevation={0}>
                <ContentWrapper>
                    <StyledTypography align="left" sx={{margin: '20px 0 20px 0'}}>Enter your email for AutoPylot and we'll email you a link to create a new password.</StyledTypography>
                    <form onSubmit={handleSubmit(onSubmitUser)}>
                        <Row>
                            <label>Email address</label>
                            <StyledTextField
                                {...register('email', { required: 'Required Field' })}
                                inputProps={{
                                    autoComplete: 'off',
                                    form: {
                                        autoComplete: 'off',
                                    }
                                }}
                                variant='outlined' size='small'
                                error={!!formState.errors?.email}
                                helperText={formState.errors?.email?.message}
                            />
                        </Row>

                        <ButtonRow>
                            {loading ? <CircularProgress /> : <ForgotPasswordButton type='submit' disabled={!formState.isValid}>Sumbit</ForgotPasswordButton>}
                        </ButtonRow>
                    </form>
                </ContentWrapper>
            </ForgotPasswordPaper>
            <FooterRow>
                <div>Copyright © Knowme, Inc. 2022</div>
                <div>
                    <StyledLink href="https://www.autopylot.com/privacy" target={'_blank'}>Privacy</StyledLink>
                    <CircleIcon />
                    <StyledLink href="https://www.autopylot.com/terms" target={'_blank'}>Terms</StyledLink>
                </div>
            </FooterRow>
            <Snackbar open={snackbarProps.open} message={message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />
        </PublicLayout>
    )
}

export default ForgotPassword
